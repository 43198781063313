import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractFormComponent } from '../../../../components/abstract-form.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminAccountService } from '../../../../services/admin/admin-account.service';
import { AdminInformationService } from '../../../../services/admin/admin-information.service';
import { Information } from '../../../../models/information';
import { SettingService } from '../../../../services/setting.service';
import { ModalConfirmComponent } from '../../../../components/modal/modal-confirm/modal-confirm.component';
import { TranslateService } from '@ngx-translate/core';
import { ERROR_CODE } from '../../../../models/error';

@Component({
  selector: 'app-modal-edit-information',
  templateUrl: './modal-edit-information.component.html',
  styleUrls: ['./modal-edit-information.component.scss']
})
export class ModalEditInformationComponent extends AbstractFormComponent<any> implements OnInit {

  @ViewChild('buttonCancel') buttonCancel;
  @Input() iId;
  information: Information;
  durations: any[];
  isChange = false;
  isPublicLoading = false;
  isSaveLoading = false;
  errorCode: ERROR_CODE | null = null;
  publish_flg: number;
  constructor(
    public adminInformationService: AdminInformationService,
    public modal: NgbActiveModal,
    private translate: TranslateService,
    private toast: ToastrService,
    private settingService: SettingService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit() {
    this.adminInformationService.getByIdInformation(this.iId)
      .subscribe(t => {
        this.information = t;
        this.publish_flg = this.information.publish_flg;
        this.form = new FormGroup({
          'title': new FormControl({ value: this.information.title, disabled: false },
            [Validators.required, Validators.maxLength(250)]),
          'content': new FormControl({ value: this.information.content, disabled: false },
            [Validators.required, Validators.maxLength(4000)]),
          'publish_date': new FormControl({ value: this.information.publish_date ?
            this.information.publish_date.toString().slice(0, -3).split('-').join('/') : '', disabled: true }),
          'publish_flg': new FormControl({ value: this.information.publish_flg, disabled: true }),
        });

        this.form.valueChanges.subscribe(value => {
          this.isChange = true;
        });
      }, (err) => {
        this.isPublicLoading = false;
        this.toast.success(err.error.message);
        this.modal.close('close');
      });
    this.settingService.getDurations().subscribe(t => {
      this.durations = t.information_durations;
    });
  }

  submitInfomationPublic(publish_flg) {
    this.isPublicLoading = true;
    return this.adminInformationService.updatedInformation(this.iId, this.form.controls.title.value.trim(),
      this.form.controls.content.value.trim(), publish_flg)
      .subscribe(() => {
        this.isPublicLoading = false;
        this.toast.success(this.translate.instant(
          'common.toast_msg.success.edit'
        ));
        this.modal.close('close');
      }, (err) => {
        this.isPublicLoading = false;
        this.errorCode = err.error[0] ? err.error[0].code : this.errorCode;
        if (err.error && (err.error.code === 'not_found')) {
          this.toast.success(err.error.message);
          this.modal.close('close');
        }
      });
  }

  _onSubmit(formData) {
    this.isSaveLoading = true;
    return this.adminInformationService.updatedInformation(this.iId, formData.title.trim(), formData.content.trim(), null)
      .subscribe(() => {
        this.isSaveLoading = false;
        this.toast.success(this.translate.instant(
          'common.toast_msg.success.edit'
        ));
        this.modal.close('close');
      }, (err) => {
        this.isSaveLoading = false;
        this.errorCode = err.error[0] ? err.error[0].code : this.errorCode;
        if (err.error && (err.error.code === 'not_found')) {
          this.toast.success(err.error.message);
          this.modal.close('close');
        }
      });
  }

  onSuccess() {
  }

  isPublic(params) {
    return +params === 1;
  }

  goBack() {
    if (this.isChange) {
      const modal = this.modalService.open(ModalConfirmComponent);
      modal.componentInstance.message = this.translate.instant('page.information.modal.back.message');
      modal.componentInstance.title = this.translate.instant('page.information.modal.back.heading');
      modal.result
        .then(() => {
          this.modal.close('close');
        });
    } else {
      this.modal.close('close');
    }
  }
}
