export interface User {
  display_lang: string;
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  gender: boolean;
  phone: string;
  type?: TYPE;
  user_type: string;
  access_token: string;
  token_type: string;
  birthday: string;
  two_factor_authen_flg: number;
  two_factor_authen_txt?: string;
}

export enum TYPE {
  USER_MANAGER = 0,
  ADMIN = 2
}

export enum TWO_FACTOR_AUTHEN_FLG {
  OFF = 0,
  ON = 1
}

export enum TWO_FACTOR_AUTHEN_TXT {
  OFF = '未登録',
  ON = '登録済'
}

export enum TYPE_TXT {
  USER = '一般のユーザー',
  ADMIN = '管理者'
}
