import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AnalysisDetailModel,
  AnalysisDownloadRequest,
  AnalysisIndexModel,
} from 'src/app/models/analysis';
import { PageResponse } from 'src/app/models/page-response';
import { SearchRequest } from 'src/app/models/search-request';
import { AdminAnalysisSearchForm } from 'src/app/pages/admin/analysis-management/analysis-management.component';
import {
  AdminAnalysisDetailSearchForm
} from 'src/app/pages/admin/analysis-management/modal-statistic-analysis/modal-statistic-analysis.component';
import { environment } from 'src/environments/environment';
import { AbstractService } from '../abstract.service';

@Injectable({
  providedIn: 'root',
})
export class AdminAnalysisService extends AbstractService {
  _URL = `${environment.API_SERVER_URL}/api`;

  constructor(private http: HttpClient) {
    super();
  }

  search(searchForm: SearchRequest<AdminAnalysisSearchForm>) {
    const apiURL = `${this._URL}/analytics/scenes`;
    const params = AbstractService.requestToParams(searchForm);
    return this.http.get<PageResponse<AnalysisIndexModel>>(apiURL, { params });
  }

  searchDetail(
    id: string,
    searchForm: SearchRequest<AdminAnalysisDetailSearchForm>
  ) {
    const apiURL = `${this._URL}/analytics/${id}`;
    const params = AbstractService.requestToParams(searchForm);
    return this.http.get<PageResponse<AnalysisDetailModel>>(apiURL, {
      params,
    });
  }

  downloadAccessLog(request: AnalysisDownloadRequest) {
    const apiURL = `${this._URL}/accesslog/${request.id}/download`;
    const body = {
      from: request.from,
      to: request.to,
    };
    return this.http.post(apiURL, body, { responseType: 'arraybuffer' });
  }

  downloadAnalysisLog(request: AnalysisDownloadRequest) {
    const apiURL = `${this._URL}/analytics/${request.id}/download`;
    const body = {
      from: request.from,
      to: request.to,
      is_all: request.is_all ? 1 : 0,
    };
    return this.http.post(apiURL, body, { responseType: 'arraybuffer' });
  }
}
