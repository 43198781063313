import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractFormComponent} from '../../components/abstract-form.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import {Router} from '@angular/router';
import {
  ModalConfirm2FARegisteredComponent
} from '../../components/modal/modal-confirm-2fa-registered/modal-confirm-2fa-registered.component';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-modal-confirm-code-setting-2fa',
  templateUrl: './modal-confirm-code-setting-2fa.component.html',
  styleUrls: ['./modal-confirm-code-setting-2fa.component.scss']
})
export class ModalConfirmCodeSetting2FAComponent extends AbstractFormComponent<any> implements OnInit {
  @ViewChild('buttonCancel') buttonCancel;
  isTextFieldType: boolean;
  isTextFieldType1: boolean;
  isTextFieldType2: boolean;
  constructor(
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router
  ) {
    super();
  }


  ngOnInit() {
    this.form = this.formBuilder.group({
      'code': new FormControl('', [Validators.required, Validators.maxLength(7), Validators.pattern('^[0-9]+$')]),
    }, {});
  }


  _onSubmit(formData) {
    this.isLoading = true;
    return this.authService.change2FAStutus(formData.code.trim())
      .subscribe(async () => {
        let currentUser: User = null;
        await this.authService.getCurrentUser().toPromise().then((value: any) => {
          currentUser = value;
        });

        const current2Fa = currentUser.two_factor_authen_flg;
        this.isLoading = false;
        this.modal.close('close');
        const modalAlert = this.modalService.open(ModalConfirm2FARegisteredComponent);
        modalAlert.componentInstance.message = current2Fa
          ? 'page.dashboard.modal.setting_2fa_result.message_registered'
          : 'page.dashboard.modal.setting_2fa_result.message_released';
        modalAlert.componentInstance.title = 'page.dashboard.modal.setting_2fa_result.heading';
        modalAlert.result
          .then(async () => {
            modalAlert.close('close');
          });
      }, (err) => {
        this.isLoading = false;
        this.toast.error(this.translate.instant(`page.dashboard.modal.confirm_setting_2fa.${err.error.message}`));
      });
  }

  onSuccess() {
  }
}
