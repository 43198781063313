import { Injectable } from '@angular/core';
import {AbstractService} from '../abstract.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SearchRequest} from '../../models/search-request';
import {PageResponse} from '../../models/page-response';
import {License} from '../../models/license';
import {AdminLicenseSearchForm} from '../../pages/admin/license-management/license-management.component';

@Injectable({
  providedIn: 'root'
})
export class AdminLicenseService extends AbstractService {

  _URL = `${this.API_URL}`;

  constructor(
    private http: HttpClient
) {
    super();
  }

  search(searchForm: SearchRequest<AdminLicenseSearchForm>) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    const params = AbstractService.requestToParams(searchForm);
    return this.http.get<PageResponse<License>>(`${this._URL}/licenses`, {params, headers: httpHeaders});
  }


  createLicense(regist_dt, update_dt, duration, regist_sts, user_id, email) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.post(`${this._URL}/licenses`,
      {regist_dt, update_dt, duration, regist_sts, user_id, email}, {headers: httpHeaders});
  }


  getByIdLicense(id) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.get<License>(`${this._URL}/licenses/${id}`, {headers: httpHeaders});
  }

  updatedLicense(id, regist_dt, duration, update_dt, regist_sts, user_id) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.put(`${this._URL}/licenses/${id}`,
      {regist_dt, update_dt, duration, regist_sts, user_id}, {headers: httpHeaders});
  }

  deleteLicense(lId) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.delete(`${this._URL}/licenses/${lId}`, {headers: httpHeaders});
  }

  listUserSearch(searchForm) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });

    return this.http.get(`${this._URL}/licenses/users`, { params: searchForm, headers: httpHeaders });
  }
}
