<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{title | translate}}</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body d-flex justify-content-center" [innerHTML]="(message || confirmMessage) | translate">

</div>
<div class="modal-footer d-flex justify-content-center">
  <button type="button" #buttonCancel class="btn btn-outline-secondary button-delete"
          (click)="modal.dismiss('cancel click')">{{buttonCancelTitle | translate}}</button>
</div>
