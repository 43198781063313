<div class="modal-header">
  <div
    class="w-100 row justify-content-between align-items-center pl-4 pr-1 py-1"
  >
    <h2 class="modal-title" id="modal-title" style="font-weight: 600">
      {{ "page.dashboard.modal.change_password.heading" | translate }}
    </h2>
    <button
      type="button"
      class="close p-0"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('close')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body form-label">
  <form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form">
    <div class="form-group row">
      <div class="col-md-4 col-lg-4 col-sm-4">
        <label class="isRequired" data-required="必須" appRequiredLabel>{{
          "page.dashboard.modal.change_password.old_password_lbl" | translate
        }}</label>
      </div>
      <div class="col-md-8 col-lg-8 col-sm-4">
        <div class="pwdMask">
          <input
            (blur)="validateInput('password')"
            [type]="isTextFieldType ? 'text' : 'password'"
            class="form-control password"
            name="password"
            id="password"
            autocomplete="off"
            formControlName="password"
          />
          <span class="focus-input"></span>
          <span
            class="fa fa-eye-slash pwd-toggle hiddenPass"
            *ngIf="!isTextFieldType"
            (click)="hidePass()"
          ></span>
          <span
            class="fa fa-eye pwd-toggle hiddenPass"
            *ngIf="isTextFieldType"
            (click)="hidePass()"
          ></span>
        </div>
        <div
          *ngIf="inputError.password"
          class="text text-danger mt-1 text-left"
        >
          <p
            class="text text-danger mt-1"
          >
            {{
              "page.dashboard.modal.change_password.error_empty_current_password"
                | translate
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4 col-lg-4 col-sm-4">
        <label class="isRequired" data-required="必須" appRequiredLabel>{{
          "page.dashboard.modal.change_password.new_password_lbl" | translate
        }}</label>
      </div>
      <div class="col-md-8 col-lg-8 col-sm-4">
        <div class="pwdMask">
          <input
            (blur)="validateInput('new_password')"
            [type]="isTextFieldType1 ? 'text' : 'password'"
            class="form-control password"
            name="new_password"
            id="new_password"
            autocomplete="off"
            formControlName="new_password"
          />
          <span class="focus-input"></span>
          <span
            class="fa fa-eye-slash pwd-toggle hiddenPass"
            *ngIf="!isTextFieldType1"
            (click)="hidePass1()"
          ></span>
          <span
            class="fa fa-eye pwd-toggle hiddenPass"
            *ngIf="isTextFieldType1"
            (click)="hidePass1()"
          ></span>
        </div>
        <div
          *ngIf="inputError.new_password"
          class="text text-danger mt-1 text-left"
        >
          <p
            class="text text-danger mt-1"
          >
            {{
              "page.dashboard.modal.change_password.error_empty_new_password"
                | translate
            }}
          </p>

        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-4 col-lg-4 col-sm-4">
        <label class="isRequired" data-required="必須" appRequiredLabel>{{
          "page.dashboard.modal.change_password.confirm_password_lbl"
            | translate
        }}</label>
      </div>
      <div class="col-md-8 col-lg-8 col-sm-4">
        <div class="pwdMask">
          <input
            (blur)="validateInput('password_confirmation')"
            [type]="isTextFieldType2 ? 'text' : 'password'"
            class="form-control password"
            name="password_confirmation"
            id="password_confirmation"
            autocomplete="off"
            formControlName="password_confirmation"
          />
          <span class="focus-input"></span>
          <span
            class="fa fa-eye-slash pwd-toggle hiddenPass"
            *ngIf="!isTextFieldType2"
            (click)="hidePass2()"
          ></span>
          <span
            class="fa fa-eye pwd-toggle hiddenPass"
            *ngIf="isTextFieldType2"
            (click)="hidePass2()"
          ></span>
        </div>
        <div
          *ngIf="inputError.password_confirmation"
          class="text text-danger mt-1 text-left"
        >
          <p
            class="text text-danger mt-1"
          >
            {{
              "page.dashboard.modal.change_password.error_empty_password_confirm"
                | translate
            }}
          </p>

        </div>
      </div>
    </div>
    <div class="text-center form-item-row padding-button-top">
      <button
        type="submit"
        class="btn btn-md btn-primary with-button"
        appLoadingButton
        data-i18n="page.dashboard.modal.change_password.confirm_btn"
        [isLoading]="isLoading"
        loadingText="{{ 'common.processing_text' | translate }}"
        [disabled]="!form.valid"
      ></button
      >&nbsp;
      <button
        type="submit"
        class="btn btn-md btn-secondary with-button"
        (click)="modal.dismiss('close')"
      >
        {{
          "page.dashboard.modal.change_password.cancel_btn" | translate
        }}</button
      >&nbsp;
    </div>
    &nbsp;
  </form>
</div>
