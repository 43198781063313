<div class="m-grid__item m-grid__item--fluid m-wrapper">
  <div class="m-content">
    <div class="m-portlet m-portlet--tab">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <span class="m-portlet__head-icon m--hide">
              <i class="la la-gear"></i>
            </span>
            <h3 class="tworld-form-header">
              {{ 'page.information.heading' | translate }}
            </h3>
          </div>
        </div>
      </div>
      <div class="m-portlet__body">
        <div class="row">
          <div class="col-8">
          </div>
          <div class="col-xl-4 order-1 order-xl-2 m--align-right">
            <a (click)="createInformation()" class="btn btn-success" style="width: 100px">
              <span style="color: white;">
                <i class="fa fa-plus"></i>
                <span>
                  {{ 'page.information.create_btn' | translate }}
                </span>
              </span>
            </a>
          </div>
        </div>
        <br/>
        <div class="table-responsive">
          <table class="table table-sm table-hover table-tworld align-middle">
            <thead>
              <tr>
                <th class="text-left information-date">{{ 'page.information.release_date_col' | translate }}</th>
                <th class="text-left information-title">{{ 'page.information.title_col' | translate }}</th>
                <th class="text-center information-status">{{ 'page.information.status_col' | translate }}</th>
                <th class="text-center">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let information of pageResponse.rows">
                <td class="text-left">{{information.publish_date === null ? '' : information.publish_date | dateFormat}}</td>
                <td class="text-left" (click)="detailInformation(information.id)">
                  <p style="width: 50vw !important">
                    {{ information.title }}
                  </p>
                </td>
                <td class="text-center">{{ getStatusName(information.publish_flg) }}</td>
                <td class="minimum text-left align-middle" >
                  <div class="btn-group btn-group-sm">
                    <button class="btn btn-danger" ngbTooltip="{{ 'common.tooltip.delete' | translate}}" (click)="deleteInformation(information.id)">
                      <i class="fa fa-fw fa-trash-alt"></i>
                    </button>
                    <button class="btn btn-warning" (click)="editInformation(information.id)" ngbTooltip="{{ 'common.tooltip.edit' | translate}}">
                      <i class="fa fa-fw fa-edit"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!pageResponse.rows.length" class="no-content">
          {{ 'common.no_content_txt' | translate }}
        </div>
        <div class="row" *ngIf="pageResponse.count">
          <div class="col-md-8 col-sm-12">
            <ngb-pagination [collectionSize]="pageResponse.count" [(page)]="page" [rotate]="true" [maxSize]="3"
              [pageSize]="searchForm.size" [boundaryLinks]="true"></ngb-pagination>
          </div>
          <div class="col-md-4 col-sm-12 d-flex justify-content-center justify-content-md-end">
            <select title="Select Page Size" aria-label="Select Page change" class="form-control form-control-sm"
              style="width: auto" name="pageSize" [(ngModel)]="size">
              <option [ngValue]="10">{{ 'common.pagination.per_page_drd' | translate: { num: 10 } }}</option>
              <option [ngValue]="20">{{ 'common.pagination.per_page_drd' | translate: { num: 20 } }}</option>
              <option [ngValue]="50">{{ 'common.pagination.per_page_drd' | translate: { num: 50 } }}</option>
              <option [ngValue]="100">{{ 'common.pagination.per_page_drd' | translate: { num: 100 } }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>