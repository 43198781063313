import {Directive, ElementRef, Renderer2} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appRequiredLabel]'
})
export class RequiredLabelDirective {

  constructor(private el: ElementRef, private render2: Renderer2, private translate: TranslateService) {
    this.translate.get('validation.required_txt').subscribe((data: any) => {
      this.render2.setAttribute(this.el.nativeElement, 'data-required', data);
    });
  }
}
