import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Information } from '../../models/information';
import { AbstractService } from '../abstract.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends AbstractService {

  _URL = `${this.API_URL}`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getLatestInformation() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.get<Information[]>(`${this._URL}/informations/published-latest`, { headers: httpHeaders });
  }

}
