import {Component, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import {AbstractFormComponent} from '../../../../components/abstract-form.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminLicenseService} from '../../../../services/admin/admin-license.service';
import {ERROR_CODE} from '../../../../models/error';

@Component({
  selector: 'app-modal-search-user-license.component',
  templateUrl: './modal-search-user-license.component.html',
  styleUrls: ['./modal-search-user-license.component.scss']
})
export class ModalSearchUserLicenseComponent extends AbstractFormComponent<any> implements OnInit {
  errorCode: ERROR_CODE | null = null;
  @ViewChild('buttonCancel') buttonCancel;
  @Output() emitSerivce = new EventEmitter;
  isChange = false;
  durations: any[];
  user_id = null;
  user_list = [];
  userSelected = null;
  isSearch = false;
  constructor(
    public modal: NgbActiveModal,
    private adminLicenseService: AdminLicenseService,
  ) {
    super();
  }


  ngOnInit() {
    this.form = new FormGroup({
      'name': new FormControl('', [Validators.maxLength(255)]),
      'email': new FormControl('', [Validators.maxLength(255)]),
    });
  }

  _onSubmit(formData) {
    const data = {
      username: this.userSelected.name,
      id: this.userSelected.id
    };

    this.emitSerivce.next(data);
    this.modal.dismiss('dismiss');
  }

  onSuccess() {
  }

  goBack() {
    this.modal.dismiss('dismiss');
  }

  keyUpSearch(event) {
    if (event.key === 'Enter') {
      const value = this.form.value;
      this.searchUsers();
      this.unselectUser();

      this.form.get('name').setValue(value.name || '');
      this.form.get('email').setValue(value.email || '');
    }
  }

  onSearch() {
    const value = this.form.value;
    if (value.name || value.email) {
      this.searchUsers();
    } else {
      this.user_list = [];
    }

    this.unselectUser();
  }

  searchUsers() {
    this.adminLicenseService.listUserSearch(this.form.value).subscribe((val: any) => {
      this.isSearch = true;
      this.user_list = val.users;
    });
  }

  selectUser(id) {
    const user = this.user_list.find(item => item.id === id);
    this.userSelected = user;
    this.user_id = user.id;
  }

  unselectUser() {
    this.userSelected = null;
    this.user_id = null;
  }

  autoTrimInput(event, formControlName) {
    this.form.controls[formControlName].patchValue(event.target.value.trim());
  }
}
