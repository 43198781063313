<div class="modal-header">
  <h2 class="modal-title" id="modal-title" style="font-weight: 600">
    {{ "page.dashboard.modal.setting_2fa.heading" | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('close')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form-label">
  <form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form">
    <p>{{ "page.dashboard.modal.setting_2fa.message" | translate }}</p>
    <div class="form-group row">
      <div class="col-md-8 col-lg-8 col-sm-12 m-auto">
        <div class="pwdMask">
          <input
            type="password"
            class="form-control"
            name="code"
            id="code"
            autocomplete="off"
            formControlName="code"
          />
        </div>
        <span
          *ngIf="form.controls.code.errors?.pattern"
          class="text text-danger mt-1"
        >
          {{
            "page.dashboard.modal.setting_2fa.code_only_container_numberic"
              | translate
          }}
        </span>
      </div>
    </div>
    <div class="text-center form-item-row padding-button-top">
      <a
        class="btn btn-md btn-secondary with-button"
        (click)="modal.dismiss('close')"
      >
        {{ "page.dashboard.modal.setting_2fa.cancel_btn" | translate }}</a
      >&nbsp;
      <button
        type="submit"
        class="btn btn-md btn-primary with-button"
        appLoadingButton
        data-i18n="page.dashboard.modal.setting_2fa.confirm_btn"
        [isLoading]="isLoading"
        loadingText="{{ 'common.progress_text' | translate }}"
        [disabled]="!form.valid"
      ></button
      >&nbsp;
    </div>
    &nbsp;
  </form>
</div>
