import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AnalysisIndexModel } from 'src/app/models/analysis';
import { PageResponse } from 'src/app/models/page-response';
import { SearchRequest } from 'src/app/models/search-request';
import { AdminAnalysisService } from 'src/app/services/admin/admin-analysis.services';
import { AbstractSearchPage } from '../../abstract-search-page';
import { ModalDetailedLogComponent } from './modal-detailed-log/modal-detailed-log.component';
import { ModalStatisticAnalysisComponent } from './modal-statistic-analysis/modal-statistic-analysis.component';
import * as _ from 'lodash';
import { AuthService } from 'src/app/services/auth.service';

export interface AdminAnalysisSearchForm {
  id: string;
  name: string;
  username: string;
}

@Component({
  selector: 'app-analysis-management',
  templateUrl: './analysis-management.component.html',
  styleUrls: ['./analysis-management.component.scss'],
})
export class AnalysisManagementComponent
  extends AbstractSearchPage<AdminAnalysisSearchForm, AnalysisIndexModel>
  implements OnInit {
  userRole: any;
  isFirstSearch = true;

  constructor(
    private modalService: NgbModal,
    private analysisService: AdminAnalysisService,
    private authService: AuthService
  ) {
    super();
    this.userRole = this.authService.currentUserValue.type;
  }

  ngOnInit() {
    this.search();
  }

  _search(
    searchForm: SearchRequest<AdminAnalysisSearchForm>
  ): Observable<PageResponse<AnalysisIndexModel>> {
    const _searchForm = _.cloneDeep(searchForm);
    return this.analysisService.search(_searchForm);
  }

  _initSearchForm(): SearchRequest<AdminAnalysisSearchForm> {
    return <SearchRequest<AdminAnalysisSearchForm>>{
      size: 20,
      page: 1,
      search: {},
      sort: [],
    };
  }

  showStatistic(id: string) {
    const modal = this.modalService.open(ModalStatisticAnalysisComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modal.componentInstance.id = id;
  }

  showDetailedLog(id) {
    const modal = this.modalService.open(ModalDetailedLogComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modal.componentInstance.id = id;
  }

  changeFirstSearchState() {
    this.isFirstSearch = false;
  }
}
