<div class="m-grid__item m-grid__item--fluid m-wrapper">
  <div class="m-content">
    <div class="m-portlet m-portlet--tab">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <span class="m-portlet__head-icon m--hide">
              <i class="la la-gear"></i>
            </span>
            <h3 class="tworld-form-header">
              {{ "page.log.heading" | translate}}
            </h3>
          </div>
        </div>
      </div>
      <div class="m-portlet__body">
        <div class="row">
          <div class="col-12">
            <form class="form-inline pb-4 row" (ngSubmit)="search()">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div style="line-height: 40px">
                  <label style="font-size: 14px; justify-content: start !important;">
                    {{ "page.log.scene_id" | translate}}
                  </label>
                  <div class="mb-3" style="width: auto;">
                    <input type="text" class="form-control m-input" placeholder="{{ 'page.log.scene_id_placeholder' | translate}}" aria-label="scene_id"
                      id="scene_id" [(ngModel)]="searchForm.search.scene_id" name="scene_id" style="width: 100%"
                      (keyup)="changeValueSearch()">
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div style="line-height: 40px">
                  <label style="font-size: 14px; justify-content: start !important;">
                    {{ "page.log.log_level" | translate}}
                  </label>
                  <div class="mb-3" style="width: auto;">
                    <select class="form-control m-input" aria-label="Log level" name="log_level" style="width: 100%"
                      [(ngModel)]="searchForm.search.log_level" (change)="changeValueSearch()">
                      <option [ngValue]="''">{{ "page.log.level.all_level" | translate}}</option>
                      <option [ngValue]="'Info'">{{ "page.log.level.info_level" | translate}}</option>
                      <option [ngValue]="'Warning'">{{ "page.log.level.warning_level" | translate}}</option>
                      <option [ngValue]="'Error'">{{ "page.log.level.error_level" | translate}}</option>
                      <option [ngValue]="'Debug'">{{ "page.log.level.debug_level" | translate}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div style="line-height: 40px">
                  <label style="font-size: 14px; justify-content: start !important;">
                    {{ "page.log.user_name" | translate}}
                  </label>
                  <div class="mb-3" style="width: auto;">
                    <input type="text" class="form-control m-input" placeholder="{{ 'page.log.user_name_placeholder' | translate}}" aria-label="username"
                      id="user_name" [(ngModel)]="searchForm.search.user_name" name="user_name" style="width: 100%"
                      (keyup)="changeValueSearch()">
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div style="line-height: 40px">
                  <label style="font-size: 14px; justify-content: start !important;">
                    {{ "page.log.device_info" | translate}}
                  </label>
                  <div class="mb-3" style="width: auto;">
                    <input type="text" class="form-control m-input" placeholder="{{ 'page.log.device_info_placeholder' | translate}}" aria-label="device info"
                      id="device_info" [(ngModel)]="searchForm.search.device_info" name="device_info"
                      style="width: 100%" (keyup)="changeValueSearch()">
                  </div>
                </div>
              </div>

              <div class="col-12 text-center">
                <button type="submit" class="btn btn-tworld-important btn-sm mb-3" appLoadingButton
                  data-i18n="common.search_btn" loadingText="{{ 'common.search_btn' | translate }}"
                  [isLoading]="_loading$ | async" style="padding: 7px 40px;font-size: 16px;"
                  [disabled]="isSearch && isFirstSearch">
                </button>
              </div>
            </form>

          </div>
        </div>
        <div class="row">
          <div class="col-8">
          </div>
          <div class="col-xl-4 order-1 order-xl-2 m--align-right mb-2 pr-4">
            <a (click)="downloadLog()" class="btn btn-success">
              <div style="color: white;" class="d-flex">
                <i class="fa flaticon-download"></i>
                <span class="text-nowrap ml-2">
                  {{ 'page.log.download_btn' | translate }}
                </span>
              </div>
            </a>
          </div>
        </div>
        <br />
        <div class="table-responsive">
          <table class="table table-sm table-hover table-tworld align-middle">
            <thead>
              <tr>
                <th class="text-center">{{ 'page.log.table_header.date' | translate }}</th>
                <th class="text-center">{{ 'page.log.table_header.ip' | translate }}</th>
                <th class="text-center">{{ 'page.log.table_header.scene_id' | translate }}</th>
                <th class="text-center">{{ 'page.log.table_header.log_level' | translate }}</th>
                <th class="text-center">{{ 'page.log.table_header.user_name' | translate }}</th>
                <th class="text-center">{{ 'page.log.table_header.device_info' | translate }}</th>
                <th class="text-center">{{ 'page.log.table_header.tag' | translate }}</th>
                <th class="text-center message">{{ 'page.log.table_header.message' | translate }}</th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let log of pageResponse.rows, let i = index">
                <td class="text-left">{{log.date === null ? '' : replaceLogDate(log)}}</td>
                <td class="text-center">{{log.ip_address}}</td>
                <td class="text-center">{{log.scene_id}}</td>
                <td class="text-center">{{log.log_level}}</td>
                <td class="text-center">{{log.user_name}}</td>
                <td class="text-center">{{log.device_info}}</td>
                <td class="text-center">{{log.tag}}</td>
                <td class="text-center">{{log.message}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!pageResponse.rows.length" class="no-content">
          {{ 'common.no_content_txt' | translate }}
        </div>
        <div class="row" *ngIf="pageResponse.count">
          <div class="col-md-4 col-sm-12">
            <ngb-pagination [rotate]="true" [collectionSize]="pageResponse.count" [(page)]="page" [maxSize]="3"
              [pageSize]="searchForm.size" [boundaryLinks]="true"></ngb-pagination>
          </div>
          <!-- <div class="col-md-4 col-sm-12 d-flex justify-content-center justify-content-md-end">
            <select title="Select Page Size" aria-label="Select Page change" class="form-control form-control-sm"
              style="width: auto" name="pageSize" [(ngModel)]="size">
              <option [ngValue]="10">{{ 'common.pagination.per_page_drd' | translate: { num: 10 } }}</option>
              <option [ngValue]="20">{{ 'common.pagination.per_page_drd' | translate: { num: 20 } }}</option>
              <option [ngValue]="50">{{ 'common.pagination.per_page_drd' | translate: { num: 50 } }}</option>
            </select>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>