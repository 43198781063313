<div class="modal-header">
  <h2 class="modal-title" id="modal-title" style="font-weight: 600;">{{ 'page.information.modal.create.heading' |
    translate }}</h2>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
    (click)="modal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form-label">
  <form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form">
    <div class="form-group row form-item-row">
      <div class="col-12">
        <input class="form-control m-input" name="title" id="title" type="text" formControlName="title"
          placeholder="{{ 'page.information.modal.create.title_plh' | translate }}">
        <div *ngIf="form.controls.title.touched" class="text text-danger mt-1 text-left">
          <span *ngIf="form.controls.title.errors?.required || (errorCode == 'title_requrired')"
            class="text text-danger mt-1 label-error">
            {{ "page.information.modal.error_message.title_requrired" | translate}}
          </span>
          <span *ngIf="form.controls.title.errors?.maxlength" class="text text-danger mt-1 label-error">
            {{ "page.information.modal.error_message.title_exceed_max_length" | translate}}
          </span>
        </div>
      </div>
    </div>
    <div class="form-group row form-item-row">
      <div class="col-12">
        <div id="quill">
          <quill-editor id="content" placeholder="{{ 'page.information.modal.create.content_plh' | translate }}"
            formControlName="content" required>
          </quill-editor>
        </div>
        <div *ngIf="form.controls.content.touched" class="text text-danger mt-1 text-left">
          <span *ngIf="form.controls.content.errors?.required" class="text text-danger mt-1 label-error">
            {{ "page.information.modal.error_message.content_requrired" | translate}}
          </span>
          <span *ngIf="form.controls.content.errors?.maxlength" class="text text-danger mt-1 label-error">
            {{ "page.information.modal.error_message.content_exceed_max_length" | translate}}
          </span>
        </div>
      </div>
    </div>
    <div class="text-center form-item-row padding-button-top">
      <button type="submit" class="btn btn-md btn-primary with-button mt-2" appLoadingButton [isLoading]="isSaveLoading"
        loadingText="{{ 'common.processing_text' | translate }}" [disabled]="!form.valid"
        data-i18n="page.information.modal.create.save_btn">
      </button>&nbsp;
      <button type="button" class="btn btn-md btn-primary with-button mt-2" appLoadingButton [isLoading]="isPublicLoading"
        (click)="submitInfomationPublic()" loadingText="{{ 'common.processing_text' | translate }}"
        [disabled]="!form.valid" data-i18n="page.information.modal.create.publish_btn">
      </button>&nbsp;
      <button type="button" class="btn btn-md btn-secondary with-button mt-2" (click)="goBack()">
        {{ 'page.information.modal.create.cancel_btn' | translate }}
      </button>&nbsp;
    </div>&nbsp;
  </form>
</div>