// tslint:disable:max-line-length
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { UserMainComponent } from './pages/admin/user-main/user-main.component';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin-guard';
import { AccountManagementComponent } from './pages/admin/account-management/account-management.component';
import { LicenseManagementComponent } from './pages/admin/license-management/license-management.component';
import { MemberManagementComponent } from './pages/admin/member-management/member-management.component';
import { NotFoundPageComponent } from './pages/error/not-found-page/not-found-page.component';
import { UserGuard } from './guards/user-guard';
import { MyLicenseComponent } from './pages/user/my-license/my-license.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { LogManagementComponent } from './pages/admin/log-management/log-management.component';
import { CsvManagementComponent } from './pages/admin/csv-management/csv-management.component';
import { InformationManagementComponent } from './pages/admin/information-management/information-management.component';
import { MyInformationComponent } from './pages/user/my-information/my-information.component';
import { FileManagementComponent } from './pages/admin/file-management/file-management.component';
import { MyFileComponent } from './pages/user/my-file/my-file.component';
import { MyDashboardComponent } from './pages/user/my-dashboard/my-dashboard.component';
import { AnalysisManagementComponent } from './pages/admin/analysis-management/analysis-management.component';
import { SceneManagementComponent } from './pages/admin/scene-management/scene-management.component';

const routes: Routes = [
  {
    path: 'reset-password', component: ResetPasswordComponent
  },
  { path: '', component: HomeComponent },
  {
    path: 'manager', component: UserMainComponent, canActivate: [AuthGuard],
    children: [
      // admin
      { path: 'dashboard', component: DashboardComponent },
      { path: 'account-management', component: AccountManagementComponent, canActivate: [AdminGuard] },
      { path: 'analysis-management', component: AnalysisManagementComponent, canActivate: [AuthGuard] },
      { path: 'member-management', component: MemberManagementComponent, canActivate: [AdminGuard] },
      { path: 'license-management', component: LicenseManagementComponent, canActivate: [AdminGuard] },
      { path: 'log-management', component: LogManagementComponent, canActivate: [AdminGuard] },
      { path: 'csv-management', component: CsvManagementComponent, canActivate: [AdminGuard] },
      { path: 'information-management', component: InformationManagementComponent, canActivate: [AdminGuard] },
      { path: 'file-management', component: FileManagementComponent, canActivate: [AdminGuard] },
      { path: 'scene-management', component: SceneManagementComponent, canActivate: [AdminGuard] },

      // user
      { path: 'my-information', component: MyInformationComponent, canActivate: [UserGuard] },
      { path: 'my-license', component: MyLicenseComponent, canActivate: [UserGuard] },
      { path: 'my-file', component: MyFileComponent, canActivate: [UserGuard] },
      { path: 'my-dashboard', component: MyDashboardComponent, canActivate: [UserGuard] },
    ]
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
