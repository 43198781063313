import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractFormComponent} from '../../../../components/abstract-form.component';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MustMatch} from '../../../../validators/mail.validator';
import {AdminAccountService} from '../../../../services/admin/admin-account.service';
import {ToastrService} from 'ngx-toastr';
import {TYPE} from '../../../../models/user';
import {NotContainUserName, PasswordStrengthValidator} from '../../../../validators/password.validator';
import {ModalConfirmComponent} from '../../../../components/modal/modal-confirm/modal-confirm.component';
import {ERROR_CODE} from '../../../../models/error';
import { generatePassword } from '../../../../util/string-util';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-create-account',
  templateUrl: './modal-create-account.component.html',
  styleUrls: ['./modal-create-account.component.scss']
})
export class ModalCreateAccountComponent extends AbstractFormComponent<any> implements OnInit {
  errorCode: ERROR_CODE | null = null;
  isTextFieldType: boolean;
  type = TYPE;
  error_email;
  email: string = null;
  @ViewChild('buttonCancel') buttonCancel;
  isChange = false;
  constructor(
    public modal: NgbActiveModal,
    private adminAccountService: AdminAccountService,
    private formBuilder: FormBuilder,
    private toast: ToastrService,
    private modalService: NgbModal,
    private translate: TranslateService,
  ) {
    super();
  }


  ngOnInit() {
    this.form = this.formBuilder.group({
      'name': new FormControl('', [Validators.required, Validators.maxLength(255)]),
      'email': new FormControl('', [Validators.required, Validators.email,
        Validators.maxLength(255),  Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      'email_confirmation': new FormControl('', [Validators.required, Validators.email,
        Validators.maxLength(255),  Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      'password': new FormControl('', [Validators.required, Validators.maxLength(32), Validators.minLength(8),
        PasswordStrengthValidator]),
      'type': new FormControl({value: this.type.USER_MANAGER, disabled: false}),
    }, {
      validator: [
        MustMatch('email', 'email_confirmation'),
        NotContainUserName('name', 'password'),
      ]
    });

    this.form.valueChanges.subscribe(() => {
      this.isChange = true;
    });
  }

  hidePass() {
    this.isTextFieldType = !this.isTextFieldType;
  }

  clearPass() {
    this.form.controls['password'].setValue('');
  }

  randomPass() {
    this.form.controls['password'].setValue(generatePassword());
  }

  _onSubmit(formData) {
    this.isLoading = true;
    return this.adminAccountService.createUser(formData.name.trim(), formData.email.trim(), formData.email_confirmation.trim(),
      formData.password.trim(), formData.type)
      .subscribe(() => {
        this.isLoading = false;
        this.toast.success(this.translate.instant(
          'common.toast_msg.success.create'
        ));
        this.modal.close('close');
      }, (err) => {
        this.isLoading = false;
        this.errorCode = err.error.result;
        if (this.errorCode['email']) {
          this.error_email = true;
          this.email = formData.email.trim();
        }

        this.toast.error(this.translate.instant(
          'common.toast_msg.failed.create'
        ));
      });
  }

  onSuccess() {
  }

  goBack() {
    if (this.isChange) {
      const modal = this.modalService.open(ModalConfirmComponent);
      modal.componentInstance.message = 'page.account.modal.back_msg';
      modal.componentInstance.title = 'page.account.modal.back_title';
      modal.result
        .then(() => {
          this.modal.close('close');
        });
    } else {
      this.modal.close('close');
    }
  }

  removeError() {
    this.error_email = false;
  }
}
