import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {DOCUMENT} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {TYPE, TWO_FACTOR_AUTHEN_FLG} from '../../models/user';
import {ERROR_CODE} from '../../models/error';
import {ModalForgotPasswordComponent} from '../modal-forgot-password/modal-forgot-password.component';
import {ModalCodeLoginComponent} from './modal-code-login/modal-code-login/modal-code-login.component';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent implements OnInit {
  isTextFieldType: boolean;
  isLoading = false;
  isShowLoginErr = false;
  loginManagerForm: FormGroup;

  loginDiffWebForm: FormGroup;

  errorCode: ERROR_CODE | null = null;

  @ViewChild('buttonCancel') buttonCancel;

  constructor(
    public modal: NgbActiveModal,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.loginManagerForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(32),
      ])
    });

    this.loginDiffWebForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ])
    });
  }
  hidePass() {
    this.isTextFieldType = !this.isTextFieldType;
  }

  onSubmit() {
    this.isShowLoginErr = false;
    this.isLoading = true;
    this.errorCode = null;
    this.authService
      .login(this.email.value, this.password.value)
      .toPromise()
      .then((res: any) => {
        this.modal.close('close');
        if (res.two_factor_authen_flg === TWO_FACTOR_AUTHEN_FLG.ON) {
          this.openModalCodeConfirm();
        } else {
          this.translate.use(res.display_lang);
          if (res.type === TYPE.ADMIN) {
            this.router.navigateByUrl('/manager/dashboard');
          } else {
            this.router.navigateByUrl('/manager/my-dashboard');
          }
        }
      })
      .catch(err => {
        this.errorCode = err.error.message;
        this.isShowLoginErr = true;
        if (this.errorCode === ERROR_CODE.invalid_email_password) {
           this.toastr.error(this.translate.instant('page.home.modal.login.msg_login_fails'));
        }
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  get email() {
    return this.loginManagerForm.get('email');
  }

  get password() {
    return this.loginManagerForm.get('password');
  }

  forgotPassword() {
    this.modal.close('close');
    const modalRef = this.modalService.open(ModalForgotPasswordComponent, {backdrop: 'static', keyboard: false, centered: true});
    modalRef.result.then(() => {
    }, err => {
      console.log(err);
    });
  }

  openModalCodeConfirm() {
    const modalRef = this.modalService.open(ModalCodeLoginComponent, { backdrop: 'static', keyboard: false, centered: true, size: 'lg' });
    modalRef.componentInstance.email = this.email.value;
    modalRef.componentInstance.password = this.password.value;
    modalRef.result.then(() => {
      this.modal.close('close');
    }, err => {
        console.log(err);
    });
  }
}
