<div class="modal-header">
  <!-- <h2 class="modal-title" id="modal-title" style="font-weight: 600;">{{ 'page.license.modal.search_user.heading' | translate }}</h2> -->
  <h2 class="modal-title" id="modal-title" style="font-weight: 600">
    {{ "page.license.modal.search_user.heading" | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('close')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form-label">
  <form
    [formGroup]="form"
    class="form-search"
  >
    <div class="form-group row form-item-row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <!-- <label for="regist_dt" class="col-form-label isRequired" data-required="必須" appRequiredLabel>{{ 'page.license.modal.create.start_date_lbl' | translate }}</label> -->
        <label class="col-form-label">{{
          "page.license.modal.search_user.username_lbl" | translate
        }}</label>
        <div class="input-group">
          <input
            class="form-control m-input"
            name="name"
            id="name"
            type="text"
            formControlName="name"
            (keyup)="keyUpSearch($event)"
            (change)="autoTrimInput($event, 'name')"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="onSearch()"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div
          *ngIf="form.controls.name.touched"
          class="text text-danger mt-1 text-left"
        >
          <span
            *ngIf="form.controls.name.errors?.maxlength"
            class="text text-danger mt-1 label-error"
          >
            {{
              "page.license.modal.search_user.username_error_length_txt"
                | translate
            }}
          </span>
        </div>
      </div>
    </div>

    <div class="form-group row form-item-row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <!-- <label for="regist_dt" class="col-form-label isRequired" data-required="必須" appRequiredLabel>{{ 'page.license.modal.create.start_date_lbl' | translate }}</label> -->
        <label class="col-form-label">{{
          "page.license.modal.search_user.email_lbl" | translate
        }}</label>
        <div class="input-group">
          <input
            class="form-control m-input"
            name="email"
            id="email"
            type="text"
            formControlName="email"
            (keyup)="keyUpSearch($event)"
            (change)="autoTrimInput($event, 'email')"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar"
              type="button"
              (click)="onSearch()"
              [disabled]="form.controls.email.errors"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div
          *ngIf="form.controls.email.touched"
          class="text text-danger mt-1 text-left"
        >
          <span
            *ngIf="form.controls.email.errors?.maxlength"
            class="text text-danger mt-1 label-error"
          >
            {{
              "page.license.modal.search_user.email_error_length_txt"
                | translate
            }}
          </span>
        </div>
      </div>
    </div>

    <div class="user-search" *ngIf="user_list.length">
      <span>{{
        "page.license.modal.search_user.search_result_lbl" | translate
      }}</span>
      <div class="user-list-container">
        <div *ngFor="let user of user_list" class="user-list">
          <button
            type="button"
            class="btn btn-default btn-user btn-selected"
            (click)="unselectUser()"
            *ngIf="user_id === user.id"
          >
            {{ user.name }} ({{ user.email }})
          </button>
          <button
            type="button"
            class="btn btn-default btn-user"
            (click)="selectUser(user.id)"
            *ngIf="user_id !== user.id"
          >
            {{ user.name }} ({{ user.email }})
          </button>
        </div>
      </div>
    </div>

    <div class="user-search" *ngIf="isSearch && !user_list.length">
      <span>{{
        "page.license.modal.search_user.search_result_not_found_lbl" | translate
      }}</span>
    </div>

    <div class="text-center form-item-row padding-button-top">
      <button
        type="button"
        class="btn btn-md btn-secondary with-button"
        (click)="goBack()"
      >
        {{ "page.license.modal.search_user.cancel_btn" | translate }}</button
      >&nbsp;
      <button
        type="button"
        (click)="form.valid && onSubmit()"
        class="btn btn-md btn-primary with-button"
        appLoadingButton
        data-i18n="page.license.modal.search_user.select_btn"
        [isLoading]="isLoading"
        loadingText="Select"
        [disabled]="!form.valid || !userSelected"
      ></button
      >&nbsp;
    </div>
    &nbsp;
  </form>
</div>
