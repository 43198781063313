<div *ngIf="form">
  <div class="modal-header">
    <div class="header-title">
      <h2 class="modal-title title-info" id="modal-title" style="font-weight: 600;">{{ information.title }}</h2>
      <span class="modal-title date-time" style="font-weight: 400;">{{ information.publish_date.toString().slice(0, -3) }}</span>
    </div>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body form-label">
    <div class="form-group row form-item-row">
      <div class="col-12">
        <div class="content">
          <p class="e2e-inner-html-bound" [innerHTML]="contentHtml"></p>
        </div>
    </div>
    <div class="text-center form-item-row padding-button-top btn-close">
      <button type="button" class="btn btn-md btn-secondary with-button" (click)="goBack()">
        {{ 'page.my_information.detail.cancel_btn' | translate }}
      </button>&nbsp;
    </div>&nbsp;
  </div>
</div>