import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AnalysisDetailModel,
  AnalysisDownloadRequest,
} from 'src/app/models/analysis';
import { SearchRequest } from 'src/app/models/search-request';
import { AbstractSearchPage } from 'src/app/pages/abstract-search-page';
import { AdminAnalysisService } from 'src/app/services/admin/admin-analysis.services';
import { saveAs } from 'file-saver';
import moment from 'moment';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DateUtil } from 'src/app/util/date-util';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageResponse } from 'src/app/models/page-response';
import { AuthService } from 'src/app/services/auth.service';
export interface AdminAnalysisDetailSearchForm {
  from: string;
  to: string;
  is_all: any;
}

@Component({
  selector: 'app-modal-statistic-analysis',
  templateUrl: './modal-statistic-analysis.component.html',
  styleUrls: ['./modal-statistic-analysis.component.scss'],
})
export class ModalStatisticAnalysisComponent
  extends AbstractSearchPage<AdminAnalysisDetailSearchForm, AnalysisDetailModel>
  implements OnInit {
  id: string;
  isShowTimeFromTo = false;
  submitDownload = false;
  downloadRequestForm: FormGroup;
  _downloading$ = new BehaviorSubject<boolean>(false);
  analysisTotal: AnalysisDetailModel;

  constructor(
    public modal: NgbActiveModal,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private analysisService: AdminAnalysisService,
    private toast: ToastrService,
    private authService: AuthService
  ) {
    super();
    this.analysisTotal = {
      scene_id: null,
      analysis_date: null,
      avg_time: 0,
      lv: 0,
      pv: 0,
      uu: 0,
    };
  }

  _search(
    searchForm: SearchRequest<AdminAnalysisDetailSearchForm>
  ): Observable<PageResponse<AnalysisDetailModel>> {
    const requestForm = this.formatDownloadRequest();
    searchForm.search = {
      is_all: requestForm.is_all,
      from: requestForm.is_all ? null : requestForm.from,
      to: requestForm.is_all ? null : requestForm.to,
    };

    const _searchForm = _.cloneDeep(searchForm);
    const result = this.analysisService.searchDetail(this.id, _searchForm);
    result.subscribe((res) => {
      this.analysisTotal = {
        ...res.countTotal,
      };
    });
    return result;
  }

  ngOnInit() {
    const toDate = moment(new Date()).subtract(1, 'day').toDate();
    const fromDate = moment(toDate).subtract(29, 'days').toDate();
    this.downloadRequestForm = this.formBuilder.group({
      id: [this.id],
      is_all: [true],
      from: [DateUtil.dateToNgbDateObject(fromDate)],
      to: [DateUtil.dateToNgbDateObject(toDate)],
    });
    this.search();
  }

  _initSearchForm(): SearchRequest<AdminAnalysisDetailSearchForm> {
    return <SearchRequest<AdminAnalysisDetailSearchForm>>{
      size: 50,
      page: 1,
      search: {},
      sort: [],
    };
  }

  async download() {
    const downloadRequest = this.formatDownloadRequest();
    this._downloading$.next(true);
    if (this.authService.isTokenExpired()) {
      await this.authService.manualRefreshToken();
    }
    this.analysisService.downloadAnalysisLog(downloadRequest).subscribe(
      (response) => {
        const blob = new Blob([response], {
          type: 'text/plain;charset=utf-8',
        });
        saveAs(blob, `${this.generateFileName(downloadRequest)}.csv`);
        this.toast.success(
          this.translate.instant('page.log.modal.download.success')
        );
        this._downloading$.next(false);
      },
      (err) => {
        this._downloading$.next(false);
      }
    );
  }

  formatDownloadRequest(): AnalysisDownloadRequest {
    const dateFormat = 'YYYY-MM-DD';
    const downloadRequestValue = this.downloadRequestForm.value;
    return {
      id: downloadRequestValue.id,
      is_all: downloadRequestValue.is_all ? 1 : 0,
      from: DateUtil.ngbDateObjectToDateStr(
        downloadRequestValue.from,
        dateFormat
      ),
      to: DateUtil.ngbDateObjectToDateStr(downloadRequestValue.to, dateFormat),
    };
  }

  generateFileName(downloadRequest) {
    let fileName;
    if (downloadRequest.is_all) {
      fileName = `analytics_${this.id}_all`;
    } else {
      const dateFormat = 'YYYYMMDD';
      const fromDate = DateUtil.ngbDateObjectToDateStr(
        this.downloadRequestForm.value.from,
        dateFormat
      );
      const toDate = DateUtil.ngbDateObjectToDateStr(
        this.downloadRequestForm.value.to,
        dateFormat
      );
      fileName = `analytics_${this.id}_${fromDate}_${toDate}`;
    }
    return fileName;
  }

  timeChange(e) {
    const { value } = e.target;
    if (value === 'all') {
      this.isShowTimeFromTo = false;
      this.searchForm.search.is_all = true;
      this.downloadRequestForm.controls.is_all.patchValue(true);
    } else {
      this.isShowTimeFromTo = true;
      this.searchForm.search.is_all = false;
      this.downloadRequestForm.controls.is_all.patchValue(false);
    }
    this.search();
  }

  minFromDatePickerAtrribute() {
    const maxDate = this.downloadRequestForm.controls['to'].value;
    return { ...maxDate, day: maxDate.day - 1 };
  }

  toDatePickerAtrribute(isMin: boolean) {
    if (isMin) {
      const minDate = this.downloadRequestForm.controls['from'].value;
      return { ...minDate, day: minDate.day + 1 };
    }
    const maxDate = moment().subtract(1, 'day').toDate();
    return DateUtil.dateToNgbDateObject(maxDate);
  }

  goBack() {
    this.modal.close('close');
  }
}
