<div class="modal-header">
  <h2 class="modal-title" id="modal-title" style="font-weight: 600;">{{ 'page.license.modal.create.heading' | translate }}</h2>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form-label">
  <form (ngSubmit)="form.valid && onSubmit()"
        [formGroup]="form">
    <div class="form-group row form-item-row">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label for="username" class="col-form-label isRequired" data-required="必須" appRequiredLabel>{{ 'page.license.modal.create.username_lbl' | translate }}</label>
        <div class="input-group">
          <input class="form-control" id="username" formControlName="username">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="modalSearch()" type="button">
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div *ngIf="form.controls.username.touched" class="text text-danger mt-1 text-left">
          <span *ngIf="form.controls.username.errors?.required"
                class="text text-danger mt-1 label-error">
            Required field.
          </span>
          <span *ngIf="form.controls.username.errors?.maxlength" class="text text-danger mt-1 label-error">
            Field Only 225 character!
          </span>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label for="email" class="col-form-label isRequired" data-required="必須" appRequiredLabel>{{ 'page.license.modal.create.email_lbl' | translate }}</label>
        <input class="form-control m-input" name="email" id="email" type="text"
               formControlName="email">
        <div *ngIf="form.controls.email.touched" class="text text-danger mt-1 text-left">
          <span *ngIf="form.controls.email.errors?.required"
              class="text text-danger mt-1 label-error">
            Required field.
          </span>
          <span *ngIf="form.controls.email.errors?.maxlength" class="text text-danger mt-1 label-error">
            Field Only 225 character!
          </span>
          <span *ngIf="form.controls.email.errors?.email" class="text text-danger mt-1 label-error">
            Field not format mail!
          </span>
        </div>
      </div>
    </div>

    <div class="form-group row form-item-row">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label for="regist_dt" class="col-form-label isRequired" data-required="必須" appRequiredLabel>{{ 'page.license.modal.create.start_date_lbl' | translate }}</label>
        <div class="input-group">
          <input class="form-control" placeholder="{{ 'common.placeholder.date_plh' | translate }}" id="regist_dt"
                 name="dp" ngbDatepicker #regist_dt="ngbDatepicker" formControlName="regist_dt" [appFormValid]="form.get('regist_dt')">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="regist_dt.toggle()" type="button">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label for="duration" class="col-form-label isRequired" data-required="必須" appRequiredLabel>{{ 'page.license.modal.create.license_expire_lbl' | translate }}</label>
        <select class="form-control" id="duration" name="duration" formControlName="duration" type="number">
          <option *ngFor="let d of durations" [value]="d">{{ 'common.select.license_expire_opt' | translate: { value: d } }}</option>
        </select>
        <div *ngIf="form.controls.duration.touched" class="text text-danger mt-1 text-left">
          <span *ngIf="form.controls.duration.errors?.required" class="text text-danger mt-1 label-error">
            Required field.
          </span>
        </div>
      </div>
    </div>

    <div class="form-group row form-item-row">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label for="update_dt" class="col-form-label">{{ 'page.license.modal.create.end_date_lbl' | translate }}</label>
        <div class="input-group">
          <input class="form-control" placeholder="{{ 'common.placeholder.date_plh' | translate }}" id="update_dt"
                 name="dp" ngbDatepicker #update_dt="ngbDatepicker" formControlName="update_dt" [appFormValid]="form.get('update_dt')">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="update_dt.toggle()" type="button">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label for="regist_sts" class="col-form-label isRequired" data-required="必須" appRequiredLabel>{{ 'page.license.modal.create.registration_status_lbl' | translate }}</label>
        <select class="form-control" id="regist_sts" name="regist_sts" formControlName="regist_sts" type="number">
          <option [ngValue]="0">{{ 'common.select.registration_status.inactive_opt' | translate }}</option>
          <option [ngValue]="1">{{ 'common.select.registration_status.active_opt' | translate }}</option>
          <option [ngValue]="2">{{ 'common.select.registration_status.deactive_processing_opt' | translate }}</option>
          <option [ngValue]="3">{{ 'common.select.registration_status.active_processing_opt' | translate }}</option>
        </select>
        <div *ngIf="form.controls.regist_sts.touched" class="text text-danger mt-1 text-left">
          <span *ngIf="form.controls.regist_sts.errors?.required" class="text text-danger mt-1 label-error">
            Required field.
          </span>
        </div>
      </div>
    </div>

    <div class="text-center form-item-row padding-button-top">
      <button type="button" class="btn btn-md btn-secondary with-button" (click)="goBack()">
        {{ 'page.license.modal.create.cancel_btn' | translate }}
      </button>&nbsp;
      <button type="submit" class="btn btn-md btn-primary with-button" appLoadingButton data-i18n="page.license.modal.create.confirm_btn" [isLoading]="isLoading" loadingText="{{ 'common.processing_text' | translate }}" [disabled]="!form.valid">
      </button>&nbsp;
    </div>&nbsp;
  </form>
</div>
