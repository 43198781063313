import {ElementRef, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {animate, AnimationBuilder, AnimationPlayer, style} from '@angular/animations';
import {LogService} from './log.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalConfirmComponent} from '../components/modal/modal-confirm/modal-confirm.component';
import {ModalAlertComponent} from '../components/modal/modal-alert/modal-alert.component';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private isSideBarMinimizeSource = new Subject<boolean>();
  isSideBarMinimize$ = this.isSideBarMinimizeSource.asObservable();
  private isSideBarMouseOverSource = new Subject<boolean>();
  isSideBarMouseOver$ = this.isSideBarMouseOverSource.asObservable();
  private isSidebarCanvasOpenSource = new Subject<boolean>();
  isSidebarCanvasOpen$ = this.isSidebarCanvasOpenSource.asObservable();
  isTopBarOpenSource = new Subject<boolean>();
  isTopBarOpen = this.isTopBarOpenSource.asObservable();
  private loadingEls;
  public player: AnimationPlayer;

  constructor(private animationBuilder: AnimationBuilder, private log: LogService,
              private modalService: NgbModal) {
  }

  init(splashScreen: ElementRef) {
    this.loadingEls = splashScreen.nativeElement;
  }

  mouseOverSidebar(isOver: boolean) {
    this.isSideBarMouseOverSource.next(isOver);
  }

  minimizeSideBar(isClose: boolean) {
    console.log('Set minibar: ' + isClose);
    this.isSideBarMinimizeSource.next(isClose);
  }

  showSidebarCanvas(isShow) {
    console.log('Set showSidebarCanvas: ' + isShow);
    this.isSidebarCanvasOpenSource.next(isShow);
  }

  splashScreenVisible(isShow: boolean) {
    console.log('Set splashScreenVisible: ' + isShow);
    if (isShow) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.log.info('Show splash screen');
    this.player = this.animationBuilder
      .build([
        style({opacity: '0', zIndex: '99999', display: 'flex'}),
        animate('600ms ease', style({opacity: '1'}))
      ])
      .create(this.loadingEls);

    setTimeout(() => {
      this.player.play();
    }, 0);
  }

  hide() {
    this.log.info('Hide splash screen');
    this.player = this.animationBuilder
      .build([
        style({opacity: '1'}),
        animate('600ms ease', style({opacity: '0'}))
      ])
      .create(this.loadingEls);

    setTimeout(() => {
      this.player.onDone(
        () => (this.loadingEls.style.display = 'none')
      );
      this.player.play();
    }, 0);
  }

  confirm({title, message}) {
    const modalInstance = this.modalService.open(ModalConfirmComponent);
    modalInstance.componentInstance.title = title;
    modalInstance.componentInstance.message = message;
    return modalInstance.result;
  }

  alert(message, title?) {
    const modalIns = this.modalService.open(ModalAlertComponent);
    modalIns.componentInstance.message = message;
    if (title) {
      modalIns.componentInstance.title = title;
    }
    return modalIns;
  }
}
