<div class="m-grid__item m-grid__item--fluid m-wrapper">
  <div class="m-content">
    <div class="m-portlet m-portlet--tab">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <span class="m-portlet__head-icon m--hide">
              <i class="la la-gear"></i>
            </span>
            <h3 class="tworld-form-header">
              {{ "page.license.heading" | translate }}
            </h3>
          </div>
        </div>
      </div>
      <div class="m-portlet__body">
        <div class="row">
          <div class="col-12">
            <form class="form-inline row" (ngSubmit)="search()">
              <div class="col-lg-3 col-md-3 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.license.code_lbl" | translate }}
                  </label>
                  <div
                    class="m-input-icon m-input-icon--left mb-3"
                    style="width: auto"
                  >
                    <input
                      type="text"
                      class="form-control m-input"
                      placeholder="{{
                        'page.license.search_by_code_plh' | translate
                      }}"
                      aria-label="Search by code"
                      id="codeSearch"
                      [(ngModel)]="searchForm.search.code"
                      name="codeSearch"
                      style="width: 100%"
                      (keyup)="changeValueSearch()"
                    />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.license.username_lbl" | translate }}
                  </label>
                  <div
                    class="m-input-icon m-input-icon--left mb-3"
                    style="width: auto"
                  >
                    <input
                      type="text"
                      class="form-control m-input"
                      placeholder="{{
                        'page.license.search_by_username_plh' | translate
                      }}"
                      aria-label="Search by username"
                      id="usernameSearch"
                      [(ngModel)]="searchForm.search.name"
                      name="usernameSearch"
                      style="width: 100%"
                      (keyup)="changeValueSearch()"
                    />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.license.email_lbl" | translate }}
                  </label>
                  <div
                    class="m-input-icon m-input-icon--left mb-3"
                    style="width: auto"
                  >
                    <input
                      type="text"
                      class="form-control m-input"
                      placeholder="{{
                        'page.license.search_by_email_plh' | translate
                      }}"
                      aria-label="Search by email"
                      id="emailSearch"
                      [(ngModel)]="searchForm.search.email"
                      name="emailSearch"
                      style="width: 100%"
                      (keyup)="changeValueSearch()"
                    />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6"></div>
              <div class="col-lg-3 col-md-3 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.license.start_date_lbl" | translate }}
                  </label>
                  <div class="m-input-icon mb-3" style="width: 100%">
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="{{
                          'common.placeholder.date_plh' | translate
                        }}"
                        id="regist_from"
                        name="dp"
                        ngbDatepicker
                        #regist_from="ngbDatepicker"
                        [(ngModel)]="searchForm.search.regist_from"
                        (keyup)="changeValueSearch()"
                        (dateSelect)="changeValueSearch()"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="regist_from.toggle()"
                          type="button"
                        >
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.license.end_date_lbl" | translate }}
                  </label>
                  <div class="m-input-icon mb-3" style="width: 100%">
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="{{
                          'common.placeholder.date_plh' | translate
                        }}"
                        id="regist_to"
                        name="dp"
                        ngbDatepicker
                        #regist_to="ngbDatepicker"
                        [(ngModel)]="searchForm.search.regist_to"
                        (keyup)="changeValueSearch()"
                        (dateSelect)="changeValueSearch()"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="regist_to.toggle()"
                          type="button"
                        >
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.license.license_expire_lbl" | translate }}
                  </label>
                  <div class="m-input-icon mb-3" style="width: auto">
                    <select
                      class="form-control m-input"
                      aria-label="Select duration"
                      name="duration"
                      style="width: 100%"
                      [(ngModel)]="searchForm.search.duration"
                      (change)="changeValueSearch()"
                    >
                      <option [ngValue]="null" [selected]="true">
                        {{ "common.select.all_opt" | translate }}
                      </option>
                      <option *ngFor="let d of durations" [value]="d">
                        {{
                          "common.select.license_expire_opt"
                            | translate: { value: d }
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.license.registration_status_lbl" | translate }}
                  </label>
                  <div class="m-input-icon mb-3" style="width: auto">
                    <select
                      class="form-control m-input"
                      aria-label="Select status"
                      name="status"
                      style="width: 100%"
                      [(ngModel)]="searchForm.search.regist_sts"
                      (change)="changeValueSearch()"
                    >
                      <option [ngValue]="null">
                        {{ "common.select.all_opt" | translate }}
                      </option>
                      <option [ngValue]="0">
                        {{
                          "common.select.registration_status.inactive_opt"
                            | translate
                        }}
                      </option>
                      <option [ngValue]="1">
                        {{
                          "common.select.registration_status.active_opt"
                            | translate
                        }}
                      </option>
                      <option [ngValue]="2">
                        {{
                          "common.select.registration_status.deactive_processing_opt"
                            | translate
                        }}
                      </option>
                      <option [ngValue]="3">
                        {{
                          "common.select.registration_status.active_processing_opt"
                            | translate
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div style="margin: 10px auto">
                <button
                  type="submit"
                  class="btn btn-tworld-important btn-sm mb-3"
                  appLoadingButton
                  data-i18n="common.search_btn"
                  loadingText="{{ 'common.loading_text' | translate }}"
                  [isLoading]="_loading$ | async"
                  style="padding: 7px 40px; font-size: 16px"
                  [disabled]="(isSearch && isFirstSearch) || isCompareDate"
                ></button>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-8"></div>
          <div class="col-xl-4 order-1 order-xl-2 m--align-right">
            <a
              (click)="createLicense()"
              class="btn btn-success"
              style="width: 100px"
            >
              <span style="color: white">
                <i class="fa fa-plus"></i>
                <span>
                  {{ "page.license.create_btn" | translate }}
                </span>
              </span>
            </a>
          </div>
        </div>
        <br />
        <div class="table-responsive">
          <table class="table table-sm table-hover table-tworld align-middle">
            <thead>
              <tr>
                <th class="minimum text-center">#</th>
                <th class="text-center">
                  {{ "page.license.code_col" | translate }}
                </th>
                <th class="text-center">
                  {{ "page.license.username_col" | translate }}
                </th>
                <th class="text-center">
                  {{ "page.license.email_col" | translate }}
                </th>
                <th class="text-center">
                  {{ "page.license.license_expire_col" | translate }}
                </th>
                <th class="text-center">
                  {{ "page.license.license_period_col" | translate }}
                </th>
                <th class="text-center">
                  {{ "page.license.registration_status_col" | translate }}
                </th>
                <th class="minimum text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let license of pageResponse.rows; let i = index">
                <td class="text-center">{{ size * (page - 1) + i + 1 }}</td>
                <td class="text-center">{{ license.code }}</td>
                <td class="text-center">{{ license.username }}</td>
                <td class="text-center">{{ license.email }}</td>
                <td class="text-center" style="min-width: 140px">
                  {{ getLicenseTerm(license) }}
                </td>
                <td class="text-center" style="min-width: 140px">
                  {{
                    "common.select.license_expire_opt"
                      | translate: { value: license.duration }
                  }}
                </td>
                <td class="text-center" style="min-width: 85px">
                  <span *ngIf="license.regist_sts === 0" class="label-danger">{{
                    "common.select.registration_status.inactive_opt" | translate
                  }}</span>
                  <span
                    *ngIf="license.regist_sts === 1"
                    class="label-success"
                    >{{
                      "common.select.registration_status.active_opt" | translate
                    }}</span
                  >
                  <span
                    *ngIf="license.regist_sts === 2"
                    class="label-warning"
                    >{{
                      "common.select.registration_status.deactive_processing_opt"
                        | translate
                    }}</span
                  >
                  <span
                    *ngIf="license.regist_sts === 3"
                    class="label-primary"
                    >{{
                      "common.select.registration_status.active_processing_opt"
                        | translate
                    }}</span
                  >
                </td>
                <td class="minimum text-left align-middle">
                  <div class="btn-group btn-group-sm">
                    <button
                      class="btn btn-danger"
                      ngbTooltip="{{ 'common.tooltip.delete' | translate }}"
                      (click)="deleteLicense(license.id)"
                    >
                      <i class="fa fa-fw fa-trash-alt"></i>
                    </button>
                    <button
                      class="btn btn-warning"
                      (click)="editLicense(license.id)"
                      ngbTooltip="{{ 'common.tooltip.edit' | translate }}"
                    >
                      <i class="fa fa-fw fa-edit"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!pageResponse.rows.length" class="no-content">
          {{ "common.no_content_txt" | translate }}
        </div>
        <div class="row" *ngIf="pageResponse.count">
          <div class="col-md-8 col-sm-12">
            <ngb-pagination
              [rotate]="true"
              [collectionSize]="pageResponse.count"
              [(page)]="page"
              [maxSize]="3"
              [pageSize]="searchForm.size"
              [boundaryLinks]="true"
            ></ngb-pagination>
          </div>
          <div
            class="col-md-4 col-sm-12 d-flex justify-content-center justify-content-md-end"
          >
            <select
              title="Select Page Size"
              aria-label="Select Page change"
              class="form-control form-control-sm"
              style="width: auto"
              name="pageSize"
              [(ngModel)]="size"
            >
              <option [ngValue]="10">
                {{ "common.pagination.per_page_drd" | translate: { num: 10 } }}
              </option>
              <option [ngValue]="20">
                {{ "common.pagination.per_page_drd" | translate: { num: 20 } }}
              </option>
              <option [ngValue]="50">
                {{ "common.pagination.per_page_drd" | translate: { num: 50 } }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
