<div *ngIf="form">
  <div class="modal-header">
    <h2 class="modal-title" id="modal-title" style="font-weight: 600;">{{ 'page.csv.modal.edit.heading' | translate }}</h2>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body form-label">
    <form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form">
      <div class="avatar-upload">
        <div class="avatar-edit">
          <input type='file' id="company_id_file" accept=".csv" #company_id_file (change)="upFile($event)" />
        </div>
        <div class="avatar-preview">
          <div id="imagePreview" style="text-align: center;">
            {{imageUrl}}
          </div>
        </div>
      </div>

      <div class="d-flex flex-row" style="justify-content: center;">
        <button type="button" class="btn btn-outline-info btn-upload" *ngIf="!isUpload"
          (click)="company_id_file.click()">{{ 'page.csv.modal.edit.sel_file_btn' | translate }}</button>
      </div>
      <div class="d-flex flex-row" style="justify-content: center; margin-top: 30px;">
        <button type="button" class="btn btn-success" [disabled]="!isUpload" (click)="updateCsv()" appLoadingButton data-i18n="page.csv.modal.edit.upload_btn"
          [isLoading]="isUploading" loadingText="{{ 'common.processing_text' | translate }}"></button>&nbsp;
        <button type="button" class="btn btn-danger" (click)="cancelUpload()">{{ 'page.csv.modal.edit.cancel_btn' | translate }}</button>
      </div>
    </form>
  </div>
</div>