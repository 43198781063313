import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AbstractFormComponent } from '../../../../components/abstract-form.component';
import { ModalConfirmComponent } from '../../../../components/modal/modal-confirm/modal-confirm.component';
import { ERROR_CODE } from '../../../../models/error';
import { User } from '../../../../models/user';
import { AdminInformationService } from '../../../../services/admin/admin-information.service';

@Component({
  selector: 'app-modal-create-information',
  templateUrl: './modal-create-information.component.html',
  styleUrls: ['./modal-create-information.component.scss']
})
export class ModalCreateInformationComponent extends AbstractFormComponent<any> implements OnInit {
  errorCode: ERROR_CODE | null = null;
  @ViewChild('buttonCancel') buttonCancel;
  isChange = false;
  isPublicLoading = false;
  isSaveLoading = false;
  user$3: User[];
  durations: any[];
  publish_flg: number;
  constructor(
    public adminInformationService: AdminInformationService,
    public modal: NgbActiveModal,
    private translate: TranslateService,
    private toast: ToastrService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      'title': new FormControl({ value: '', disabled: false }, [Validators.required, Validators.maxLength(250)]),
      'content': new FormControl({ value: '', disabled: false }, [Validators.required, Validators.maxLength(4000)]),
    });
    this.form.valueChanges.subscribe(value => {
      this.isChange = true;
    });
  }

  _onSubmit(formData) {
    this.errorCode = null;
    this.isSaveLoading = true;
    return this.adminInformationService.createInformation(formData.title.trim(), formData.content.trim(), null)
      .subscribe(() => {
        this.isSaveLoading = false;
        this.toast.success(this.translate.instant(
          'common.toast_msg.success.create'
        ));
        this.modal.close('close');
      }, (err) => {
        this.isSaveLoading = false;
        this.errorCode = err.error[0] ? err.error[0].code : this.errorCode;
      });
  }

  submitInfomationPublic() {
    this.publish_flg = 1;
    this.isPublicLoading = true;
    return this.adminInformationService.createInformation(this.form.controls.title.value.trim(), this.form.controls.content.value.trim(),
      this.publish_flg)
      .subscribe(() => {
        this.isPublicLoading = false;
        this.toast.success(this.translate.instant(
          'common.toast_msg.success.create'
        ));
        this.modal.close('close');
      }, (err) => {
        this.isPublicLoading = false;
        this.errorCode = err.error[0] ? err.error[0].code : this.errorCode;
      });
  }

  onSuccess() {
  }

  goBack() {
    if (this.isChange) {
      const modal = this.modalService.open(ModalConfirmComponent);
      modal.componentInstance.message = this.translate.instant('page.information.modal.back.message');
      modal.componentInstance.title = this.translate.instant('page.information.modal.back.heading');
      modal.result
        .then(() => {
          this.modal.close('close');
        });
    } else {
      this.modal.close('close');
    }
  }
}
