<div class="modal-header">
  <h2 class="modal-title" id="modal-title" style="font-weight: 600">
    {{ "page.analysis.access_log" | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="goBack()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form-label">
  <form class="form-inline row" [formGroup]="downloadRequestForm">
    <div class="col-md-6 col-sm-12">
      <div style="line-height: 40px">
        <label style="font-size: 14px; justify-content: start !important">
          {{ "page.analysis.modal.statistic.start_date_lbl" | translate }}
        </label>
        <div class="m-input-icon mb-3" style="width: 100%">
          <div class="input-group">
            <input
              class="form-control"
              placeholder="{{ 'common.placeholder.date_plh' | translate }}"
              id="regist_from"
              name="dp"
              ngbDatepicker
              #regist_from="ngbDatepicker"
              readonly
              formControlName="from"
              [minDate]="fromDatePickerAtrribute(true)"
              [maxDate]="fromDatePickerAtrribute(false)"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="regist_from.toggle()"
                type="button"
              >
                <i class="fa fa-calendar" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-sm-12">
      <div style="line-height: 40px">
        <label style="font-size: 14px; justify-content: start !important">
          {{ "page.analysis.modal.statistic.end_date_lbl" | translate }}
        </label>
        <div class="m-input-icon mb-3" style="width: 100%">
          <div class="input-group">
            <input
              class="form-control"
              placeholder="{{ 'common.placeholder.date_plh' | translate }}"
              id="regist_to"
              name="dp"
              ngbDatepicker
              #regist_to="ngbDatepicker"
              readonly
              formControlName="to"
              [minDate]="toDatePickerAtrribute(true)"
              [maxDate]="toDatePickerAtrribute(false)"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="regist_to.toggle()"
                type="button"
              >
                <i class="fa fa-calendar" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <br />
  <div class="text-center form-item-row">
    <button
      type="button"
      class="btn btn-md btn-secondary with-button"
      (click)="goBack()"
    >
      {{ "page.license.modal.edit.cancel_btn" | translate }}
    </button>&nbsp;
    <button
      (click)="download()"
      class="btn btn-success"
      appLoadingButton
      data-i18n="page.log.download_btn"
      loadingText="{{ 'common.loading_text' | translate }}"
      [isLoading]="_downloading$ | async"
      [disabled]="_downloading$ | async"
    ></button>
  </div>
  &nbsp;
</div>
