import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AbstractFormComponent } from '../../../../components/abstract-form.component';
import { ERROR_CODE } from '../../../../models/error';
import { saveAs } from 'file-saver';
import { AdminLogService } from '../../../../services/admin/admin-log.service';
import moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-modal-download-log',
  templateUrl: './modal-download-log.component.html',
  styleUrls: ['./modal-download-log.component.scss'],
})
export class ModalDownloadLogComponent
  extends AbstractFormComponent<any>
  implements OnInit {
  errorCode: ERROR_CODE | null = null;
  @ViewChild('buttonCancel') buttonCancel;
  isChange = false;
  isdownloading = false;
  date_error = false;
  type_error = false;
  maxStartDate = null;
  minEndDate = null;
  rows = [];
  constructor(
    public modal: NgbActiveModal,
    private translate: TranslateService,
    private toast: ToastrService,
    private adminLogService: AdminLogService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    const dateLastMonth = new Date();
    dateLastMonth.setMonth(dateLastMonth.getMonth() - 1);
    this.form = new FormGroup({
      type: new FormControl(),
      start_date: new FormControl(),
      end_date: new FormControl(),
    });

    this.maxStartDate = () => {
      if (this.form.value.end_date) {
        const year = this.form.value.end_date.year;
        const month = this.form.value.end_date.month;
        const day = this.form.value.end_date.day;
        return { year, month, day };
      }
      return null;
    };

    this.minEndDate = () => {
      if (this.form.value.start_date) {
        const year = this.form.value.start_date.year;
        const month = this.form.value.start_date.month;
        const day = this.form.value.start_date.day;
        return { year, month, day };
      }
      return null;
    };
  }

  async _onSubmit(formData) {
    let start_date;
    if (formData.start_date !== null) {
      start_date = `${formData.start_date.year}-${formData.start_date.month}-${formData.start_date.day}`;
    } else {
      start_date = null;
    }
    let end_date;
    if (formData.end_date !== null) {
      end_date = `${formData.end_date.year}-${formData.end_date.month}-${formData.end_date.day}`;
    } else {
      end_date = null;
    }

    if (formData.type !== null && formData.type !== undefined) {
      this.type_error = false;
    } else {
      this.type_error = true;
      return;
    }
    if (+formData.type === 1 && !start_date && !end_date) {
      this.date_error = true;
      return;
    } else {
      this.date_error = false;
    }

    const logs = {
      date_from: start_date,
      date_to: end_date,
      ids: +formData.type === 2 ? this.rows.map((e) => e.id) : null,
    };

    this.isdownloading = true;

    if (this.authService.isTokenExpired()) {
      await this.authService.manualRefreshToken();
    }
    this.adminLogService.downLoadLog(logs).subscribe(
      (response) => {
        const blob = new Blob([response], {
          type: 'text/plain;charset=utf-8',
        });
        const timeNow = moment(new Date()).format('YYYYMMDD_HHmmss').toString();
        const fileName = 'accesslog_' + timeNow;
        saveAs(blob, `${fileName}.csv`);

        this.isdownloading = false;
        this.toast.success(
          this.translate.instant('page.log.modal.download.success')
        );
        this.modal.close('close');
      },
      (err) => {
        this.isdownloading = false;
        this.errorCode = err.error[0].code;
        this.toast.error(this.errorCode);
      }
    );
  }

  typeChange(e) {
    if (+e.target.value !== 1) {
      this.form.controls.start_date.setValue(null);
      this.form.controls.end_date.setValue(null);
    }
  }

  onSuccess() {}

  goBack() {
    this.modal.close('close');
  }
}
