<a href="javascript:void(0)" class="m-nav__link m-dropdown__toggle" (click)="refresh()">
  <span class="m-topbar__userpic">
    <img [src]="avatar" class="m--img-rounded m--marginless m--img-centered" alt="" />
  </span>
  <span class="m-topbar__username m--hide">Admin@bap.jp</span>
</a>
<div class="m-dropdown__wrapper">
  <div class="m-dropdown__inner">
    <div class="m-dropdown__header m--align-center info-container" #mProfileDropdown style="background-size: cover">
      <div class="m-card-user m-card-user--skin-dark">
        <div class="m-card-user__pic">
          <img [src]="avatar" class="m--img-rounded m--marginless" alt="" />
        </div>
        <div class="m-card-user__details card-user">
          <span class="m--font-weight-300">{{'header.account_lbl' | translate}}: {{user?.name }}</span>
          <!-- <br *ngIf="user?.type == 2"/>
          <span *ngIf="user?.type == 2"  class="m--font-weight-300">{{'header.user_type' | translate}}: {{ 'common.select.user_type.admin' | translate }}</span> -->
          <br/>
          <span class="m--font-weight-300">{{'header.mail' | translate}}: {{user?.email }}</span>
          <br/>
          <span *ngIf="user?.two_factor_authen_flg == 0" class="m--font-weight-300">{{'header.2fa_status_text' | translate}}: {{ 'page.account.modal.edit.member_info.tfa_lbl_no' | translate }}</span>
          <span *ngIf="user?.two_factor_authen_flg == 1" class="m--font-weight-300">{{'header.2fa_status_text' | translate}}: {{ 'page.account.modal.edit.member_info.tfa_lbl_yes' | translate }}</span>
          <br/>
        </div>
      </div>
    </div>
    <div class="m-dropdown__body dropdown-edit-profile">
      <div class="m-dropdown__content dropdown-content mt-3">
        <ul class="m-nav m-nav--skin-light d-flex flex-wrap justify-content-center">
          <!-- <li class="m-nav__separator m-nav__separator--fit"></li> -->
          <li class="m-nav__item m-1">
            <a class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder btn-profile"
              (click)="changePassword()">{{'header.change_password' | translate}}</a>
          </li>
          <li class="m-nav__item m-1">
            <a class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder btn-profile"
              (click)="confirmSetting2FA()">{{'header.2fa_status_btn' | translate}}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="m-dropdown__body dropdown-logout">
      <div class="m-dropdown__content dropdown-content">
        <ul class="m-nav m-nav--skin-light">
          <li class="m-nav__separator m-nav__separator--fit"></li>
          <li class="m-nav__item">
            <a class="btn m-btn&#45;&#45;pill btn-secondary m-btn m-btn&#45;&#45;custom m-btn&#45;&#45;label-brand m-btn&#45;&#45;bolder btn-profile"
              (click)="logout()">{{'header.logout' | translate}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>