import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ModalConfirmComponent } from '../../../components/modal/modal-confirm/modal-confirm.component';
import { Information } from '../../../models/information';
import { PageResponse } from '../../../models/page-response';
import { SearchRequest } from '../../../models/search-request';
import { AdminInformationService } from '../../../services/admin/admin-information.service';
import { SettingService } from '../../../services/setting.service';
import { AbstractSearchPage } from '../../abstract-search-page';
import * as _ from 'lodash';
import { ModalCreateInformationComponent } from './modal-create-information/modal-create-information.component';
import { ModalEditInformationComponent } from './modal-edit-information/modal-edit-information.component';
import { ModalDetailInformationComponent } from './modal-detail-information/modal-detail-information.component';
import { AuthService } from 'src/app/services/auth.service';

export interface AdminInformationSearchForm {
  title: string;
  content: string;
  publish_date: string;
  publish_flg: number;
}
@Component({
  selector: 'app-information-management',
  templateUrl: './information-management.component.html',
  styleUrls: ['./information-management.component.scss'],
})
export class InformationManagementComponent
  extends AbstractSearchPage<AdminInformationSearchForm, Information>
  implements OnInit {
  durations: any[];
  current_user = JSON.parse(localStorage.getItem('currentUser'));
  constructor(
    private adminInformationService: AdminInformationService,
    public authService: AuthService,
    private settingService: SettingService,
    private router: Router,
    private translate: TranslateService,
    private toast: ToastrService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit() {
    this.search();
    this.settingService.getDurations().subscribe((t) => {
      this.durations = t.license_durations;
    });
  }

  _initSearchForm(): SearchRequest<AdminInformationSearchForm> {
    return <SearchRequest<AdminInformationSearchForm>>{
      size: 10,
      page: 1,
      search: {},
      sort: [],
    };
  }

  _search(
    searchForm: SearchRequest<AdminInformationSearchForm>
  ): Observable<PageResponse<Information>> {
    const _searchForm = _.cloneDeep(searchForm);
    return this.adminInformationService.search(_searchForm);
  }

  createInformation() {
    const modalRef = this.modalService.open(ModalCreateInformationComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modalRef.result.then(
      () => {
        this.search();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  detailInformation(iId) {
    const modalRef = this.modalService.open(ModalDetailInformationComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modalRef.componentInstance.iId = iId;
    modalRef.result.then(
      () => {
        this.search();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getStatusName(statusValue) {
    switch (statusValue) {
      case 0:
        return this.translate.instant('page.information.private_txt');
      case 1:
        return this.translate.instant('page.information.publish_txt');
      default:
        return this.translate.instant('page.information.draft_txt');
    }
  }

  editInformation(iId) {
    const modalRef = this.modalService.open(ModalEditInformationComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modalRef.componentInstance.iId = iId;
    modalRef.result.then(
      () => {
        this.search();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteInformation(iId) {
    const modal = this.modalService.open(ModalConfirmComponent);
    modal.componentInstance.message = 'common.modal.delete.message';
    modal.componentInstance.title = 'common.modal.delete.heading';
    modal.componentInstance.revertButton = true;
    modal.result.then(() => {
      this._loading$.next(true);
      this.adminInformationService.deleteInformation(iId).subscribe(
        () => {
          this.toast.success(
            this.translate.instant('common.toast_msg.success.delete')
          );
          this.search();
        },
        (err) => {
          this.toast.error(err.error.message);
          this.search();
          this._loading$.next(false);
        }
      );
    });
  }
}
