<div>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-title" style="font-weight: 600">
      {{ "page.file.modal.upload.update_heading" | translate }}
    </h2>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="closeModal()"
      [disabled]="progress === 100"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body form-label">
    <form style="justify-content: center">
      <div
        class="container-input d-flex flex-column align-items-center justify-content-center"
        appDnd
        (dragover)="isDragOver = true"
        (dragleave)="isDragOver = false"
        [ngClass]="{ dragover: isDragOver }"
        (appDropZone)="upFile($event, true)"
        *ngIf="!fileURL"
      >
        <input
          type="file"
          hidden="true"
          id="fileDropRef"
          (change)="upFile($event)"
          accept="{{ typeFile }}"
        />
        <h4>
          {{ "page.file.modal.upload.drag_drop_lbl" | translate }}
        </h4>
        <h4>
          {{ "page.file.modal.upload.or_lbl" | translate }}
        </h4>
        <label for="fileDropRef" style="cursor: pointer">
          {{ "page.file.modal.upload.select_file_lbl" | translate }}
        </label>
      </div>
      <div class="avatar-preview">
        <div id="imagePreview" style="text-align: center">
          <p class="name-file">{{ fileURL }}</p>
        </div>
      </div>
      <br />
      <div *ngIf="errorMessage" class="error-message">
        <p>{{ "page.file.error.title" | translate }}</p>
        <p class="txt-error">
          {{ errorMessage | translate | replace: "{format}":typeFile }}
        </p>
      </div>
      <div
        class="progress-bar bg-primary"
        attr.aria-valuenow="{{ progress }}"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{ width: progress + '%' }"
        *ngIf="progress"
      >
        {{ progress }}%
      </div>
      <div
        class="d-flex flex-row"
        style="justify-content: center"
        *ngIf="!fileURL || progress"
      >
        <div [ngSwitch]="isUploading" *ngIf="progress !== 100">
          <button
            *ngSwitchCase="false"
            type="button"
            class="btn btn-outline-dark w-100"
            (click)="closeModal()"
          >
            {{ "page.file.modal.upload.close_btn" | translate }}</button
          >&nbsp;
          <button
            *ngSwitchCase="true"
            type="button"
            class="btn btn-outline-dark w-100"
            (click)="closeModal()"
          >
            {{ "page.file.modal.upload.cancel_btn" | translate }}</button
          >&nbsp;
        </div>
      </div>
      <div
        class="d-flex flex-row"
        style="justify-content: center"
        *ngIf="!progress"
      >
        <button
          type="button"
          class="btn btn-success"
          *ngIf="isUpload"
          (click)="upload()"
          appLoadingButton
          data-i18n="page.file.modal.upload.upload_btn"
          [isLoading]="isUploading"
          loadingText="{{ 'common.processing_text' | translate }}"
        >
          アップロード</button
        >&nbsp;
        <button
          type="button"
          class="btn btn-danger"
          *ngIf="isUpload"
          (click)="cancelUpload()"
        >
          {{ "page.file.modal.upload.cancel_btn" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
