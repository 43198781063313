<div class="m-grid__item m-grid__item--fluid m-wrapper">
  <div class="m-content">
    <div class="m-portlet m-portlet--tab">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <span class="m-portlet__head-icon m--hide">
              <i class="la la-gear"></i>
            </span>
            <h3 class="tworld-form-header">
              {{ "page.analysis.heading" | translate }}
            </h3>
          </div>
        </div>
      </div>
      <div class="m-portlet__body">
        <div class="row">
          <div class="col-12">
            <form class="form-inline row" (ngSubmit)="search()">
              <div class="col-lg-4 col-md-4 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.analysis.name_col" | translate }}
                  </label>
                  <div
                    class="m-input-icon m-input-icon--left mb-3"
                    style="width: auto"
                  >
                    <input
                      type="text"
                      class="form-control m-input"
                      placeholder="{{
                        'page.analysis.search_by_name' | translate
                      }}"
                      [(ngModel)]="searchForm.search.name"
                      aria-label="Search by name"
                      id="codeSearch"
                      name="codeSearch"
                      style="width: 100%"
                      (keydown)="changeFirstSearchState()"
                    />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6" *ngIf="userRole == 2">
                <!-- ADMIN = 2 -->
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.analysis.username_col" | translate }}
                  </label>
                  <div
                    class="m-input-icon m-input-icon--left mb-3"
                    style="width: auto"
                  >
                    <input
                      type="text"
                      class="form-control m-input"
                      placeholder="{{
                        'page.analysis.search_by_username' | translate
                      }}"
                      [(ngModel)]="searchForm.search.username"
                      aria-label="Search by username"
                      id="usernameSearch"
                      name="usernameSearch"
                      style="width: 100%"
                      (keydown)="changeFirstSearchState()"
                    />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.analysis.search_by_guid" | translate }}
                  </label>
                  <div
                    class="m-input-icon m-input-icon--left mb-3"
                    style="width: auto"
                  >
                    <input
                      type="text"
                      class="form-control m-input"
                      placeholder="{{
                        'page.analysis.search_by_guid' | translate
                      }}"
                      [(ngModel)]="searchForm.search.id"
                      aria-label="Search by guid"
                      id="emailSearch"
                      name="emailSearch"
                      style="width: 100%"
                      (keydown)="changeFirstSearchState()"
                    />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="col-12 d-flex justify-content-center"
                style="margin: 10px auto"
              >
                <button
                  type="submit"
                  class="btn btn-tworld-important btn-sm mb-3"
                  appLoadingButton
                  data-i18n="common.search_btn"
                  loadingText="{{ 'common.search_btn' | translate }}"
                  [isLoading]="_loading$ | async"
                  [disabled]="isFirstSearch"
                  style="padding: 7px 40px; font-size: 16px"
                ></button>
              </div>
            </form>
          </div>
        </div>
        <br />
        <div class="table-responsive">
          <table class="table table-sm table-hover table-tworld align-middle">
            <thead>
              <tr>
                <th class="text-center">
                  {{ "page.analysis.name_col" | translate }}
                </th>
                <th class="text-center" *ngIf="userRole == 2">
                  {{ "page.analysis.username_col" | translate }}
                </th>
                <th class="text-center">
                  {{ "page.analysis.guid_col" | translate }}
                </th>
                <th class="text-center" style="min-width: 15rem">
                  {{ "page.analysis.updated_at_col" | translate }}
                </th>
                <th class="minimum text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let analysis of pageResponse.rows; let i = index">
                <td class="text-center" style="max-width: 30rem">
                  <a
                    class="scene-hyperlink"
                    href="https://gallery.styly.cc/scene/{{ analysis.id }}"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ analysis.name }}</a
                  >
                </td>
                <td
                  class="text-center"
                  *ngIf="userRole == 2"
                  style="max-width: 40rem"
                >
                  {{ analysis.username }}
                </td>
                <td class="text-center">{{ analysis.id }}</td>
                <td class="text-center">
                  {{ analysis.date | date: "yyyy/MM/dd hh:mm" }}
                </td>
                <td class="minimum text-left align-middle">
                  <div class="">
                    <button
                      class="btn btn-sm btn-success"
                      ngbTooltip="{{
                        'page.analysis.statistical_information' | translate
                      }}"
                      (click)="showStatistic(analysis.id)"
                    >
                      <span>{{
                        "page.analysis.statistical_information" | translate
                      }}</span></button
                    >&nbsp;&nbsp;
                    <button
                      class="btn btn-sm btn-outline-success"
                      (click)="showDetailedLog(analysis.id)"
                      ngbTooltip="{{ 'page.analysis.access_log' | translate }}"
                    >
                      <span>{{ "page.analysis.access_log" | translate }}</span>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="!pageResponse.rows.length" class="no-content">
          {{ "common.no_content_txt" | translate }}
        </div>
        <div class="row" *ngIf="pageResponse.count">
          <div
            class="col-12 d-flex justify-content-center justify-content-md-end"
          >
            <ngb-pagination
              [collectionSize]="pageResponse.count"
              [(page)]="page"
              [rotate]="true"
              [maxSize]="3"
              [pageSize]="searchForm.size"
              [boundaryLinks]="true"
            ></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
