import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminAnalysisService } from 'src/app/services/admin/admin-analysis.services';
import * as _ from 'lodash';
import moment from 'moment';
import { saveAs } from 'file-saver';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DateUtil } from 'src/app/util/date-util';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AnalysisDownloadRequest } from 'src/app/models/analysis';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-modal-detailed-log',
  templateUrl: './modal-detailed-log.component.html',
  styleUrls: ['./modal-detailed-log.component.scss'],
})
export class ModalDetailedLogComponent implements OnInit {
  id: string;
  downloadRequestForm: FormGroup;
  _downloading$ = new BehaviorSubject<boolean>(false);

  constructor(
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private analysisService: AdminAnalysisService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const toDate = moment(new Date()).subtract(1, 'day').toDate();
    const fromDate = moment(toDate).subtract(29, 'days').toDate();
    this.downloadRequestForm = this.formBuilder.group({
      id: [this.id],
      is_all: [true],
      from: [DateUtil.dateToNgbDateObject(fromDate)],
      to: [DateUtil.dateToNgbDateObject(toDate)],
    });
  }

  async download() {
    this._downloading$.next(true);
    const downloadRequest = this.formatDownloadRequest();
    if (this.authService.isTokenExpired()) {
      await this.authService.manualRefreshToken();
    }
    this.analysisService.downloadAccessLog(downloadRequest).subscribe(
      (response) => {
        const blob = new Blob([response], {
          type: 'text/plain;charset=utf-8',
        });
        saveAs(blob, `${this.generateFileName()}.csv`);
        this.toast.success(
          this.translate.instant('page.log.modal.download.success')
        );
        this._downloading$.next(false);
        this.goBack();
      },
      (err) => {
        this._downloading$.next(false);
      }
    );
  }

  formatDownloadRequest(): AnalysisDownloadRequest {
    const dateFormat = 'YYYY-MM-DD';
    const downloadRequestValue = this.downloadRequestForm.value;
    return {
      id: downloadRequestValue.id,
      from: DateUtil.ngbDateObjectToDateStr(
        downloadRequestValue.from,
        dateFormat
      ),
      to: DateUtil.ngbDateObjectToDateStr(downloadRequestValue.to, dateFormat),
    };
  }

  generateFileName() {
    const dateFormat = 'YYYYMMDD';
    const fromDate = DateUtil.ngbDateObjectToDateStr(
      this.downloadRequestForm.value.from,
      dateFormat
    );
    const toDate = DateUtil.ngbDateObjectToDateStr(
      this.downloadRequestForm.value.to,
      dateFormat
    );
    return `accesslog_${this.id}_${fromDate}_${toDate}`;
  }

  goBack() {
    this.modal.close('close');
  }

  fromDatePickerAtrribute(isMin: boolean) {
    if (isMin) {
      const minDate = moment().subtract(1, 'year').toDate();
      return DateUtil.dateToNgbDateObject(minDate);
    }
    const maxDate = this.downloadRequestForm.controls['to'].value;
    return { ...maxDate };
  }

  toDatePickerAtrribute(isMin: boolean) {
    if (isMin) {
      const minDate = this.downloadRequestForm.controls['from'].value;
      return { ...minDate, day: minDate.day + 1 };
    }
    const maxDate = moment().subtract(1, 'day').toDate();
    return DateUtil.dateToNgbDateObject(maxDate);
  }
}
