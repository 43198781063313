<div class="m-grid__item m-grid__item--fluid m-wrapper">
  <div class="m-content">
    <div class="m-portlet m-portlet--tab">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <span class="m-portlet__head-icon m--hide">
              <i class="la la-gear"></i>
            </span>
            <h1 class="tworld-form-header  mb-1 mt-1">
              {{'page.dashboard.heading' | translate}}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title mb-0">{{'page.dashboard.heading_info' | translate}}</h3>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li *ngFor="let item of resInformation" class="list-group-item d-flex justify-content-start" (click)="detailInformation(item.id)">
                <div class="information">
                  <div>
                    <span class="badge badge-primary badge-pill">{{item.publish_date | dateFormat}}</span>
                  </div>
                  <div class="text-left pl-4 title-info">
                    {{item.title}}
                  </div>
                </div>
              </li>
              <li *ngIf="!resInformation.length" class="no-content text-center list-group-item border-0 p-0">
                {{ 'common.no_content_txt' | translate }}
              </li>
              <li *ngIf="resInformation.length > 0" class="list-group-item d-flex justify-content-end align-items-center border-0">
                <a routerLink="/manager/my-information">{{'page.dashboard.more' | translate}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
