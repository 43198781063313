import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit, AfterViewInit {
  @Input() title;
  @Input() confirmMessage;
  @Input() message;
  @Input() type = 'warning';
  @Input() buttonConfirmTitle = 'common.modal.delete.confirm_btn';
  @Input() buttonCancelTitle = 'common.modal.delete.cancel_btn';
  @Input() revertButton = false;

  @ViewChild('buttonCancel') buttonCancel;

  constructor(public modal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // this.buttonCancel.nativeElement.focus();
  }


}
