import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AbstractFormComponent } from 'src/app/components/abstract-form.component';
import { AuthService } from 'src/app/services/auth.service';
import { ERROR_CODE } from '../../../../models/error';
import { Router } from '@angular/router';
import { TYPE } from 'src/app/models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-code-login',
  templateUrl: './modal-code-login.component.html',
  styleUrls: ['./modal-code-login.component.scss'],
})
export class ModalCodeLoginComponent
  extends AbstractFormComponent<any>
  implements OnInit {
  @ViewChild('buttonCancel') buttonCancel;
  @Input() email;
  @Input() password;
  errorCode: ERROR_CODE | null = null;
  isLoading = false;
  constructor(
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      codeConfirm: new FormControl('', [
        Validators.required,
        Validators.maxLength(6),
        Validators.minLength(6),
        Validators.pattern(/^\d+$/),
      ]),
    });
  }

  _onSubmit(formData) {
    this.isLoading = true;
    this.authService
      .loginCodeConfirm(this.email, this.password, formData.codeConfirm.trim())
      .toPromise()
      .then((res: any) => {
        localStorage.setItem('2faAuthorized', '1');
        this.modal.close('close');
        this.translate.use(res.display_lang);
        if (res.type === TYPE.ADMIN) {
          this.router.navigateByUrl('/manager/dashboard');
        } else {
          this.router.navigateByUrl('/manager/my-dashboard');
        }
      })
      .catch((err) => {
        this.toast.error(this.translate.instant('page.dashboard.modal.confirm_setting_2fa.invalid_code'));
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onSuccess() {}
}
