import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { LayoutService } from '../../../services/layout.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { TYPE } from '../../../models/user';

declare var mMenu: any;
declare var mUtil: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mMenu;
  menu = [];
  NORMAL_MENU: Array<any> = [
    { link: 'my-dashboard', icon: 'fa-fw fa fa-home', title: 'sidebar.dashboard', exact: true },
    { link: 'my-license', icon: 'fa-fw fa fa-list', title: 'sidebar.my_license', exact: true },
    { link: 'my-information', icon: 'fa-fw fa fa-info-circle', title: 'sidebar.my_information', exact: true },
    { link: 'my-file', icon: 'fa-fw fa fa-file', title: 'sidebar.file', exact: true },
    { link: 'analysis-management', icon: 'fa-fw fa fa-chart-area', title: 'sidebar.analysis', exact: true },
  ];

  ADMIN: Array<any> = [
    { link: 'dashboard', icon: 'fa-fw fa fa-home', title: 'sidebar.dashboard', exact: true },
    { link: 'account-management', icon: 'fa-fw fa fa-id-card', title: 'sidebar.account', exact: true },
    { link: 'license-management', icon: 'fa-fw fa fa-list', title: 'sidebar.license', exact: true },
    { link: 'information-management', icon: 'fa-fw fa fa-info-circle', title: 'sidebar.information', exact: true },
    { link: 'csv-management', icon: 'fa-fw fa fa-table', title: 'sidebar.csv', exact: true },
    { link: 'file-management', icon: 'fa-fw fa fa-file', title: 'sidebar.file', exact: true },
    { link: 'analysis-management', icon: 'fa-fw fa fa-chart-area', title: 'sidebar.analysis', exact: true },
    { link: 'log-management', icon: 'fa-fw fa fa-file-code', title: 'sidebar.log', exact: true },
    { link: 'scene-management', icon: 'fas fa-clipboard-list', title: 'sidebar.scene', exact: true },
  ];

  mouseEnter;
  mouseLeave;
  isSideBarCanvasOpen;
  sideBarCanvasSubs: Subscription;
  sideBarMinimizeSubs: Subscription;

  constructor(private el: ElementRef,
    public layoutService: LayoutService,
    private render: Renderer2,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService) {
  }

  ngOnInit() {
    this.sideBarMinimizeSubs = this.layoutService.isSideBarMinimize$.subscribe(t => {
      this.registerMouseEvent(t);
    });
    this.sideBarCanvasSubs = this.layoutService.isSidebarCanvasOpen$.subscribe(t => {
      console.log('got sidebar event: ' + t);
      this.isSideBarCanvasOpen = t;
    });

    if (this.authService.currentUserValue.type === TYPE.USER_MANAGER) {
      this.menu = this.NORMAL_MENU;
    }

    if (this.authService.currentUserValue.type === TYPE.ADMIN) {
      this.menu = this.ADMIN;
    }

  }

  registerMouseEvent(shouldRegister: boolean) {
    console.log('Register Mouse Event: ' + shouldRegister);
    if (shouldRegister) {
      this.mouseEnter = this.render.listen(this.el.nativeElement, 'mouseenter', () => {
        this.layoutService.mouseOverSidebar(true);
      });
      this.mouseLeave = this.render.listen(this.el.nativeElement, 'mouseleave', () => {
        this.layoutService.mouseOverSidebar(false);
      });
    } else {
      this.mouseEnter();
      this.mouseLeave();
    }
  }

  isMenuItemIsActive(item): boolean {
    for (const child of item.children) {
      if (this.router.isActive(child.link, false)) {
        return true;
      }
    }
    return false;
  }

  initMenu(): any {
    let menuDesktopMode = 'accordion';
    if (mUtil.attr(this.el.nativeElement, 'm-menu-dropdown') === '1') {
      menuDesktopMode = 'dropdown';
    }

    const options = {
      // vertical scroll
      scroll: false,
      // submenu setup
      submenu: {
        desktop: {
          // by default the menu mode set to accordion in desktop mode
          default: menuDesktopMode,
          // whenever body has this class switch the menu mode to dropdown
          state: {
            body: 'm-aside-left--minimize',
            mode: 'dropdown'
          }
        },
        tablet: 'accordion', // menu set to accordion in tablet mode
        mobile: 'accordion' // menu set to accordion in mobile mode
      },
      // accordion setup
      accordion: {
        autoScroll: false,
        expandAll: true
      }
    };

    this.mMenu = new mMenu(this.el.nativeElement, options);
  }

  ngAfterViewInit(): void {
    this.initMenu();
  }

  ngOnDestroy(): void {
    this.sideBarCanvasSubs.unsubscribe();
    this.sideBarMinimizeSubs.unsubscribe();
  }
}
