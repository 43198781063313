import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractFormComponent} from '../../../../components/abstract-form.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AdminAccountService} from '../../../../services/admin/admin-account.service';
import {AdminLicenseService} from '../../../../services/admin/admin-license.service';
import {License} from '../../../../models/license';
import {SettingService} from '../../../../services/setting.service';
import {ModalConfirmComponent} from '../../../../components/modal/modal-confirm/modal-confirm.component';
import {ERROR_CODE} from '../../../../models/error';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-edit-license',
  templateUrl: './modal-edit-license.component.html',
  styleUrls: ['./modal-edit-license.component.scss']
})
export class ModalEditLicenseComponent extends AbstractFormComponent<any> implements OnInit {

  @ViewChild('buttonCancel') buttonCancel;
  @Input() lId;
  license: License;
  durations: any[];
  isChange = false;
  errorCode: ERROR_CODE | null = null;
  user_id: number;
  constructor(
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private adminAccountService: AdminAccountService,
    private adminLicenseService: AdminLicenseService,
    private settingService: SettingService,
    private modalService: NgbModal,
    private translate: TranslateService,
  ) {
    super();
  }


  ngOnInit() {
    this.adminLicenseService.getByIdLicense(this.lId)
      .subscribe(t => {
        this.license = t;
        this.form = new FormGroup({
          'code': new FormControl({value: this.license.code, disabled: true}, [Validators.maxLength(20)]),
          'username': new FormControl({value: this.license.username !== null ? this.license.username : '', disabled: true},
            [Validators.required, Validators.maxLength(255)]),
          'email': new FormControl({value: this.license.email !== null ? this.license.email : '', disabled: true},
            [Validators.email, Validators.maxLength(255)]),
          'regist_dt': new FormControl(null, [Validators.required]),
          'duration': new FormControl(this.license.duration, [Validators.required]),
          'update_dt': new FormControl(null),
          'regist_sts': new FormControl(this.license.regist_sts, [Validators.required]),
        });

        if (this.license.regist_dt !== null) {
          const date = this.license.regist_dt.split('-');
          const year =  Number(date[0]);
          const month =  Number(date[1]);
          const day =  Number(date[2]);
          this.form.get('regist_dt').setValue({
            year: year ,
            month: month ,
            day: day
          });
        }
        if (this.license.update_dt !== null) {
          const date = this.license.update_dt.split('-');
          const year =  Number(date[0]);
          const month =  Number(date[1]);
          const day =  Number(date[2]);
          this.form.get('update_dt').setValue({
            year: year ,
            month: month ,
            day: day
          });
        }

        this.user_id = this.license.user_id;

        this.form.valueChanges.subscribe(value => {
          this.isChange = true;
        });
      });
    this.settingService.getDurations().subscribe(t => {
      this.durations = t.license_durations;
    });
  }

  _onSubmit(formData) {
    this.isLoading = true;
    let regist_dt;
    if (formData.regist_dt !== null) {
      regist_dt = `${formData.regist_dt.year}-${formData.regist_dt.month}-${formData.regist_dt.day}`;
    } else {
      regist_dt = null;
    }
    let update_dt;
    if (formData.update_dt !== null) {
      update_dt = `${formData.update_dt.year}-${formData.update_dt.month}-${formData.update_dt.day}`;
    } else {
      update_dt = null;
    }
    return this.adminLicenseService.updatedLicense(this.lId, regist_dt, formData.duration, update_dt,
      formData.regist_sts, this.user_id)
      .subscribe(() => {
        this.isLoading = false;
        this.toast.success(this.translate.instant(
          'common.toast_msg.success.edit'
        ));
        this.modal.close('close');
      }, (err) => {
        this.isLoading = false;
        this.errorCode = err.error[0].code;
        if (this.errorCode === ERROR_CODE.regist_dt_date_invalid) {
          this.toast.error('初回登録日 not format!');
        } else if (this.errorCode === ERROR_CODE.update_dt_date_invalid) {
          this.toast.error('更新日 not format!');
        }
      });
  }

  onSuccess() {
  }

  goBack() {
    if (this.isChange) {
      const modal = this.modalService.open(ModalConfirmComponent);
      modal.componentInstance.message = 'common.modal.back.message';
      modal.componentInstance.title = 'common.modal.back.heading';
      modal.result
        .then(() => {
          this.modal.close('close');
        });
    } else {
      this.modal.close('close');
    }
  }
}
