import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AbstractFormComponent } from 'src/app/components/abstract-form.component';
import { ModalConfirmComponent } from 'src/app/components/modal/modal-confirm/modal-confirm.component';
import { AdminSceneService } from 'src/app/services/admin/admin-scene.service';
import { ModalSearchUserLicenseComponent } from '../../license-management/modal-search-user-license/modal-search-user-license.component';
import { SceneModel } from '../../../../models/scene';

@Component({
  selector: 'app-modal-edit-scene',
  templateUrl: './modal-edit-scene.component.html',
  styleUrls: ['./modal-edit-scene.component.scss'],
})
export class ModalEditSceneComponent
  extends AbstractFormComponent<any>
  implements OnInit {
  @ViewChild('buttonCancel') buttonCancel;
  isChange = false;
  id: string;
  scene: SceneModel;

  constructor(
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private translate: TranslateService,
    private sceneService: AdminSceneService,
    private toast: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.id = this.scene.id;
    this.initializeForm();
    this.form.valueChanges.subscribe((value) => {
      this.isChange = true;
    });
  }

  initializeForm() {
    const fb = new FormBuilder();
    this.form = fb.group({
      username: [
        {
          value: this.scene.username,
          disabled: true,
        },
        [Validators.required],
      ],
      id: [this.scene.id, [Validators.required, Validators.maxLength(50)]],
      name: [this.scene.name, [Validators.required, Validators.maxLength(255)]],
      user_id: [this.scene.user_id],
    });
  }

  goBack() {
    if (this.isChange) {
      const modal = this.modalService.open(ModalConfirmComponent);
      modal.componentInstance.title = this.translate.instant(
        'page.scene.modal.back_warning_title'
      );
      modal.componentInstance.message = this.translate.instant(
        'page.scene.modal.back_warning_message.edit'
      );
      modal.result.then(() => {
        this.modal.close(false);
      });
    } else {
      this.modal.close(false);
    }
  }

  openUserSearchModal() {
    const modal = this.modalService.open(ModalSearchUserLicenseComponent);
    modal.componentInstance.emitSerivce.subscribe((val) => {
      this.form.controls['username'].setValue(val.username);
      this.form.controls['user_id'].setValue(val.id);
    });
    modal.result.then(() => {
      this.modal.close('close');
    });
  }

  _onSubmit(formData: any) {
    this.isLoading = true;
    const data: SceneModel = {
      id: formData.id.trim(),
      username: formData.username.trim(),
      name: formData.name.trim(),
      user_id: formData.user_id,
    };
    return this.sceneService.editScene(data, this.id).subscribe(
      () => {
        this.isLoading = false;
        this.toast.success(this.translate.instant('common.toast_msg.success.edit'));
        this.modal.close(true);
      },
      (err) => {
        this.isLoading = false;
        this.translate.instant('common.toast_msg.failed.create');
      }
    );
  }

  onSuccess(resp: any) {
    throw new Error('Method not implemented.');
  }

  autoTrimInput(event, formControlName) {
    this.form.controls[formControlName].patchValue(event.target.value.trim());
  }
}
