import {
  AfterViewInit,
  Component,
  HostListener,
  ViewChild,
} from '@angular/core';
import { LayoutService } from './services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'VRTECH';
  isAppReady = false;
  @ViewChild('splashScreen') splashScreen;

  constructor(
    translate: TranslateService,
    private layoutService: LayoutService,
    private authService: AuthService,
    private toast: ToastrService,
    private router: Router,
    private location: Location
  ) {
    const validRoutes = [];
    this.router.config.forEach((route) => {
      const routePath: string = route.path;
      validRoutes.push(routePath);
      const routeChildren = route.children || [];
      routeChildren.forEach((routeChild) => {
        const routeChildPath: string = route.path + '/' + routeChild.path;
        validRoutes.push(routeChildPath);
      });
    });

    const url = this.location.path();
    const user = JSON.parse(localStorage.getItem('currentUser'));
    translate.setDefaultLang((user && user.display_lang) || 'ja');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use((user && user.display_lang) || 'ja');

    document.addEventListener('visibilitychange', (event) => {
      const currentUser = this.authService.currentUserValue;
      if (
        document.visibilityState === 'visible' &&
        this.isAppReady &&
        currentUser
      ) {
        if (currentUser.two_factor_authen_flg === 1) {
          if (localStorage.getItem('2faAuthorized') !== '1') {
            return;
          }
        }
        if (validRoutes.includes(url.substring(1))) {
          this.toast.success(
            translate.instant('common.toast_msg.welcome_back'),
            '',
            {timeOut: 1000}
          );
        }
      } else {
      }
    });
  }

  ngAfterViewInit(): void {
    this.layoutService.init(this.splashScreen);
    this.isAppReady = true;
  }
}
