import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {User} from '../models/user';
import {AdminAccountService} from '../services/admin/admin-account.service';

@Injectable()
export class AdminAccountResolve implements Resolve<User> {
  constructor(private adminAccountService: AdminAccountService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> | Promise<User> | User {
    return this.adminAccountService.user(route.params.id);
  }
}
