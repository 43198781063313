<div class="m-grid__item m-grid__item--fluid m-wrapper">
  <div class="m-content">
    <div class="m-portlet m-portlet--tab">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <span class="m-portlet__head-icon m--hide">
              <i class="la la-gear"></i>
            </span>
            <h3 class="tworld-form-header">
              {{ "page.my_information.heading" | translate }}
            </h3>
          </div>
        </div>
      </div>
      <div class="m-portlet__body">
        <div class="table-responsive">
          <table class="table table-sm table-hover table-tworld align-middle">
            <thead>
              <tr>
                <th class="text-left information-date">
                  {{ "page.my_information.release_date_col" | translate }}
                </th>
                <th class="text-center information-title">
                  {{ "page.my_information.title_col" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let information of pageResponse.rows">
                <td class="text-left">
                  {{
                    information.publish_date === null
                      ? ""
                      : (information.publish_date | dateFormat)
                  }}
                </td>
                <td
                  class="text-left"
                  (click)="detailInformation(information.id)"
                >
                  <p style="width: 50vw !important">
                    {{ information.title }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!pageResponse.rows.length" class="no-content text-center">
          {{ "common.no_content_txt" | translate }}
        </div>
        <div class="row" *ngIf="pageResponse.count">
          <div class="col-md-8 col-sm-12">
            <ngb-pagination
              [rotate]="true"
              [collectionSize]="pageResponse.count"
              [(page)]="page"
              [maxSize]="3"
              [pageSize]="searchForm.size"
              [boundaryLinks]="true"
            ></ngb-pagination>
          </div>
          <div
            class="col-md-4 col-sm-12 d-flex justify-content-center justify-content-md-end"
          >
            <select
              title="Select Page Size"
              aria-label="Select Page change"
              class="form-control form-control-sm"
              style="width: auto"
              name="pageSize"
              [(ngModel)]="size"
            >
              <option [ngValue]="10">
                {{ "common.pagination.per_page_drd" | translate: { num: 10 } }}
              </option>
              <option [ngValue]="20">
                {{ "common.pagination.per_page_drd" | translate: { num: 20 } }}
              </option>
              <option [ngValue]="50">
                {{ "common.pagination.per_page_drd" | translate: { num: 50 } }}
              </option>
              <option [ngValue]="100">
                {{ "common.pagination.per_page_drd" | translate: { num: 100 } }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
