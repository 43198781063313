import { SearchRequest } from '../models/search-request';
import { HttpParams } from '@angular/common/http';
import { DateUtil } from '../util/date-util';
import { environment } from '../../environments/environment';

export class AbstractService {
  API_URL = `${environment.API_SERVER_URL}/api/manager`;

  static requestToParams(searchRequest: SearchRequest<any>) {
    let params = new HttpParams();
    if (searchRequest.search) {
      const _searchForm = searchRequest.search;
      for (const key of Object.keys(_searchForm)) {
        const value = _searchForm[key];
        if (value != null) {
          if (value instanceof Date) {
            params = params.append(key, DateUtil.browserDateToUtc(value));
          } else if (!Number.isNaN(value) || value.length) {
            params = params.append(key, value);
          }
        }
      }
    }
    params = params.append('page', String(searchRequest.page));
    params = params.append('size', String(searchRequest.size));
    if (searchRequest.sort && searchRequest.sort.length) {
      for (const sort of searchRequest.sort) {
        if (sort.column && sort.dir) {
          params = params.append('sort[]', `${sort.column},${sort.dir}`);
        }
      }
    }

    return params;
  }

  static requestToParamsLimit(searchRequest: SearchRequest<any>) {
    let params = new HttpParams();
    if (searchRequest.search) {
      const _searchForm = searchRequest.search;
      for (const key of Object.keys(_searchForm)) {
        const value = _searchForm[key];
        if (value != null) {
          if (value instanceof Date) {
            params = params.append(key, DateUtil.browserDateToUtc(value));
          } else if (!Number.isNaN(value) || value.length) {
            params = params.append(key, value);
          }
        }
      }
    }
    params = params.append('page', String(searchRequest.page));
    params = params.append('limit', String(searchRequest.size));
    if (searchRequest.sort && searchRequest.sort.length) {
      for (const sort of searchRequest.sort) {
        if (sort.column && sort.dir) {
          params = params.append('sort[]', `${sort.column},${sort.dir}`);
        }
      }
    }

    return params;
  }
}
