// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domain: 'php.local',
  callingCardDomain: ['callingcard.local'],
  reCaptcha: '6LftQZkUAAAAAJObhRwLAt93EbSN3GxotZlkUP-j',
  API_SERVER_URL: 'https://dev-api.cloud.styly.biz',
  DOMAIN_STYLY_CC: 'https://webeditor.styly.cc/',
  SIZE_MB: 2,
  SIZE_CSV_MB: 1,
  URL_ESTIMATION_PAGE: 'https://estimate-dev.styly.biz/',
  SIZE_FILE_IMAGE_MB: 5,
  SIZE_FILE_VIDEO_MB: 300,
  SIZE_FILE_AUDIO_MB: 30,
  SIZE_FILE_3D_PDF_MB: 50,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
