import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SettingService {

  _URL = `${environment.API_SERVER_URL}/api/manager`;

  constructor(
    private http: HttpClient
  ) {}

  getDurations() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.get<any>(`${this._URL}/settings`, {headers: httpHeaders});
  }

}
