import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractFormComponent } from '../../../../components/abstract-form.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { License } from '../../../../models/license';
import { ModalConfirmComponent } from '../../../../components/modal/modal-confirm/modal-confirm.component';
import { ERROR_CODE } from '../../../../models/error';
import { environment } from '../../../../../environments/environment';
import { AdminCsvService } from 'src/app/services/admin/admin-csv.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-edit-csv',
  templateUrl: './modal-edit-csv.component.html',
  styleUrls: ['./modal-edit-csv.component.scss']
})
export class ModalEditCsvComponent extends AbstractFormComponent<any> implements OnInit {
  imageUrl: any = null;
  editFile = true;
  removeUpload = false;
  isUpload = false;
  companyFile: File;
  isUploading = false;

  @ViewChild('buttonCancel') buttonCancel;
  @Input() guid;
  license: License;
  durations: any[];
  isChange = false;
  errorCode: ERROR_CODE | null = null;
  constructor(
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private adminCsvService: AdminCsvService,
    private translate: TranslateService

  ) {
    super();
  }


  ngOnInit() {
    this.form = new FormGroup({
    });
  }

  _onSubmit(formData) {
  }

  onSuccess() {
  }

  upFile($event) {
    const reader = new FileReader();
    const file = $event.target.files[0];
    if ($event.target.files && $event.target.files[0]) {
      this.companyFile = $event.target.files && $event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = file.name;
        this.editFile = false;
        this.removeUpload = true;
        this.isUpload = true;
      };
      this.cd.markForCheck();
    }
  }

  updateCsv() {
    this.isUploading = true;
    const data = new FormData();
    data.append('guid', this.guid);
    data.append('csv', this.companyFile);
    this.adminCsvService.updateCsv(data)
      .subscribe(resp => {
        this.toast.success(this.translate.instant('common.toast_msg.success.upload'));
        this.isUpload = false;
        this.modal.close('close');
        window.scrollTo(0, 0);
        setTimeout(() => {
        }, 5000);
        this.isUploading = false;
      }, (e) => {
        this.toast.error(this.translate.instant('common.toast_msg.failed.upload'));
        this.isUploading = false;
      });
  }

  cancelUpload() {
    this.modal.close('close');
  }

  goBack() {
    if (this.isChange) {
      const modal = this.modalService.open(ModalConfirmComponent);
      modal.componentInstance.message = 'Are you sure come back license list?';
      modal.componentInstance.title = 'Data lost alert';
      modal.result
        .then(() => {
          this.modal.close('close');
        });
    } else {
      this.modal.close('close');
    }
  }
}
