<div class="modal-header">
  <h2 class="modal-title" id="modal-title" style="font-weight: 600;">
    {{ 'page.log.modal.download.heading' | translate}}
  </h2>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
    (click)="modal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form-label">
  <form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form" class="pr-4">
    <div class="form-group row form-item-row">
      <div class="col-12">
        <div class="form-check mb-5">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" name="type" formControlName="type" value="0"
              (change)="typeChange($event)">{{ 'page.log.modal.download.type_all' | translate}}
          </label>
        </div>
      </div>
      <div class="col-12">
        <div class="form-check mb-4">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" name="type" formControlName="type" value="1"
              (change)="typeChange($event)">{{ 'page.log.modal.download.type_stage' | translate}}
          </label>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-12">
        <div style="line-height: 40px">
          <div class="m-input-icon mb-3" style="width: 100%;">
            <div class="input-group">
              <input class="form-control" placeholder="{{
                'page.log.modal.download.from_date_plhder' | translate
              }}" id="start_date" name="start_date" ngbDatepicker
                #start_date="ngbDatepicker" formControlName="start_date"
                (click)="(form.controls.type.value == 1) && start_date.toggle()" [readonly]="true"
                [maxDate]="maxStartDate()">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-12 text-center">～</div>
      <div class="col-lg-5 col-md-5 col-sm-12">
        <div style="line-height: 40px">
          <div class="m-input-icon mb-3" style="width: 100%;">
            <div class="input-group">
              <input class="form-control" placeholder="{{
                'page.log.modal.download.to_date_plhder' | translate
              }}" id="end_date"
                (click)="(form.controls.type.value == 1) && end_date.toggle()" [readonly]="true" name="end_date"
                ngbDatepicker #end_date="ngbDatepicker" formControlName="end_date" [appFormValid]="form.get('end_date')"
                [minDate]="minEndDate()">
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div *ngIf="date_error" class="text text-danger mt-1 text-left">
          <span class="text text-danger mt-1 label-error">
            {{ 'page.log.modal.download.select_one_date' | translate}}
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="form-check mt-4">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" name="type" formControlName="type" value="2"
              (change)="typeChange($event)">{{ 'page.log.modal.download.type_on_display' | translate}}
          </label>
        </div>
        <div *ngIf="type_error" class="text text-danger mt-1 text-left">
          <span class="text text-danger mt-1 label-error">
            {{ 'page.log.modal.download.select_one_type' | translate}}
          </span>
        </div>
      </div>
    </div>
    <div class="text-center form-item-row padding-button-top">
      <button type="button" class="btn btn-md btn-secondary with-button" (click)="goBack()">
        {{ 'page.log.modal.download.cancel_btn' | translate}}
      </button>&nbsp;
      <button type="submit" class="btn btn-md btn-primary with-button" appLoadingButton [isLoading]="isLoading"
        loadingText="processing" data-i18n="page.log.modal.download.confirm_btn">
      </button>&nbsp;
    </div>&nbsp;
  </form>
</div>