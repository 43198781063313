<div
  class="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile
  m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas
  m-footer--push m-aside--offcanvas-default m-scroll-top--shown"
  [ngClass]="{'m-brand--minimize': isSideBarMinimize,
  ' m-aside-left--on': isSideBarCanvasOpen,
  'm-topbar--on': (layoutService.isTopBarOpen | async),
    'm-aside-left--minimize': isSideBarMinimize && !isSideBarMouseOver,
    'm-aside-left--minimize-hover': isSideBarMinimize && isSideBarMouseOver}">
  <div class="m-grid m-grid--hor m-grid--root m-page">
    <app-header></app-header>
    <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
      <app-sidebar></app-sidebar>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>
