import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ModalConfirmComponent } from 'src/app/components/modal/modal-confirm/modal-confirm.component';
import { PageResponse } from 'src/app/models/page-response';
import { SearchRequest } from 'src/app/models/search-request';
import { AdminSceneService } from 'src/app/services/admin/admin-scene.service';
import { AbstractSearchPage } from '../../abstract-search-page';
import { ModalCreateSceneComponent } from './modal-create-scene/modal-create-scene.component';
import { ModalEditSceneComponent } from './modal-edit-scene/modal-edit-scene.component';
import { SceneModel } from '../../../models/scene';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

export interface AdminSceneSearchForm {
  id: string;
  name: string;
  username: string;
}

@Component({
  selector: 'app-scene-management',
  templateUrl: './scene-management.component.html',
  styleUrls: ['./scene-management.component.scss'],
})
export class SceneManagementComponent
  extends AbstractSearchPage<AdminSceneSearchForm, SceneModel>
  implements OnInit {
  isFirstSearch = true;

  constructor(
    private sceneService: AdminSceneService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private toast: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.search();
  }

  _initSearchForm(): SearchRequest<AdminSceneSearchForm> {
    return <SearchRequest<AdminSceneSearchForm>>{
      size: 20,
      page: 1,
      search: {},
      sort: [],
    };
  }

  _search(
    searchForm: SearchRequest<AdminSceneSearchForm>
  ): Observable<PageResponse<SceneModel>> {
    const _searchForm = _.cloneDeep(searchForm);
    return this.sceneService.search(_searchForm);
  }

  createScene() {
    const modalRef = this.modalService.open(ModalCreateSceneComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modalRef.result.then(
      (res) => {
        if (res) {
          this.search();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  editScene(scene: SceneModel) {
    const modalRef = this.modalService.open(ModalEditSceneComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modalRef.componentInstance.scene = scene;
    modalRef.result.then(
      (res) => {
        if (res) {
          this.search();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteScene(scene: SceneModel) {
    const modal = this.modalService.open(ModalConfirmComponent);
    modal.componentInstance.title = this.translate.instant(
      'page.scene.delete_scene_warning_title'
    );
    modal.componentInstance.message = this.translate.instant(
      'page.scene.delete_scene_warning_msg'
    );
    modal.componentInstance.revertButton = true;
    modal.result.then(() => {
      this.sceneService.deleteScene(scene.id).subscribe(
        () => {
          this.toast.success(
            this.translate.instant('common.toast_msg.success.delete')
          );
          this.search();
        },
        (err) => {
          this.toast.error(
            this.translate.instant('common.toast_msg.failed.delete')
          );
          console.log(err);
        }
      );
    });
  }

  changeFirstSearchState() {
    this.isFirstSearch = false;
  }
}
