import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../../../services/admin/dashboard.service';
import { MyModalDetailInformationComponent } from '../my-information/my-modal-detail-information/my-modal-detail-information.component';

@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.scss']
})
export class MyDashboardComponent implements OnInit {
  resInformation: any = [];
  constructor(
    private dashboardService: DashboardService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.getLatestInformation();
  }

  /** get latest informations record */
  getLatestInformation() {
    this.dashboardService.getLatestInformation().subscribe((res: any) => {
      this.resInformation = res.rows;
    });
  }

  detailInformation(iId) {
    const modalRef = this.modalService.open(MyModalDetailInformationComponent, {backdrop: 'static', keyboard: false, size: 'lg'});
    modalRef.componentInstance.iId = iId;
    modalRef.result.then(() => {
    }, err => {
      console.log(err);
    });
  }
}
