<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    {{ 'page.file.modal.confirm_quit.title' | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" [innerHTML]="'page.file.modal.confirm_quit.message' | translate">

</div>
<div class="modal-footer">
  <button type="button" #buttonCancel class="btn btn-outline-secondary button-delete"
    (click)="modal.dismiss('cancel click')">{{ 'page.file.modal.confirm_quit.cancel_btn' | translate }}</button>
  <button type="button" class="btn btn-danger" (click)="closeModal()">{{ 'page.file.modal.confirm_quit.leave_page_btn' | translate }}</button>
</div>
