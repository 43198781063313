import {Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {User} from '../../../models/user';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalChangePasswordComponent} from '../../../pages/modal-change-password/modal-change-password.component';

@Component({
  selector: 'app-user-language-dropdown',
  templateUrl: './user-language-dropdown.component.html',
  styleUrls: ['./user-language-dropdown.component.scss']
})
export class UserLanguageDropdownComponent implements OnInit {
  @HostBinding('class')
    // tslint:disable-next-line:max-line-length
  classes = ['m-nav__item m-topbar__user-language m-topbar__user-language--img m-dropdown m-dropdown--medium',
    'm-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light'].join(' ');

  @HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

  @Input() avatar = 'assets/images/avatar.png';

  @ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;

  user: User;
  currentLanguage: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.authService.currentUser.subscribe(value => {
      this.user = value;
    });

    this.currentLanguage = this.user && this.user.display_lang || 'jp';
  }

  public logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigateByUrl('/');
    });
  }

  changePassword() {
    const modalRef = this.modalService.open(ModalChangePasswordComponent, {backdrop: 'static', keyboard: false, size: 'lg'});
    modalRef.result.then(() => {
    }, err => {
      console.log(err);
    });
  }

  changeLanguage(lang) {
    this.translate.use(lang);
    this.currentLanguage = lang;
    this.authService.changeLanguage(lang).subscribe(() => {
    });
  }
}
