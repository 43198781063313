import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  readonly DT_FORMAT = 'YYYY/MM/DD';

  parse(value: string): NgbDateStruct {
      if (value) {
        value = value.trim();
        moment(value, this.DT_FORMAT);

        const parts = value.split('/');
        return { year: +parts[0], month: +parts[1], day: +parts[2] };
      }

      return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) { return ''; }

    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) { return ''; }

    return mdt.format(this.DT_FORMAT);
  }
}
