import {Component, OnInit} from '@angular/core';
import {LayoutService} from '../../../services/layout.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {User} from '../../../models/user';
import {ModalCreateAccountComponent} from '../../../pages/admin/account-management/modal-create-account/modal-create-account.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isSideBarMinimize = false;
  isSideBarCanvasOpen = false;
  isUserInfoOpen = false;
  isTopBarOpen = false;
  user: User;

  constructor(private layoutService: LayoutService,
              private authService: AuthService,
              private router: Router) {
    this.user = authService.currentUserValue;
  }

  ngOnInit() {
    this.layoutService.isSidebarCanvasOpen$.subscribe(t => {
      this.isSideBarCanvasOpen = t;
    });
    this.layoutService.isTopBarOpen.subscribe(t => {
      this.isTopBarOpen = t;
    });
  }

  signOut() {
    this.authService.logout().subscribe(value => {
      this.router.navigateByUrl('/login');
    });
  }

  toggleUserInfo() {
    this.isUserInfoOpen = !this.isUserInfoOpen;
  }

  toggleSidebar() {
    this.isSideBarMinimize = !this.isSideBarMinimize;
    this.layoutService.minimizeSideBar(this.isSideBarMinimize);
  }

  toggleSidebarCanvas() {
    this.isSideBarCanvasOpen = !this.isSideBarCanvasOpen;
    this.layoutService.showSidebarCanvas(this.isSideBarCanvasOpen);
  }

  toggleTopBar() {
    this.isTopBarOpen = !this.isTopBarOpen;
    this.layoutService.isTopBarOpenSource.next(this.isTopBarOpen);
  }
}
