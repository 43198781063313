import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractFormComponent } from '../../../../components/abstract-form.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdminAccountService } from '../../../../services/admin/admin-account.service';
import { SettingService } from '../../../../services/setting.service';
import { AdminLicenseService } from '../../../../services/admin/admin-license.service';
import { ERROR_CODE } from '../../../../models/error';
import { ModalConfirmComponent } from '../../../../components/modal/modal-confirm/modal-confirm.component';
import { ModalSearchUserLicenseComponent } from '../modal-search-user-license/modal-search-user-license.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-create-license',
  templateUrl: './modal-create-license.component.html',
  styleUrls: ['./modal-create-license.component.scss'],
})
export class ModalCreateLicenseComponent
  extends AbstractFormComponent<any>
  implements OnInit {
  errorCode: ERROR_CODE | null = null;
  @ViewChild('buttonCancel') buttonCancel;
  isChange = false;
  durations: any[];
  user_id = null;
  constructor(
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private settingService: SettingService,
    private adminLicenseService: AdminLicenseService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      username: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(255),
      ]),
      regist_dt: new FormControl('', [Validators.required]),
      duration: new FormControl('', [Validators.required]),
      update_dt: new FormControl(null),
      regist_sts: new FormControl('', [Validators.required]),
    });

    this.settingService.getDurations().subscribe((t) => {
      this.durations = t.license_durations;
    });
    this.form.valueChanges.subscribe((value) => {
      this.isChange = true;
    });
  }

  _onSubmit(formData) {
    let regist_dt;
    if (formData.regist_dt !== null) {
      regist_dt = `${formData.regist_dt.year}-${formData.regist_dt.month}-${formData.regist_dt.day}`;
    } else {
      regist_dt = null;
    }
    let update_dt;
    if (formData.update_dt !== null) {
      update_dt = `${formData.update_dt.year}-${formData.update_dt.month}-${formData.update_dt.day}`;
    } else {
      update_dt = null;
    }
    this.isLoading = true;
    return this.adminLicenseService
      .createLicense(
        regist_dt,
        update_dt,
        formData.duration,
        formData.regist_sts,
        this.user_id,
        formData.email
      )
      .subscribe(
        () => {
          this.isLoading = false;
          this.toast.success(
            this.translate.instant('common.toast_msg.success.create')
          );
          this.modal.close('close');
        },
        (err) => {
          this.isLoading = false;
          this.errorCode = err.error[0].code;
          if (this.errorCode === ERROR_CODE.user_id_requrired) {
            this.toast.error('User id is required!');
          } else if (this.errorCode === ERROR_CODE.regist_dt_date_invalid) {
            this.toast.error('初回登録日 not format!');
          } else if (this.errorCode === ERROR_CODE.update_dt_date_invalid) {
            this.toast.error('更新日 not format!');
          }
        }
      );
  }

  onSuccess() {}

  goBack() {
    if (this.isChange) {
      const modal = this.modalService.open(ModalConfirmComponent);
      modal.componentInstance.message = 'common.modal.back.message';
      modal.componentInstance.title = 'common.modal.back.heading';
      modal.result.then(() => {
        this.modal.close('close');
      });
    } else {
      this.modal.close('close');
    }
  }

  modalSearch() {
    const modal = this.modalService.open(ModalSearchUserLicenseComponent);
    modal.componentInstance.emitSerivce.subscribe((val) => {
      this.form.get('username').setValue(val.username);
      this.user_id = val.id;
    });
    modal.result.then(() => {
      this.modal.close('close');
    });
  }
}
