<div class="modal-header">
  <div
    class="w-100 row justify-content-between align-items-center pl-4 pr-1 py-1"
  >
    <h2 class="modal-title" id="modal-title" style="font-weight: 600">
      2要素認証
    </h2>
    <button
      type="button"
      class="close p-0"
      aria-label="Close button"
      aria-describedby="modal-title"
      style="font-size: 20px"
      (click)="modal.dismiss('close')"
    >
    <i class="fa fa-times" aria-hidden="true" style="font-size: 20px"></i>
    </button>
  </div>
</div>
<div class="modal-body form-label">
  <span>{{ "page.dashboard.modal.setting_2fa.message" | translate }}</span>
  <form
    (ngSubmit)="form.valid && onSubmit()"
    [formGroup]="form"
    class="form-input-code"
  >
    <div class="form-group row">
      <div class="col-md-2 col-lg-2 col-sm-4"></div>
      <div class="col-md-8 col-lg-8 col-sm-4">
        <div class="input-code">
          <input
            type="password"
            class="form-control"
            name="codeConfirm"
            id="codeConfirm"
            formControlName="codeConfirm"
          />
          <div
            *ngIf="form.controls.codeConfirm.touched"
            class="text text-danger mt-1 text-left text-error"
          >
            <span
              *ngIf="form.controls.codeConfirm.errors?.required"
              class="text text-danger mt-1"
            >
              {{ "validation.field_required" | translate }}
            </span>
            <span
              *ngIf="form.controls.codeConfirm.errors?.pattern"
              class="text text-danger mt-1"
            >
              {{ "validation.field_format" | translate }}
            </span>
            <span
              *ngIf="
                form.controls.codeConfirm.errors?.maxlength ||
                form.controls.codeConfirm.errors?.minlength
              "
              class="text text-danger mt-1"
            >
              {{
                "validation.field_max_length" | translate | replace: "{max}":"6"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-lg-2 col-sm-4"></div>
    </div>

    <div class="text-center form-item-row padding-button-top btns-confirm-code">
      <a
        class="btn btn-md btn-secondary with-button"
        (click)="modal.dismiss('close')"
      >
        {{ "page.scene.modal.back_btn" | translate }} </a
      >&nbsp;
      <button
        type="submit"
        class="btn btn-md btn-primary with-button"
        data-i18n="page.dashboard.modal.setting_2fa.confirm_btn"
        appLoadingButton
        [isLoading]="isLoading"
        loadingText="{{ 'common.processing_text' | translate }}"
        [disabled]="!form.valid"
      ></button
      >&nbsp;
    </div>
    &nbsp;
  </form>
</div>
