import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-success-send-email',
  templateUrl: './modal-success-send-email.component.html',
  styleUrls: ['./modal-success-send-email.component.scss']
})
export class ModalSuccessSendEmailComponent implements OnInit {

  constructor(
    public modal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
  }

}
