import {Pipe, PipeTransform} from '@angular/core';
import {format} from 'date-fns';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, args: any): any {
    if (args) {
      return format(value, args.format);
    }
    return format(value, 'YYYY/MM/DD HH:mm');
  }

}
