<div *ngIf="form">
  <div class="modal-header">
    <h2 class="modal-title" id="modal-title" style="font-weight: 600;">{{ 'page.information.modal.edit.heading' | translate }}</h2>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="goBack()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body form-label">
    <form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form">
      <div class="form-group row form-item-row">
        <div class="col-lg-9 col-md-9 col-sm-12">
          <input class="form-control m-input" name="title" id="title" type="text" formControlName="title"
            placeholder="{{ 'page.information.modal.edit.title_plh' | translate }}">
          <div *ngIf="form.controls.title.touched" class="text text-danger mt-1 text-left">
            <span *ngIf="form.controls.title.errors?.required || (errorCode == 'title_requrired')" class="text text-danger mt-1 label-error">
              {{ "page.information.modal.error_message.title_requrired" | translate}}
            </span>
            <span *ngIf="form.controls.title.errors?.maxlength" class="text text-danger mt-1 label-error">
              {{ "page.information.modal.error_message.title_exceed_max_length" | translate}}
            </span>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <input class="form-control m-input" name="publish_date" id="publish_date" type="text"
            formControlName="publish_date" disabled="disabled">
        </div>
      </div>
      <div class="form-group row form-item-row">
        <div class="col-12">
          <div id="quill" >
            <quill-editor id="content" placeholder="{{ 'page.information.modal.edit.content_plh' | translate }}"
              formControlName="content" required>
            </quill-editor>
          </div>
          <div *ngIf="form.controls.content.touched" class="text text-danger mt-1 text-left">
            <span *ngIf="form.controls.content.errors?.required" class="text text-danger mt-1 label-error">
              {{ "page.information.modal.error_message.content_requrired" | translate}}
            </span>
            <span *ngIf="form.controls.content.errors?.maxlength" class="text text-danger mt-1 label-error">
              {{ "page.information.modal.error_message.content_exceed_max_length" | translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="text-center form-item-row padding-button-top">
        <button type="submit" class="btn btn-md btn-primary with-button mt-2" appLoadingButton [isLoading]="isSaveLoading"
          loadingText="{{ 'common.processing_text' | translate }}" [disabled]="!form.valid" data-i18n="page.information.modal.edit.save_btn">
        </button>&nbsp;
        <button *ngIf="isPublic(form.controls.publish_flg.value)" type="button" class="btn btn-md btn-primary with-button mt-2"
          appLoadingButton [isLoading]="isPublicLoading" data-i18n="page.information.modal.edit.private_btn"
          (click)="submitInfomationPublic(0)" loadingText="{{ 'common.processing_text' | translate }}" [disabled]="!form.valid">
        </button>&nbsp;
        <button *ngIf="!isPublic(form.controls.publish_flg.value)" type="button" class="btn btn-md btn-primary with-button mt-2"
          appLoadingButton [isLoading]="isPublicLoading" data-i18n="page.information.modal.edit.publish_btn"
          (click)="submitInfomationPublic(1)" loadingText="{{ 'common.processing_text' | translate }}" [disabled]="!form.valid">
        </button>&nbsp;
        <button type="button" class="btn btn-md btn-secondary with-button mt-2" (click)="goBack()">
          {{ 'page.information.modal.edit.cancel_btn' | translate }}
        </button>&nbsp;
      </div>&nbsp;
    </form>
  </div>
</div>