import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractFormComponent } from '../../../../components/abstract-form.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminAccountService } from '../../../../services/admin/admin-account.service';
import { AdminInformationService } from '../../../../services/admin/admin-information.service';
import { Information } from '../../../../models/information';
import { SettingService } from '../../../../services/setting.service';
import { ModalConfirmComponent } from '../../../../components/modal/modal-confirm/modal-confirm.component';
import { ERROR_CODE } from '../../../../models/error';
import { ModalEditInformationComponent } from '../modal-edit-information/modal-edit-information.component';

@Component({
  selector: 'app-modal-detail-information',
  templateUrl: './modal-detail-information.component.html',
  styleUrls: ['./modal-detail-information.component.scss']
})
export class ModalDetailInformationComponent extends AbstractFormComponent<any> implements OnInit {

  @ViewChild('buttonCancel') buttonCancel;
  @Input() iId;
  information: Information;
  durations: any[];
  isChange = false;
  errorCode: ERROR_CODE | null = null;
  constructor(
    public adminInformationService: AdminInformationService,
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private settingService: SettingService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit() {
    this.adminInformationService.getByIdInformation(this.iId)
      .subscribe(t => {
        this.information = t;
        this.form = new FormGroup({
          'title': new FormControl({ value: this.information.title, disabled: true }),
          'content': new FormControl({ value: this.information.content, disabled: true }),
          'publish_date': new FormControl({ value: this.information.publish_date ?
            this.information.publish_date.toString().slice(0, -3).split('-').join('/') : '', disabled: true }),
          'publish_flg': new FormControl({ value: this.information.publish_flg, disabled: true }),
        });

        this.form.valueChanges.subscribe(value => {
          this.isChange = true;
        });
      }, (err) => {
        this.toast.success(err.error.message);
        this.modal.close('close');
      });
    this.settingService.getDurations().subscribe(t => {
      this.durations = t.information_durations;
    });
  }

  _onSubmit(formData) {
  }

  editInformation() {
    const modalRef = this.modalService.open(ModalEditInformationComponent, {backdrop: 'static', keyboard: false, size: 'lg'});
    modalRef.componentInstance.iId = this.iId;
    modalRef.result.then(() => {
      this.modal.close('close');
    }, err => {
      console.log(err);
    });
  }

  onSuccess() {
  }

  goBack() {
    this.modal.close('close');
  }
}
