<div class="modal-header">
  <div class="w-100 row justify-content-between align-items-center pl-4 pr-1 py-1">
    <h4 class="modal-title" id="modal-title">{{ title | translate }}</h4>
    <button
      type="button"
      class="close p-0"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss(false)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div
  class="modal-body"
  [innerHTML]="message || confirmMessage | translate"
></div>
<div class="modal-footer">
  <div style="display: flex">
    <button
      type="button"
      [ngStyle]="{ order: revertButton ? 2 : 1 }"
      #buttonCancel
      class="btn btn-outline-secondary button-delete mx-2"
      (click)="modal.dismiss(false)"
    >
      {{ buttonCancelTitle | translate }}
    </button>
    <button type="button" class="btn btn-danger" (click)="modal.close(true)">
      {{ buttonConfirmTitle | translate }}
    </button>
  </div>
</div>
