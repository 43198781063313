import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileModel } from 'src/app/models/file';

@Component({
  selector: 'app-converting-error-modal',
  templateUrl: './converting-error-modal.component.html',
  styleUrls: ['./converting-error-modal.component.scss']
})
export class ConvertingErrorModalComponent implements OnInit {

  @Input() file: FileModel;

  constructor(
    public modal: NgbActiveModal
  ) {
  }

  ngOnInit() {

  }
}
