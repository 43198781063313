import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { WINDOW } from '../../providers/window.provider';
import { Router } from '@angular/router';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalLoginComponent } from '../login/modal-login.component';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { TYPE, User } from 'src/app/models/user';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  lang;
  urlEstimation: any;
  user: User;
  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private layoutService: LayoutService,
    private els: ElementRef,
    private translate: TranslateService,
    private modalService: NgbModal,
    private authService: AuthService
  ) {
    this.lang = translate.currentLang;
    layoutService.splashScreenVisible(true);
  }

  ngOnInit() {
    this.user = this.authService.currentUserValue;
    if (this.user) {
      if (this.user.type === TYPE.ADMIN) {
        this.router.navigateByUrl('/manager/dashboard');
      } else {
        this.router.navigateByUrl('/manager/my-dashboard');
      }
    }

    this.resetPwdAndOpenLogin();
    this.loadScript();
    this.onComplete();
    this.getUrlEstimationPage();
    this.checkBrowser();
  }

  resetPwdAndOpenLogin() {
    if (history.state.isReset) {
      this.modalService.open(ModalLoginComponent, { backdrop: 'static', keyboard: false, centered: true });
    }
  }

  // check browser is IE
  checkBrowser() {
    const isIE = this.detectIEEdge();
    if (isIE) {
      alert('グーグルクローム、モジラ・ファイアーフォックス、マイクロソフト エッジなどのブラウザを使用することをお勧めします。');
    }
  }

  /**
 * detect IEEdge
 * returns version of IE/Edge or false, if browser is not a Microsoft browser
 */
  detectIEEdge() {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return true;
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return true;
    }

    // other browser
    return false;
  }

  getUrlEstimationPage() {
    this.urlEstimation = environment.URL_ESTIMATION_PAGE;
  }

  ngOnDestroy(): void {
    console.log('Destroy');
    if (!this.user) {
      $(window).off('scroll');
    }
  }

  onComplete() {
    this.layoutService.splashScreenVisible(false);
  }


  login() {
    const modalRef = this.modalService.open(ModalLoginComponent, { backdrop: 'static', keyboard: false, centered: true });
    modalRef.result.then(() => {
    }, err => {
      console.log(err);
    });
  }

  public loadScript() {
    let isFound = false;
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('loader')) {
        isFound = true;
      }
    }

    if (!isFound) {
      const dynamicScripts = [
        'https://code.jquery.com/jquery-3.4.0.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js',
        'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js',
        'assets/framework/creative.js'
      ];

      for (let i = 0; i < dynamicScripts.length; i++) {
        const node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        this.els.nativeElement.appendChild(node);
        // document.getElementsByTagName('body')[0].appendChild(node);
      }

    }
  }
}
