import {SearchRequest, Sort} from '../models/search-request';
import {PageResponseFile} from '../models/page-response';
import {BehaviorSubject, Observable} from 'rxjs';

export abstract class AbstractSearchPageFile<T, R> {
  searchForm: SearchRequest<T> = this._initSearchForm();
  pageResponse: PageResponseFile<R> = {
    message: '',
    status: 0,
    result: {
      user_id: null,
      files: [],
      total: 0,
    }
  };
  _loading$ = new BehaviorSubject<boolean>(true);
  searchError = '';

  get page() {
    return this.searchForm.page;
  }

  set page(_page: number) {
    this.searchForm.page = _page;
    this.search();
  }

  get size() {
    return this.searchForm.size;
  }

  set size(_size) {
    this.searchForm.size = _size;
    this.search();
  }

  search() {
    const search: Array<String> = Object.values(this.searchForm.search);
    const isSearch: boolean = search.some(item => !!item);
    if (isSearch) {
      this._loading$.next(true);
    }

    this._search(this.searchForm).subscribe((resp) => {
      this.pageResponse = resp;
      this._loading$.next(false);
    }, error1 => {
      this.searchError = error1.error ? error1.error.error : error1.statusMessage;
      this._loading$.next(false);
    });
  }

  loadMore() {
    this.searchForm.page += 1;
    this._loading$.next(true);
    this._search(this.searchForm).subscribe((resp) => {
      this.pageResponse.result.files = this.pageResponse.result.files.concat(resp.result.files);
      this._loading$.next(false);
    }, error1 => {
      console.log(error1);
      this.searchError = error1.error ? error1.error.error : error1.statusMessage;
      this._loading$.next(false);
    });
  }

  onSort($event: Sort[]) {
    this._onSort($event);
    this.searchForm.sort = $event;
    this.search();
  }

  _onSort($event: Sort[]) {

  }

  abstract _search(searchForm: SearchRequest<T>): Observable<PageResponseFile<R>>;

  abstract _initSearchForm(): SearchRequest<T>;
}
