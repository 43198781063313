<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" [innerHTML]="message">

</div>
<div class="modal-footer">
  <button type="button" #buttonClose class="btn btn-info" (click)="modal.close('Ok click')">{{buttonCloseTitle}}</button>
</div>
