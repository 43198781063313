<div class="modal-header">
  <div class="w-100 row justify-content-between align-items-center pl-4 pr-1 py-1">
    <h2 class="modal-title" id="modal-title" style="font-weight: 600;">{{ 'page.home.modal.forgot_password.heading' | translate }}</h2>
    <button type="button" class="close p-0" aria-label="Close button" aria-describedby="modal-title"
    (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  </div>
</div>
<div class="modal-body form-label">
  <div class="title-forgot-pwd">
    <span>{{ 'page.home.modal.forgot_password.description_txt' | translate }}</span>
  </div>

  <form (ngSubmit)="form.valid && onSubmit()"
        [formGroup]="form">
    <div class="form-group row">
      <div class="col-md-4 col-lg-4 col-sm-4">
        <label class="isRequired" data-required="必須" appRequiredLabel for="email">{{ 'page.home.modal.forgot_password.email_lbl' | translate }}</label>
      </div>
      <div class="col-md-8 col-lg-8 col-sm-4">
        <input type="email" class="form-control" name="email" id="email" formControlName="email">
        <div *ngIf="form.controls.email.touched" class="text text-danger mt-1 text-left">
          <span *ngIf="form.controls.email.errors?.required" class="text text-danger mt-1">
            {{ 'page.home.modal.forgot_password.error_email_required' | translate }}
          </span>
          <span *ngIf="form.controls.email.errors?.email" class="text text-danger mt-1">
            {{ 'page.home.modal.forgot_password.error_email_format' | translate }}
          </span>
          <span *ngIf="form.controls.email.errors?.maxlength" class="text text-danger mt-1">
            メールアドレスは255文字内で入力してください。
          </span>
        </div>
      </div>
    </div>

    <div class="text-center form-item-row padding-button-top">
      <button type="button" class="btn btn-md btn-secondary with-button" (click)="closeModal()">
        {{ 'page.home.modal.forgot_password.cancel_btn' | translate }}
      </button>&nbsp;
      <button type="submit" class="btn btn-md btn-primary with-button" appLoadingButton data-i18n="page.home.modal.forgot_password.confirm_btn" [isLoading]="isLoading" loadingText="{{ 'common.processing_text' | translate }}" [disabled]="!form.valid">
      </button>&nbsp;
    </div>&nbsp;
  </form>
</div>


