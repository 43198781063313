<div class="modal-header">
  <h2 class="modal-title" id="modal-title" style="font-weight: 600">
    {{ "page.account.modal.create.heading" | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('close')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form-label">
  <form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form">
    <legend class="title">
      {{ "page.account.modal.create.member_info.heading" | translate }}
    </legend>

    <div class="form-group row form-item-row">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label
          for="name"
          class="col-form-label isRequired"
          data-required="必須"
          appRequiredLabel
          >{{
            "page.account.modal.create.member_info.login_name_lbl" | translate
          }}</label
        >
        <input
          class="form-control m-input"
          name="name"
          id="name"
          type="text"
          formControlName="name"
        />
        <div
          *ngIf="form.controls.name.touched"
          class="text text-danger mt-1 text-left"
        >
          <span
            *ngIf="form.controls.name.errors?.required"
            class="text text-danger mt-1 label-error"
          >
            {{ "validation.field_required" | translate }}
          </span>
          <span
            *ngIf="form.controls.name.errors?.maxlength"
            class="text text-danger mt-1 label-error"
          >
            {{
              "validation.field_max_length" | translate | replace: "{max}":"255"
            }}
          </span>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12">
        <label
          for="password"
          class="col-form-label isRequired"
          data-required="必須"
          appRequiredLabel
          >{{
            "page.account.modal.create.member_info.password_lbl" | translate
          }}</label
        >
        <div class="pwdMask">
          <input
            [type]="isTextFieldType ? 'text' : 'password'"
            class="form-control m-input password"
            name="password"
            id="password"
            autocomplete="off"
            formControlName="password"
          />
          <span class="focus-input"></span>
          <span
            class="fa fa-eye-slash pwd-toggle hiddenPass"
            *ngIf="!isTextFieldType"
            (click)="hidePass()"
          ></span>
          <span
            class="fa fa-eye pwd-toggle hiddenPass"
            *ngIf="isTextFieldType"
            (click)="hidePass()"
          ></span>
          <span
            class="fa fa-eraser pwd-toggle clearPass"
            *ngIf="form.controls.password.value"
            (click)="clearPass()"
          ></span>
          <span
            class="fa fa-random pwd-toggle randomPass"
            *ngIf="!form.controls.password.value"
            (click)="randomPass()"
          ></span>
        </div>
        <div
          *ngIf="form.controls.password.touched"
          class="text text-danger mt-1 text-left"
        >
          <span
            *ngIf="form.controls.password.errors?.required"
            class="text text-danger mt-1 label-error label-error"
          >
            {{ "validation.field_required" | translate }}
          </span>
          <span
            *ngIf="
              form.controls.password.errors?.maxlength ||
              form.controls.password.errors?.minlength
            "
            class="text text-danger mt-1 label-error"
          >
            {{
              "validation.field_between"
                | translate
                | replace: "{min}":"8"
                | replace: "{max}":"32"
            }}
          </span>
          <span
            *ngIf="form.controls.password.errors?.passwordStrength"
            class="text text-danger mt-1 label-error"
          >
            {{ "page.home.modal.login.error_format_password_txt" | translate}}
          </span>
          <span
            *ngIf="form.controls.password.errors?.notContainUserName"
            class="text text-danger mt-1 label-error"
          >
            {{ "page.dashboard.modal.change_password.error_password_contain_username" | translate}}
          </span>
        </div>
      </div>
    </div>
    <div class="form-group row form-item-row">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label
          for="email"
          class="col-form-label isRequired"
          data-required="必須"
          appRequiredLabel
          >{{
            "page.account.modal.create.member_info.email_lbl" | translate
          }}</label
        >
        <input
          class="form-control m-input"
          name="email"
          id="email"
          type="email"
          formControlName="email"
          (keyup)="removeError()"
        />
        <div
          *ngIf="form.controls.email.touched"
          class="text text-danger mt-1 text-left"
        >
          <span
            *ngIf="form.controls.email.errors?.required"
            class="text text-danger mt-1 label-error"
          >
            {{ "validation.field_required" | translate }}
          </span>
          <span *ngIf="error_email" class="text text-danger mt-1 label-error">
            {{ "validation.email_unique" | translate }}
          </span>
          <span
            *ngIf="
              form.controls.email.errors?.email ||
              form.controls.email.errors?.pattern
            "
            class="text text-danger mt-1 label-error"
          >
            {{ "validation.field_format" | translate }}
          </span>
          <span
            *ngIf="form.controls.email.errors?.maxlength"
            class="text text-danger mt-1 label-error"
          >
            {{
              "validation.field_max_length" | translate | replace: "{max}":"255"
            }}
          </span>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label
          for="email_confirmation"
          class="col-form-label isRequired"
          data-required="必須"
          appRequiredLabel
          >{{
            "page.account.modal.create.member_info.email_confirm_lbl"
              | translate
          }}</label
        >
        <input
          class="form-control m-input"
          name="email_confirmation"
          id="email_confirmation"
          type="email"
          formControlName="email_confirmation"
        />
        <div
          *ngIf="form.controls.email_confirmation.touched"
          class="text text-danger mt-1 text-left"
        >
          <span
            *ngIf="form.controls.email_confirmation.errors?.required"
            class="text text-danger mt-1 label-error"
          >
            {{ "validation.field_required" | translate }}
          </span>
          <span
            *ngIf="
              form.controls.email_confirmation.errors?.email ||
              form.controls.email_confirmation.errors?.pattern
            "
            class="text text-danger mt-1 label-error"
          >
            {{ "validation.field_format" | translate }}
          </span>
          <span
            *ngIf="form.controls.email.errors?.maxlength"
            class="text text-danger mt-1 label-error"
          >
            {{
              "validation.field_max_length" | translate | replace: "{max}":"255"
            }}
          </span>
          <span
            *ngIf="form.controls.email_confirmation.errors?.mustMatch"
            class="text text-danger mt-1 label-error"
          >
            {{ "validation.field_must_match" | translate }}
          </span>
        </div>
      </div>
    </div>

    <div class="form-group row form-item-row">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <label
          for="type"
          class="col-form-label isRequired"
          data-required="必須"
          appRequiredLabel
          >{{
            "page.account.modal.create.member_info.user_type_lbl" | translate
          }}</label
        >
        <select
          class="form-control m-input"
          name="type"
          id="type"
          style="width: 100%"
          formControlName="type"
        >
          <option [ngValue]="0">
            {{ "common.select.user_type.user" | translate }}
          </option>
          <option [ngValue]="2">
            {{ "common.select.user_type.admin" | translate }}
          </option>
        </select>
      </div>
    </div>

    <div class="text-center form-item-row padding-button-top">
      <button
        type="button"
        class="btn btn-md btn-secondary with-button"
        (click)="goBack()"
      >
        {{ "page.account.modal.create.cancel_btn" | translate }}</button
      >&nbsp;
      <button
        type="submit"
        class="btn btn-md btn-primary with-button"
        appLoadingButton
        data-i18n="page.account.modal.create.confirm_btn"
        [isLoading]="isLoading"
        loadingText="{{ 'common.progess_text' | translate }}"
        [disabled]="!form.valid || error_email"
      ></button
      >&nbsp;
    </div>
    &nbsp;
  </form>
</div>
