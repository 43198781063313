import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractFormComponent} from '../../../../components/abstract-form.component';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AdminAccountService} from '../../../../services/admin/admin-account.service';
import {User} from '../../../../models/user';
import {MustMatch} from '../../../../validators/mail.validator';
import {ToastrService} from 'ngx-toastr';
import {ModalConfirmComponent} from '../../../../components/modal/modal-confirm/modal-confirm.component';
import {ERROR_CODE} from '../../../../models/error';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-edit-account',
  templateUrl: './modal-edit-account.component.html',
  styleUrls: ['./modal-edit-account.component.scss']
})
export class ModalEditAccountComponent extends AbstractFormComponent<any> implements OnInit {
  errorCode: ERROR_CODE | null = null;
  user: User;
  isTextFieldType: boolean;
  @Input() aId;
  @ViewChild('buttonCancel') buttonCancel;
  isChange = false;
  constructor(
    public modal: NgbActiveModal,
    private adminAccountService: AdminAccountService,
    private formBuilder: FormBuilder,
    private toast: ToastrService,
    private modalService: NgbModal,
    private translate: TranslateService,
  ) {
    super();
  }


  ngOnInit() {
    this.adminAccountService.user(this.aId)
      .subscribe(t => {
        this.user = t;
        this.form = this.formBuilder.group({
          'name': new FormControl(this.user.name !== null ? this.user.name : '', [Validators.required, Validators.maxLength(255)]),
          'email': new FormControl(this.user.email !== null ? this.user.email : '',
            [Validators.required, Validators.email, Validators.maxLength(255),
              Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
          'type': new FormControl({value: this.user.type, disabled: false}),
          'two_factor_authen_flg': [this.user.two_factor_authen_flg],
        });

        this.form.valueChanges.subscribe(() => {
          this.isChange = true;
        });
      });
  }

  hidePass() {
    this.isTextFieldType = !this.isTextFieldType;
  }

  _onSubmit(formData) {
    this.isLoading = true;
    return this.adminAccountService.updatedUser(this.aId, formData.name.trim(), formData.email.trim(), formData.type)
      .subscribe(() => {
        this.isLoading = false;
        this.toast.success(this.translate.instant(
          'common.toast_msg.success.edit'
        ));
        this.modal.close('close');
      }, (err) => {
        this.isLoading = false;
        this.errorCode = err.error[0].code;
      if (this.errorCode === ERROR_CODE.password_apha_num) {
          this.toast.error('Password only apha and number');
        }
        this.toast.error(this.translate.instant(
          'common.toast_msg.failed.edit'
        ));
      });
  }

  onSuccess() {
  }

  goBack() {
    if (this.isChange) {
      const modal = this.modalService.open(ModalConfirmComponent);
      modal.componentInstance.message = 'page.account.modal.back_msg';
      modal.componentInstance.title = 'page.account.modal.back_title';
      modal.result
        .then(() => {
          this.modal.close('close');
        });
    } else {
      this.modal.close('close');
    }
  }

  toggle2fa() {
    const modal = this.modalService.open(ModalConfirmComponent);
    modal.componentInstance.title = '';
    modal.componentInstance.message = 'page.account.modal.edit.member_info.cancel_2fa.message';
    modal.result
      .then(() => {
        return this.adminAccountService.toggle2fa(this.aId)
        .subscribe(() => {
          this.isLoading = false;
          this.toast.success('Destroy 2FA Success!');
          this.modal.close('close');
        }, (err) => {
          this.isLoading = false;
          modal.close('close');
        });
      })
      .catch(() => {
        modal.close('close');
      });
  }

  restorePassword() {
    const modal = this.modalService.open(ModalConfirmComponent);
    modal.componentInstance.title = 'page.account.modal.edit.member_info.reissue_password.heading';
    modal.componentInstance.message = `${
      this.translate.instant('page.account.modal.edit.member_info.reissue_password.message')
    }\n${this.user.email}`;
    modal.result
      .then(() => {
        return this.adminAccountService.resetUserPassword(this.aId)
        .subscribe(() => {
          this.isLoading = false;
          this.toast.success(this.translate.instant('page.account.toast_msg.restore_password_email_sent'));
          this.modal.close('close');
        }, (err) => {
          this.isLoading = false;
          modal.close('close');
        });
      })
      .catch(() => {
        modal.close('close');
      });
  }
}
