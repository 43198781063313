import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Directive({
  selector: '[appFormValid]'
})
export class FormValidDirective implements OnInit {
  @Input('appFormValid') input: AbstractControl;
  @Input() errorMessage;
  errorEls;
  @Input() parentEls;

  ERROR_MESSAGE = {
    min: 'Min value {0}.',
    max: 'Max value {0}.',
    required: 'Required field.',
    email: 'Invalid email.',
    minlength: 'Min length {0} character, current length ({1}).',
    maxlength: 'Max length {0} character, current length ({1}).',
    pattern: 'Pattern {0} invalid.',
    fileExt: 'File not allow extends',
    fileSize: 'File size is invalid',
    validatePhone: 'Phone invalid',
    matDatepickerParse: 'Invalid date',
    ngbDate: 'Invalid date'
  };

  constructor(private el: ElementRef, private render2: Renderer2) {
  }

  onValueChange() {
    if (this.errorEls) {
      this.render2.removeChild(this.parentEls, this.errorEls);
      this.render2.removeClass(this.el.nativeElement, 'is-invalid');
    }
    if (this.isFieldInvalid()) {
      this.errorEls = this.buildErrorEls();
      this.render2.addClass(this.el.nativeElement, 'is-invalid');
      this.render2.appendChild(this.parentEls, this.errorEls);
    }
  }

  isFieldInvalid() {
    return this.input.invalid && (this.input.touched || this.input.dirty);
  }

  buildErrorEls() {
    const div = this.render2.createElement('div');
    this.render2.addClass(div, 'invalid-feedback');
    Object.keys(this.input.errors).forEach(value => {
      const error = this.input.errors[value];
      const p = this.render2.createElement('p');
      if (this.errorMessage) {
        this.ERROR_MESSAGE = {...this.ERROR_MESSAGE, ...this.errorMessage};
      }

      let errorMessage: string = this.ERROR_MESSAGE[value];
      if (errorMessage === undefined) {
        console.log('Not support error: ' + value);
        console.log(error);
      }
      switch (value) {
        case 'min':
          errorMessage = errorMessage.replace('{0}', error.min);
          break;
        case 'max':
          errorMessage = errorMessage.replace('{0}', error.max);
          break;
        case 'minlength':
          errorMessage = errorMessage.replace('{0}', error.requiredLength);
          errorMessage = errorMessage.replace('{1}', error.actualLength);
          break;
        case 'maxlength':
          errorMessage = errorMessage.replace('{0}', error.requiredLength);
          errorMessage = errorMessage.replace('{1}', error.actualLength);
          break;
        case 'pattern':
          errorMessage = errorMessage.replace('{0}', error.requiredPattern);
          break;
        case 'allowCountries':
          errorMessage = errorMessage.replace('{0}', error.value);
          break;
        case 'validPhoneExist':
          errorMessage = errorMessage.replace('{0}', error.value);
          break;
        case 'validNotSupportCountry':
          errorMessage = errorMessage.replace('{0}', error.value);
          break;
        case 'validNotSupportMobile':
          errorMessage = errorMessage.replace('{0}', error.value);
          break;
        case 'validNotSupportFixLine':
          errorMessage = errorMessage.replace('{0}', error.value);
          break;
        case 'validNotSupportMobileAndFixLine':
          errorMessage = errorMessage.replace('{0}', error.value);
          break;
      }
      const errorText = this.render2.createText(errorMessage);
      this.render2.appendChild(p, errorText);
      this.render2.appendChild(div, p);
    });

    return div;
  }

  ngOnInit(): void {
    if (!this.parentEls) {
      this.parentEls = this.render2.parentNode(this.el.nativeElement);
    }

    /*if (this.parentEls.classList.contains('input-group')
    ) {
        this.parentEls = this.render2.parentNode(this.parentEls);
    }*/

    this.input.valueChanges.subscribe(() => {
      this.onValueChange();
    });
  }
}
