import {Component, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from '../../../services/layout.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-main',
  templateUrl: './user-main.component.html',
  styleUrls: ['./user-main.component.scss']
})
export class UserMainComponent implements OnInit, OnDestroy {
  isSideBarMinimize: boolean;
  isSideBarMouseOver: boolean;
  isSideBarCanvasOpen: boolean;
  sidebarMinimizeSubs: Subscription;
  sidebarMouseOverSubs: Subscription;
  sidebarCanvasSubs: Subscription;

  constructor(public layoutService: LayoutService) {
    this.sidebarMinimizeSubs = layoutService.isSideBarMinimize$.subscribe(t => {
      this.isSideBarMinimize = t;
    });
    this.sidebarMouseOverSubs = layoutService.isSideBarMouseOver$.subscribe(t => {
      this.isSideBarMouseOver = t;
    });
    this.sidebarCanvasSubs = layoutService.isSidebarCanvasOpen$.subscribe(t => {
      this.isSideBarCanvasOpen = t;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.sidebarMinimizeSubs.unsubscribe();
    this.sidebarMouseOverSubs.unsubscribe();
    this.sidebarCanvasSubs.unsubscribe();
  }
}
