<div class="modal-login">
  <div class="modal-body">
    <button
      type="button"
      class="close p-0"
      data-dismiss="modal"
      aria-label="Close"
      (click)="modal.dismiss('close')"
    >
      <i class="fa fa-times" aria-hidden="true" style="font-size: 20px"></i>
    </button>
    <div class="content">
      <div class="title-success">
        <h3>
          {{
            "page.home.modal.forgot_password.modal.send_mail.title_txt"
              | translate
          }}
        </h3>
      </div>
      <p>
        {{
          "page.home.modal.forgot_password.modal.send_mail.content_txt"
            | translate
        }}
      </p>
    </div>
  </div>
</div>
