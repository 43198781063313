export interface FileModel {
  id: number;
  convert_status?: {
    guid: string;
    file_type: string;
    status: string;
    error_code: string;
    error_message: string;
    action: string
  };
  file_name: string;
  file_path: string;
  user_id: string;
  guid: string;
  file_datetime: string;
  file_thumbnail: string;
  created_at: string;
  updated_at: string;
  file_type: string;
  thumbnail: string;
  isUrl?: boolean;
}


export enum TYPE_AND_SIZE {
  'image/jpg' = 5,
  'image/jpeg' = 5,
  'image/png' = 5,
  'image/gif' = 5,
  'text/csv' = 5,
  'video/mp4' = 1024,
  'audio/mpeg' = 30,
  'application/pdf' = 50,
  '3dmodal/glb' = 50,
  'application/vnd.ms-excel' = 5
}

export enum FORMAT_FILE_SELECT {
  JPG= 'jpg',
  PNG= 'png',
  GIF= 'gif',
  MP4= 'mp4',
  MP3= 'mp3',
  GLB= 'glb',
  PDF= 'pdf',
  CSV= 'csv'
}

export enum TYPE_AND_SHORT_TYPE {
  'image/jpg' = 'jpg',
  'image/jpeg' = 'jpeg',
  'image/png' = 'png',
  'image/gif' = 'gif',
  'text/csv' = 'csv',
  'video/mp4' = 'mp4',
  'audio/mpeg' = 'mp3',
  'application/pdf' = 'pdf',
  '3dmodal/glb' = 'glb',
  'application/vnd.ms-excel' = 'csv'
}

export enum IS_NEW {
  OLD = 0,
  NEW = 1
}
