import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static _URL = `${environment.API_SERVER_URL}/api`;

  /** url api call to https://webeditor.styly.cc/ */
  private static _URL_STYLY_CC = `${environment.DOMAIN_STYLY_CC}/api/v1/_session`;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private loginState: boolean;
  constructor(private http: HttpClient, private translate: TranslateService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<User>(AuthService._URL + '/manager/login', { email, password })
      .pipe(
        map((user) => {
          if (user) {
            user.email = email;
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            this.loginState = true;
          }
          return user;
        })
      );
  }

  async manualRefreshToken() {
    await this.http
      .post(AuthService._URL + '/manager/refresh', {})
      .toPromise()
      .then((newToken: any) => {
        const currentUser: User = JSON.parse(
          localStorage.getItem('currentUser')
        );
        const newAccessToken = newToken.access_token;
        currentUser.access_token = newAccessToken;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        return newAccessToken;
      });
  }

  isTokenExpired() {
    const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    const token = currentUser.access_token;
    if (token) {
      const tokenExpAt = this.parseJwt(token).exp * 1000;
      if (Date.now() > tokenExpAt) {
        return true;
      }
    }
    return false;
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  refreshToken(token): Observable<any> {
    return this.http.post(AuthService._URL + '/manager/refresh', {});
  }

  loginDiffWeb(email: string, password: string) {
    return this.http
      .post<any>(
        AuthService._URL_STYLY_CC,
        { email, password },
        { withCredentials: true }
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  logout() {
    this.translate.use('ja');
    // remove admin from local storage to log admin out
    const user = JSON.parse(localStorage.getItem('currentUser'));
    localStorage.setItem('2faAuthorized', '0');
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${access_token}`,
    });
    return this.http
      .get(AuthService._URL + '/manager/logout', { headers: httpHeaders })
      .pipe((resp) => {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.loginState = false;
        return resp;
      });
  }

  hasPermission(pId: number) {
    const user_type = JSON.parse(localStorage.getItem('currentUser')).type;
    return user_type >= pId;
  }

  forgotPassword(email) {
    const httpHeaders = new HttpHeaders({
      Accept: 'application/json',
    });
    return this.http.post(
      AuthService._URL + '/manager/forgot-password',
      { email },
      { headers: httpHeaders }
    );
  }

  resetPassword(password, token) {
    const httpHeaders = new HttpHeaders({
      Accept: 'application/json',
    });
    return this.http.post(
      AuthService._URL + '/manager/reset-password',
      { password, token },
      { headers: httpHeaders }
    );
  }

  changePassword(password, new_password, new_password_confirmation) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${access_token}`,
    });
    return this.http.put(
      AuthService._URL + '/manager/account/password',
      { password, new_password, new_password_confirmation },
      { headers: httpHeaders }
    );
  }

  loginCodeConfirm(email: string, password: string, code: string) {
    return this.http
      .post<User>(AuthService._URL + '/manager/login/confirm-code', {
        email,
        password,
        code,
      })
      .pipe(
        map((user) => {
          if (user) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  sendCodeAuth2FA() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${access_token}`,
    });
    return this.http.put(
      AuthService._URL + '/manager/account/2fa',
      {},
      { headers: httpHeaders }
    );
  }

  change2FAStutus(code) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${access_token}`,
    });
    return this.http.put(
      AuthService._URL + '/manager/account/2fa-confirm',
      { code },
      { headers: httpHeaders }
    );
  }

  changeLanguage(language = 'ja') {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${access_token}`,
    });
    user.display_lang = language;
    localStorage.setItem('currentUser', JSON.stringify(user));
    return this.http.put(
      AuthService._URL + '/manager/account/language',
      {
        language,
      },
      { headers: httpHeaders }
    );
  }

  getCurrentUser() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${access_token}`,
    });

    return this.http
      .get<User>(AuthService._URL + '/manager/account', {
        headers: httpHeaders,
      })
      .pipe(
        map((value: any) => {
          if (value) {
            localStorage.removeItem('currentUser');
          }
          localStorage.setItem(
            'currentUser',
            JSON.stringify({
              ...user,
              email: value.email,
              name: value.name,
              type: value.type,
              two_factor_authen_flg: value.two_factor_authen_flg,
              display_lang: value.display_lang,
            })
          );

          return value;
        })
      );
  }
}
