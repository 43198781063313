import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PageResponse} from '../../models/page-response';
import {User} from '../../models/user';
import {SearchRequest} from '../../models/search-request';
import {AbstractService} from '../abstract.service';
import {AdminAccountSearchForm} from '../../pages/admin/account-management/account-management.component';

@Injectable({
  providedIn: 'root'
})
export class AdminAccountService extends AbstractService {

  _URL = `${this.API_URL}`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  search(searchForm: SearchRequest<AdminAccountSearchForm>) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    const params = AbstractService.requestToParams(searchForm);
    return this.http.get<PageResponse<User>>(`${this._URL}/users`, {params, headers: httpHeaders});
  }

  user(id) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.get<User>(`${this._URL}/users/${id}`, {headers: httpHeaders});
  }

  delete(user_id) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.delete(`${this._URL}/users/${user_id}`, {headers: httpHeaders});
  }

  updatedUser(user_id, name, email, type) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.put(`${this._URL}/users/${user_id}`,
      {name, email, type}, {headers: httpHeaders});
  }

  toggle2fa(user_id) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.put(`${this._URL}/users/${user_id}/2fa`,
      {}, {headers: httpHeaders});
  }

  resetUserPassword(user_id) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.put(`${this._URL}/users/${user_id}/reset-password`,
      {}, {headers: httpHeaders});
  }

  createUser(name, email, email_confirmation, password, type) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.post(`${this._URL}/users`,
      {name, email, email_confirmation, password, type}, {headers: httpHeaders});
  }

  searchUser() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.get<User[]>(`${this._URL}/users/search`, {headers: httpHeaders});
  }


  getListUserLicense(uId) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.get(`${this._URL}/users/${uId}/licenses`, {headers: httpHeaders});
  }

}
