import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { User, TWO_FACTOR_AUTHEN_FLG, TWO_FACTOR_AUTHEN_TXT, TYPE, TYPE_TXT } from '../../../models/user';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from '../../modal/modal-confirm/modal-confirm.component';
import { ModalChangePasswordComponent } from '../../../pages/modal-change-password/modal-change-password.component';
import {
  ModalConfirmCodeSetting2FAComponent
} from '../../../pages/modal-confirm-code-setting-2fa/modal-confirm-code-setting-2fa.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-profile-dropdown',
  templateUrl: './user-profile-dropdown.component.html',
  styleUrls: ['./user-profile-dropdown.component.scss']
})
export class UserProfileDropdownComponent implements OnInit {
  @HostBinding('class')
    // tslint:disable-next-line:max-line-length
  classes = ['m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium',
    'm-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light'].join(' ');

  @HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

  @Input() avatar = 'assets/images/avatar.png';

  @ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;

  user: User;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toast: ToastrService,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.authService.currentUser.subscribe((value: any) => {
      if (value) {
        value = this.format2faAndtype(value);
        this.user = value;
      }
    });
  }

  format2faAndtype(value) {
    value.two_factor_authen_txt = value &&
    (value.two_factor_authen_flg === TWO_FACTOR_AUTHEN_FLG.ON) ?
      TWO_FACTOR_AUTHEN_TXT.ON : TWO_FACTOR_AUTHEN_TXT.OFF || '';

    switch (value.type) {
      case TYPE.USER_MANAGER: value.user_type = TYPE_TXT.USER; break;
      case TYPE.ADMIN: value.user_type = TYPE_TXT.ADMIN; break;
      default: value.user_type = '';
    }

    return value;
  }

  public logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigateByUrl('/');
    });
  }

  changePassword() {
    const modalRef = this.modalService.open(ModalChangePasswordComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    modalRef.result.then(() => {
    }, err => {
      console.log(err);
    });
  }

  confirmSetting2FA() {
    const modal = this.modalService.open(ModalConfirmComponent);
    modal.componentInstance.message = +this.user.two_factor_authen_flg === 1
      ? this.translate.instant('page.dashboard.modal.confirm_setting_2fa.sure_remove')
      : this.translate.instant('page.dashboard.modal.confirm_setting_2fa.sure_regist');
    modal.componentInstance.title = +this.user.two_factor_authen_flg === 1
      ? this.translate.instant('page.dashboard.modal.confirm_setting_2fa.heading_remove')
      : this.translate.instant('page.dashboard.modal.confirm_setting_2fa.heading_regist');
    modal.result
      .then(() => {
        this.sendMailCodeAuth2FA();
      })
      .catch((err) => console.log(err));
  }

  sendMailCodeAuth2FA() {
    return this.authService.sendCodeAuth2FA()
      .subscribe(() => {
        this.toast.success(this.translate.instant('page.dashboard.modal.confirm_setting_2fa.send_mail_success'));
        this.setting2FA();
    }, (err) => {
      this.toast.error(this.translate.instant('page.dashboard.modal.confirm_setting_2fa.send_mail_failed'));
    });
  }

  setting2FA() {
    const modalRef = this.modalService.open(ModalConfirmCodeSetting2FAComponent, { backdrop: 'static', keyboard: false, size: 'lg' });
    modalRef.result.then(() => {
      this.user = JSON.parse(localStorage.getItem('currentUser'));
    }, err => {
      console.log(err);
    });
  }

  refresh() {
    this.authService.getCurrentUser().subscribe((value: any) => {
      value = this.format2faAndtype(value);
      this.user = value;
    });
  }
}
