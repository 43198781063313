import { Injectable } from '@angular/core';
import { AbstractService } from '../abstract.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Csv } from '../../models/csv';
import { SearchRequest } from '../../models/search-request';
import { PageResponse } from '../../models/page-response';
import { AdminCsvSearchForm } from 'src/app/pages/admin/csv-management/csv-management.component';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminCsvService extends AbstractService {

  _URL = `${environment.API_SERVER_URL}/api`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  search(searchForm: SearchRequest<AdminCsvSearchForm>, sort?: Array<String>) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    const params = AbstractService.requestToParams(searchForm);

    return this.http.get<PageResponse<Csv>>(`${this._URL}/asset-properties`, { params, headers: httpHeaders });
  }

  uploadCsv(data: FormData) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.post<any>(`${this._URL}/asset-properties`,
      data, { headers: httpHeaders });
  }

  updateCsv(data: FormData) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.post<any>(`${this._URL}/asset-properties/update`,
      data, { headers: httpHeaders });
  }

  delete(csv_id) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.delete(`${this._URL}/asset-properties/${csv_id}`, {headers: httpHeaders});
  }
}
