import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractFormComponent} from '../../components/abstract-form.component';
import {ERROR_CODE} from '../../models/error';
import {AuthService} from '../../services/auth.service';
import { PasswordStrengthValidator } from 'src/app/validators/password.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends AbstractFormComponent<any> implements OnInit {
  errorCode: ERROR_CODE | null = null;
  isTextFieldType: boolean;
  token;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastrService,
    private authService: AuthService
  ) {
    super();
    this.token = this.route.snapshot.queryParamMap.get('token');
  }

  ngOnInit() {
    this.form = new FormGroup({
      'password': new FormControl('', [Validators.required, Validators.maxLength(32), Validators.minLength(8),
        PasswordStrengthValidator]),
    });
  }

  hidePass() {
    this.isTextFieldType = !this.isTextFieldType;
  }

  _onSubmit(formData) {
    this.isLoading = true;
    return this.authService.resetPassword(formData.password, this.token)
      .subscribe(() => {
        this.isLoading = false;
        this.toast.success('パスワード設定完了しました。');
        this.router.navigateByUrl('/', { state: { isReset: true } } );
      }, (err) => {
        this.isLoading = false;
        this.errorCode = err.error[0].code;
        if (this.errorCode === ERROR_CODE.token_invalid) {
          this.toast.error('トークンが異常です。URLが正しいか確認してください。');
        }

        if (this.errorCode === ERROR_CODE.password_no_change) {
          this.toast.error('パスワードを更新できません。');
        }
      });

  }

  onSuccess(resp) {
  }

}
