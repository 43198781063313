<div class="modal-header">
  <h2 class="modal-title" id="modal-title" style="font-weight: 600">
    {{ "page.scene.modal.create_header" | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="goBack()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form-label">
  <form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form">
    <div class="form-group row form-item-row">
      <div class="col-12">
        <label
          for="username"
          class="col-form-label isRequired"
          data-required="必須"
          appRequiredLabel
          >{{ "page.scene.modal.user_name_lbl" | translate }}</label
        >
        <div class="input-group">
          <input
            class="form-control"
            id="username"
            formControlName="username"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar"
              (click)="openUserSearchModal()"
              type="button"
            >
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div
          *ngIf="form.controls.username.touched"
          class="text text-danger mt-1 text-left"
        >
          <span
            *ngIf="form.controls.username.errors?.required"
            class="text text-danger mt-1 label-error"
          >
            {{ "validation.field_required" | translate }}
          </span>
        </div>
      </div>

      <div class="col-12">
        <label
          for="scene_id"
          class="col-form-label isRequired"
          data-required="必須"
          appRequiredLabel
          >{{ "page.scene.modal.scene_guid_lbl" | translate }}</label
        >
        <div class="input-group">
          <input
            class="form-control"
            id="scene_id"
            formControlName="id"
            (change)="autoTrimInput($event, 'id')"
            (keyup)="sceneExisted = false"
          />
        </div>
        <div
          *ngIf="form.controls.id.touched"
          class="text text-danger mt-1 text-left"
        >
          <span
            *ngIf="form.controls.id.errors?.required"
            class="text text-danger mt-1 label-error"
          >
            {{ "validation.field_required" | translate }}
          </span>
          <span
            *ngIf="form.controls.id.errors?.maxlength"
            class="text text-danger mt-1 label-error"
          >
            {{
              "validation.field_max_length" | translate | replace: "{max}":"50"
            }}
          </span>
          <span
            *ngIf="sceneExisted"
            class="text text-danger mt-1 label-error"
          >
            {{
              "page.scene.modal.err_msg.scene_exist" | translate
            }}
          </span>
        </div>
      </div>

      <div class="col-12">
        <label
          for="scene_name"
          class="col-form-label isRequired"
          data-required="必須"
          appRequiredLabel
          >{{ "page.scene.modal.scene_name" | translate }}</label
        >
        <div class="input-group">
          <input
            class="form-control"
            id="scene_name"
            formControlName="name"
            (change)="autoTrimInput($event, 'name')"
          />
        </div>

        <div
          *ngIf="form.controls.name.touched"
          class="text text-danger mt-1 text-left"
        >
          <span
            *ngIf="form.controls.name.errors?.required"
            class="text text-danger mt-1 label-error"
          >
            {{ "validation.field_required" | translate }}
          </span>
          <span
            *ngIf="form.controls.name.errors?.maxlength"
            class="text text-danger mt-1 label-error"
          >
            {{
              "validation.field_max_length" | translate | replace: "{max}":"255"
            }}
          </span>
        </div>
      </div>
    </div>

    <div class="text-center form-item-row padding-button-top">
      <button
        type="button"
        class="btn btn-md btn-secondary with-button"
        (click)="goBack()"
      >
        {{ "page.scene.modal.back_btn" | translate }}</button
      >&nbsp;
      <button
        type="submit"
        class="btn btn-md btn-primary with-button"
        appLoadingButton
        data-i18n="page.scene.modal.save_btn"
        [isLoading]="isLoading"
        loadingText="{{ 'common.processing_text' | translate }}"
        [disabled]="form.invalid"
      ></button
      >&nbsp;
    </div>
    &nbsp;
  </form>
</div>
