import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  const value: string = control.value || '';
  if (!value) {
    return null;
  }

  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*-_+=]{8,32}$/;
  const regex = new RegExp(passwordPattern);
  if (!regex.test(value)) {
    return {
      passwordStrength: 'Password must contain at least two of the following: numbers, uppercase, lowercase letters characters.'
    };

  }
};

export const NotContainUserName = function (controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const matchingControl = formGroup.controls[matchingControlName];
    const password = matchingControl.value.toLowerCase();
    let name = controlName.toLowerCase();

    if (matchingControl.errors && !matchingControl.errors.notContainUserName) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    if (controlName === 'name' && typeof formGroup.controls[controlName] !== 'undefined') {
      name = formGroup.controls[controlName].value.toLowerCase();
    }

    if (password.includes(name)) {
      matchingControl.setErrors({ notContainUserName: true });
    } else {
      matchingControl.setErrors(null);
    }

  };
};
