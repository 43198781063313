export enum ERROR_CODE {
  invalid_email_password = 'invalid_email_password',
  email_invalid = 'email_invalid',
  email_unique = 'email_unique',
  code_expire = 'code_expire',
  email_username_unique = 'email_username_unique',
  token_invalid = 'token_invalid',
  estab_date_date_invalid = 'estab_date_date_invalid',
  contract_date_date_invalid = 'contract_date_date_invalid',
  close_date_date_invalid = 'close_date_date_invalid',
  payment_date_date_invalid = 'payment_date_date_invalid',
  invoice_date_date_invalid = 'invoice_date_date_invalid',
  payment_due_date_date_invalid = 'payment_due_date_date_invalid',
  regist_dt_date_invalid = 'regist_dt_date_invalid',
  update_dt_date_invalid = 'update_dt_date_invalid',
  company_estab_date_date_invalid = 'company_estab_date_date_invalid',
  quantity_min_value = 'quantity_min_value',
  password_apha_num = 'password_apha_num',
  code_unique= 'code_unique',
  invalid_email_password_code = 'invalid_email_password_code',
  user_id_requrired = 'user_id_requrired',
  password_no_change = 'password_no_change'
}
