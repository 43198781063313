import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminCsvService } from 'src/app/services/admin/admin-csv.service';
import { AdminAccountService } from 'src/app/services/admin/admin-account.service';
import { AdminLicenseService } from 'src/app/services/admin/admin-license.service';
import { SettingService } from 'src/app/services/setting.service';
import { ERROR_CODE } from 'src/app/models/error';
import { License } from 'src/app/models/license';
import { AbstractFormComponent } from 'src/app/components/abstract-form.component';
import { environment } from 'src/environments/environment';
import { ModalConfirmComponent } from 'src/app/components/modal/modal-confirm/modal-confirm.component';
import { AbstractSearchPage } from '../../../abstract-search-page';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-create-csv',
  templateUrl: './modal-create-csv.component.html',
  styleUrls: ['./modal-create-csv.component.scss'],
})
export class ModalCreateCsvComponent
  extends AbstractFormComponent<any>
  implements OnInit {
  imageUrl: any = null;
  removeUpload = false;
  isUpload = false;
  companyFile: File;
  isUploading = false;

  @ViewChild('buttonCancel') buttonCancel;
  isChange = false;
  constructor(
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private adminCsvService: AdminCsvService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({});
  }

  _onSubmit() {}

  onSuccess() {}

  upFile($event) {
    const reader = new FileReader();
    const file = $event.target.files[0];
    if ($event.target.files && $event.target.files[0]) {
      this.companyFile = $event.target.files && $event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = file.name;
        this.removeUpload = true;
        this.isUpload = true;
      };
      this.cd.markForCheck();
    }
  }

  upload() {
    this.isUploading = true;
    const data = new FormData();
    data.append('csv', this.companyFile);
    this.adminCsvService.uploadCsv(data).subscribe(
      (resp) => {
        this.toast.success(
          this.translate.instant('common.toast_msg.success.upload')
        );
        this.isUpload = false;
        this.modal.close('close');
        window.scrollTo(0, 0);
        setTimeout(() => {}, 5000);
        this.isUploading = false;
      },
      (e) => {
        this.toast.error(
          this.translate.instant('common.toast_msg.failed.upload')
        );
        this.isUploading = false;
      }
    );
  }

  cancelUpload() {
    this.modal.close('close');
  }

  goBack() {
    if (this.isChange) {
      const modal = this.modalService.open(ModalConfirmComponent);
      modal.componentInstance.message = 'Are you sure come back license list?';
      modal.componentInstance.title = 'Data lost alert';
      modal.result.then(() => {
        this.modal.close('close');
      });
    } else {
      this.modal.close('close');
    }
  }
}
