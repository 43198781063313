<div *ngIf="form">
  <div class="modal-header">
    <h2 class="modal-title" id="modal-title" style="font-weight: 600;">STYLY編集</h2>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body form-label">
    <form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form">
      <div class="avatar-upload">
        <div class="avatar-edit">
          <input type='file' id="company_id_file" accept=".csv" #company_id_file (change)="upFile($event)" />
        </div>
        <div class="avatar-preview">
          <div id="imagePreview">
            {{imageUrl}}
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-outline-info btn-upload" *ngIf="!isUpload"
        (click)="company_id_file.click()">Choose CSV file</button>
      <div class="d-flex flex-row" style="justify-content: center;">
        <button type="button" class="btn btn-success" *ngIf="isUpload" (click)="updateCsv()" appLoadingButton
          [isLoading]="isUploading" loadingText="Processing ...">UpLoad
          CSV</button>&nbsp;
        <button type="button" class="btn btn-danger" *ngIf="isUpload" (click)="cancelUpload()">Cancel CSV</button>
      </div>
    </form>
  </div>
</div>