import { Component, OnInit } from '@angular/core';
import { AbstractSearchPage } from '../../abstract-search-page';
import { License } from '../../../models/license';
import { SearchRequest } from '../../../models/search-request';
import { Observable } from 'rxjs';
import { PageResponse } from '../../../models/page-response';
import { AdminLicenseService } from '../../../services/admin/admin-license.service';
import * as _ from 'lodash';
import { SettingService } from '../../../services/setting.service';
import moment from 'moment';

export interface AdminLicenseSearchForm {
  code: string;
  regist_from: string;
  regist_to: string;
  duration: number;
  regist_sts: number;
  username: string;
  email: string;
  license_term?: string;
}

@Component({
  selector: 'app-my-license',
  templateUrl: './my-license.component.html',
  styleUrls: ['./my-license.component.scss']
})
export class MyLicenseComponent extends AbstractSearchPage<AdminLicenseSearchForm, License> implements OnInit {

  durations: any[];
  constructor(private adminLicenseService: AdminLicenseService,
    private settingService: SettingService,
  ) {
    super();
  }

  ngOnInit() {
    this.search();
    this.settingService.getDurations().subscribe(t => {
      this.durations = t.license_durations;
    });
  }

  _initSearchForm(): SearchRequest<AdminLicenseSearchForm> {
    return <SearchRequest<AdminLicenseSearchForm>>{
      size: 20,
      page: 1,
      search: {
        code: '',
        regist_from: '',
        regist_to: '',
        duration: null,
        regist_sts: null,
        username: '',
        email: ''
      },
      sort: []
    };
  }

  _search(searchForm: SearchRequest<AdminLicenseSearchForm>): Observable<PageResponse<License>> {
    const _searchForm = _.cloneDeep(searchForm);
    if (searchForm.search.regist_from !== '' && searchForm.search.regist_from !== null) {
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      _searchForm.search.regist_from = `${searchForm.search.regist_from.year}-${searchForm.search.regist_from.month}-${searchForm.search.regist_from.day}`;
    }
    if (searchForm.search.regist_to !== '' && searchForm.search.regist_to !== null) {
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      _searchForm.search.regist_to = `${searchForm.search.regist_to.year}-${searchForm.search.regist_to.month}-${searchForm.search.regist_to.day}`;
    }

    return this.adminLicenseService.search(_searchForm);
  }


  getLicenseTerm(license) {
    const start_date = license.update_dt
    ? moment(license.update_dt)
    : moment(license.regist_dt);
  const license_term: any = moment(start_date)
    .add(license.duration, 'M')
    .add(-1, 'days');

  return license_term.format('YYYY/MM/DD').toString();
  }
}
