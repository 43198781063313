import { Injectable } from '@angular/core';
import { AbstractService } from '../abstract.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { FileModel } from '../../models/file';
import { SearchRequest } from '../../models/search-request';
import { PageResponseFile } from '../../models/page-response';
import { AdminCsvSearchForm } from 'src/app/pages/admin/csv-management/csv-management.component';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminFileService extends AbstractService {

  _URL = `${environment.API_SERVER_URL}/api/v1`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  search(searchForm: SearchRequest<AdminCsvSearchForm>) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    const params = AbstractService.requestToParams(searchForm);
    return this.http.get<PageResponseFile<FileModel>>(`${this._URL}/files/list`, { params, headers: httpHeaders });
  }

  uploadFile(data: FormData) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    data.append('user_id', user.id);

    return this.http.post<any>(`${this._URL}/files/upload`,
      data, { headers: httpHeaders, reportProgress: true, observe: 'events' });
  }

  delete(guid, file_path) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const token_data = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${token_data}`
    });
    const user_id = user.id;

    return this.http.post(`${this._URL}/files/delete`, { guid, file_path, token_data, user_id }, { headers: httpHeaders });
  }

  getLinkUpload(data: FormData, isUpdate: boolean = false) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const token_data = user.access_token;
    const httpHeaders = new HttpHeaders ({
      'Authorization': `Bearer ${token_data}`
    });
    if (isUpdate) { data.append('user_id', user.id); }

    return this.http.post(`${this._URL}/files/pre-signed`, data, { headers: httpHeaders });
  }

  uploadFileToAws(presignedLink, type, file) {
    const headers = new HttpHeaders({ 'Content-Type': type });
    const req = new HttpRequest(
      'PUT',
      presignedLink,
      file,
      { headers, reportProgress: true }
    );

    return this.http.request(req);
  }
}
