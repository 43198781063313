<!-- <button class="m-aside-left-close  m-aside-left-close--skin-dark " id="m_aside_left_close_btn"><i
  class="la la-close" *ngIf="isSideBarCanvasOpen"
  (click)="layoutService.showSidebarCanvas(false)"></i></button> -->
<div
  id="m_aside_left"
  class="m-grid__item m-aside-left m-aside-left--skin-dark"
  [ngClass]="{ 'm-aside-left--on': isSideBarCanvasOpen }"
>
  <!-- BEGIN: Aside Menu -->
  <div
    id="m_ver_menu"
    class="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark"
    style="position: relative; max-height: 100%"
    [perfectScrollbar]="config"
  >
    <button
      class="close-sidebar-mobile btn"
      *ngIf="isSideBarCanvasOpen"
      (click)="layoutService.showSidebarCanvas(false)"
    >
      <span></span>
    </button>
    <ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
      <ng-container [ngTemplateOutlet]="mMenuListing"></ng-container>
    </ul>
  </div>

  <!-- END: Aside Menu -->
</div>
<div
  class="m-aside-left-overlay"
  *ngIf="isSideBarCanvasOpen"
  (click)="layoutService.showSidebarCanvas(false)"
></div>
<ng-template #mMenuListing>
  <ng-container *ngFor="let child of menu">
    <div *ngIf="child.section">
      <h4 class="m-menu__section-text">
        <!-- menu item section title -->
        {{ child.section }}
      </h4>
      <i class="m-menu__section-icon flaticon-more-v3"></i>
    </div>
    <ng-container
      *ngIf="child.title"
      [ngTemplateOutlet]="mMenuItem"
      [ngTemplateOutletContext]="{ item: child }"
    ></ng-container>
  </ng-container>
</ng-template>

<ng-template #mMenuItem let-item="item">
  <li
    class="m-menu__item m-menu__item--expanded"
    [ngClass]="{
      'm-menu__item--submenu': item.children,
      'm-menu__item--open': item.children && isMenuItemIsActive(item)
    }"
    aria-haspopup="true"
    routerLinkActive="m-menu__item--active"
    [routerLinkActiveOptions]="{ exact: item.exact }"
  >
    <!-- if menu item hasn't submenu -->
    <a *ngIf="!item.children" [routerLink]="item.link" class="m-menu__link">
      <ng-container
        [ngTemplateOutlet]="mMenuItemText"
        [ngTemplateOutletContext]="{ item: item }"
      ></ng-container>
    </a>
    <!-- if menu item has sub menu child  -->
    <a
      href="javascript:void(0)"
      *ngIf="item.children"
      class="m-menu__link m-menu__toggle"
    >
      <ng-container
        [ngTemplateOutlet]="mMenuItemText"
        [ngTemplateOutletContext]="{ item: item }"
      ></ng-container>
    </a>

    <!-- if menu item has submenu child then recursively call new menu item component -->
    <div *ngIf="item.children" class="m-menu__submenu">
      <span class="m-menu__arrow"></span>
      <ul class="m-menu__subnav">
        <ng-container *ngFor="let child of item.children">
          <ng-container
            [ngTemplateOutlet]="mMenuItem"
            [ngTemplateOutletContext]="{ item: child, icon: item.bullet }"
          ></ng-container>
        </ng-container>
      </ul>
    </div>
  </li>
</ng-template>

<ng-template #mMenuItemText let-item="item" let-icon="icon">
  <!-- if menu item has icon -->
  <i
    *ngIf="item.icon; else menuItemIcon"
    class="m-menu__link-icon"
    [ngClass]="item.icon"
  ></i>

  <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
    <!-- menu item title text -->
    <span class="m-menu__link-text text-nowrap">
      {{ item.title.toString() | translate }}
    </span>
  </ng-container>

  <ng-template #mMenuLinkBadge>
    <!-- menu item with badge -->
    <span class="m-menu__link-title">
      <span class="m-menu__link-wrap">
        <span class="m-menu__link-text" [translate]="item.translate">{{
          item.title
        }}</span>
        <span class="m-menu__link-badge">
          <span class="m-badge" [ngClass]="item.badge.type">{{
            item.badge.value
          }}</span>
        </span>
      </span>
    </span>
  </ng-template>

  <!-- if menu item has submenu child then put arrow icon -->
  <i *ngIf="item.children" class="m-menu__ver-arrow la la-angle-right"></i>

  <ng-template #menuItemIcon>
    <i
      *ngIf="icon === 'dot'"
      class="m-menu__link-bullet m-menu__link-bullet--dot"
    >
      <span></span>
    </i>
    <i
      *ngIf="icon === 'line'"
      class="m-menu__link-bullet m-menu__link-bullet--line"
    >
      <span></span>
    </i>
  </ng-template>
</ng-template>
