<div class="modal-login">
  <div class="modal-body">
    <button
      type="button"
      class="close p-0 mt-2"
      aria-label="Close button"
      aria-describedby="modal-title"
      style="font-size: 20px;"
      (click)="modal.dismiss(false)"
    >
    <i class="fa fa-times" aria-hidden="true" style="font-size: 20px"></i>
    </button>
    <div class="styly-login">
      <div class="brand-logo text-center">
        <img src="./assets/images/sbiz_logo.png" alt="brand-logo" />
      </div>
      <div class="styly-panel panel-login text-center">
        <div class="tab-content">
          <!-- Login manager -->

          <div
            class="tab-pane fade active show"
            role="tabpanel"
            id="loginManager"
          >
            <form
              name="loginForm"
              class="loginForm"
              autocomplete="off"
              [formGroup]="loginManagerForm"
              (ngSubmit)="loginManagerForm.valid && onSubmit()"
            >
              <div class="form-group wrap-input">
                <input
                  type="email"
                  class="form-control email pl-3"
                  placeholder="{{
                    'page.home.modal.login.login_id_plh' | translate
                  }}"
                  id="email"
                  name="email"
                  autocomplete="off"
                  formControlName="email"
                />
                <span class="focus-input"></span>
              </div>
              <div
                *ngIf="loginManagerForm.controls.email.touched"
                class="text text-danger mt-1 text-left"
              >
                <span
                  *ngIf="loginManagerForm.controls.email.errors?.required"
                  class="text text-danger mt-1"
                >
                  {{ "page.home.modal.login.error_email_required" | translate }}
                </span>
                <span
                  *ngIf="loginManagerForm.controls.email.errors?.email"
                  class="text text-danger mt-1"
                >
                  {{ "page.home.modal.login.error_email_format" | translate }}
                </span>
              </div>
              <div class="form-group wrap-input">
                <div class="pwdMask">
                  <input
                    [type]="isTextFieldType ? 'text' : 'password'"
                    class="form-control password pl-3"
                    name="password"
                    placeholder="{{
                      'page.home.modal.login.password_plh' | translate
                    }}"
                    id="password"
                    autocomplete="off"
                    formControlName="password"
                  />
                  <span class="focus-input"></span>
                  <span
                    class="fa fa-eye-slash pwd-toggle"
                    *ngIf="!isTextFieldType"
                    (click)="hidePass()"
                  ></span>
                  <span
                    class="fa fa-eye pwd-toggle"
                    *ngIf="isTextFieldType"
                    (click)="hidePass()"
                  ></span>
                </div>
              </div>
              <div
                *ngIf="isShowLoginErr"
                class="text text-danger mt-1 text-left"
              >
                <span class="text text-danger mt-1">
                  {{ "page.home.modal.login.login_err_msg" | translate }}
                </span>
              </div>
              <div
                *ngIf="loginManagerForm.controls.password.touched"
                class="text text-danger mt-1 text-left"
              >
                <span
                  *ngIf="loginManagerForm.controls.password.errors?.required"
                  class="text text-danger mt-1"
                >
                  {{
                    "page.home.modal.login.error_password_required" | translate
                  }}
                </span>
                <!-- <span
                  *ngIf="loginManagerForm.controls.password.errors?.maxlength || loginManagerForm.controls.password.errors?.minlength"
                  class="text text-danger mt-1">
                  {{ 'page.home.modal.login.error_password_length' | translate }}
                </span>
                <span *ngIf="loginManagerForm.controls.password.errors?.pattern" class="text text-danger mt-1">
                  {{ 'page.home.modal.login.error_format_password_txt' | translate }}
                </span> -->
              </div>
              <div class="form-group mt-5">
                <button
                  class="btn btn-md btn-primary btn-block"
                  type="submit"
                  appLoadingButton
                  data-i18n="page.home.modal.login.login_btn"
                  [isLoading]="isLoading"
                  loadingText="{{ 'common.processing_text' | translate }}"
                  [disabled]="!loginManagerForm.valid"
                ></button>
                <a
                  class="btn btn-md btn-forgot-password btn-block"
                  (click)="forgotPassword()"
                  >{{
                    "page.home.modal.login.forgot_password_link" | translate
                  }}</a
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
