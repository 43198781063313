<div class="modal-header">
  <h2 class="modal-title" id="modal-title" style="font-weight: 600">
    {{ "page.analysis.statistical_information" | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('close')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form-label d-flex flex-column">
  <form
    class="form-inline row"
    (ngSubmit)="search()"
    [formGroup]="downloadRequestForm"
  >
    <div class="col-12 mb-2">
      <div class="row">
        <label class="col-md-2 d-flex align-items-center justify-content-start">
          <input
            #all_btn
            style="margin-right: 10px"
            type="radio"
            name="response"
            value="all"
            checked
            (change)="timeChange($event)"
          />
          {{ "page.analysis.modal.statistic.all" | translate }}
        </label>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-md-2 col-sm-12">
          <label class="d-flex align-items-center justify-content-start p-0">
            <input
              #time_btn
              style="margin-right: 10px"
              type="radio"
              name="response"
              value="timeFromTo"
              (change)="timeChange($event)"
            />
            <span>{{ "page.analysis.modal.statistic.time" | translate }}</span>
          </label>
        </div>
        <ng-container *ngIf="isShowTimeFromTo">
          <div class="col-md-5 col-sm-12">
            <div style="line-height: 40px">
              <label style="font-size: 14px; justify-content: start !important">
                {{ "page.analysis.modal.statistic.start_date_lbl" | translate }}
              </label>
              <div class="m-input-icon mb-3" style="width: 100%">
                <div class="input-group">
                  <input
                    class="form-control"
                    placeholder="{{
                      'common.placeholder.date_plh' | translate
                    }}"
                    id="regist_from"
                    name="dp"
                    ngbDatepicker
                    #regist_from="ngbDatepicker"
                    readonly
                    formControlName="from"
                    [maxDate]="minFromDatePickerAtrribute(false)"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="regist_from.toggle()"
                      type="button"
                    >
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-5 col-sm-12">
            <div style="line-height: 40px">
              <label style="font-size: 14px; justify-content: start !important">
                {{ "page.analysis.modal.statistic.end_date_lbl" | translate }}
              </label>
              <div class="m-input-icon mb-3" style="width: 100%">
                <div class="input-group">
                  <input
                    class="form-control"
                    placeholder="{{
                      'common.placeholder.date_plh' | translate
                    }}"
                    id="regist_to"
                    name="dp"
                    ngbDatepicker
                    #regist_to="ngbDatepicker"
                    readonly
                    formControlName="to"
                    [minDate]="toDatePickerAtrribute(true)"
                    [maxDate]="toDatePickerAtrribute(false)"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="regist_to.toggle()"
                      type="button"
                    >
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div style="margin: 10px auto">
      <button
        type="submit"
        class="btn btn-tworld-important btn-sm mb-3"
        appLoadingButton
        data-i18n="common.search_btn"
        loadingText="{{ 'common.search_btn' | translate }}"
        [isLoading]="_loading$ | async"
        style="padding: 7px 40px; font-size: 16px"
      ></button>
    </div>
  </form>
  <br />
  <div class="table-responsive">
    <table class="table table-sm table-hover table-tworld align-middle">
      <thead>
        <tr>
          <th class="text-center"></th>
          <th class="text-center">
            {{ "page.analysis.modal.statistic.pv" | translate }}
          </th>
          <th class="text-center">
            {{ "page.analysis.modal.statistic.uu" | translate }}
          </th>
          <th class="text-center">
            {{ "page.analysis.modal.statistic.lv" | translate }}
          </th>
          <th class="text-center">
            {{
              "page.analysis.modal.statistic.average_retention_time" | translate
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="analysisTotal">
          <td class="text-center" style="min-width: 140px">
            {{ "page.analysis.modal.statistic.total" | translate }}
          </td>
          <td class="text-center">{{ analysisTotal.pv }}</td>
          <td class="text-center">{{ analysisTotal.uu }}</td>
          <td class="text-center">{{ analysisTotal.lv }}</td>
          <td class="text-center">{{ analysisTotal.avg_time }}</td>
        </tr>
        <tr *ngFor="let statistic of pageResponse.rows; let i = index">
          <td class="text-center">{{ statistic.analysis_date }}</td>
          <td class="text-center">{{ statistic.pv }}</td>
          <td class="text-center">{{ statistic.uu }}</td>
          <td class="text-center">{{ statistic.lv }}</td>
          <td class="text-center">{{ statistic.avg_time }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row" *ngIf="analysisTotal && pageResponse.count">
    <div class="col-12 d-flex justify-content-center justify-content-md-end">
      <ngb-pagination
        [collectionSize]="pageResponse.count"
        [(page)]="page"
        [rotate]="true"
        [maxSize]="3"
        [pageSize]="searchForm.size"
        [boundaryLinks]="true"
      ></ngb-pagination>
    </div>
  </div>
  <div class="text-center form-item-row padding-button-top mt-auto">
    <button
    (click)="download()"
    class="btn btn-success mx-2"
    appLoadingButton
    data-i18n="page.log.download_btn"
    loadingText="{{ 'common.loading_text' | translate }}"
    [isLoading]="_downloading$ | async"
    [disabled]="_downloading$ | async"
  ></button>
    <button
      type="button"
      class="btn btn-md btn-secondary"
      (click)="goBack()"
    >
      {{ "page.license.modal.edit.cancel_btn" | translate }}</button
    >&nbsp;
  </div>
  &nbsp;
</div>
