import { Injectable } from '@angular/core';
import { AbstractService } from '../abstract.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SearchRequest } from '../../models/search-request';
import { PageResponse } from '../../models/page-response';
import { Information } from '../../models/information';
import { AdminInformationSearchForm } from '../../pages/admin/information-management/information-management.component';

@Injectable({
  providedIn: 'root'
})
export class AdminInformationService extends AbstractService {

  _URL = `${this.API_URL}`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  search(searchForm: SearchRequest<AdminInformationSearchForm>) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    const params = AbstractService.requestToParams(searchForm);
    return this.http.get<PageResponse<Information>>(`${this._URL}/informations`, { params, headers: httpHeaders });
  }


  createInformation(title, content, publish_flg) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.post(`${this._URL}/informations`,
      { title, content, publish_flg }, { headers: httpHeaders });
  }


  getByIdInformation(id) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.get<Information>(`${this._URL}/informations/${id}`, { headers: httpHeaders });
  }

  updatedInformation(id, title, content, publish_flg) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.put(`${this._URL}/informations/${id}`,
      { title, content, publish_flg }, { headers: httpHeaders });
  }

  deleteInformation(iId) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.delete(`${this._URL}/informations/${iId}`, { headers: httpHeaders });
  }
}
