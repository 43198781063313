<div class="login-wrapper">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-9 col-lg-10 col-md-12 col-sm-12">
        <div class="text-center brand-logo">
          <a><img src="/assets/images/sbiz_logo.png" alt="" /></a>
        </div>
        <div class="styly-register">
          <div class="styly-panel">
            <div class="form-register">
              <form
                action=""
                [formGroup]="form"
                (ngSubmit)="form.valid && onSubmit()"
                autocomplete="off"
              >
                <div class="form-group row">
                  <div class="col-md-3 col-lg-3 col-sm-3 title-password">
                    <label
                      class="isRequired"
                      data-required="必須"
                      appRequiredLabel
                      >{{
                        "page.reset_password.password_lbl" | translate
                      }}</label
                    >
                  </div>
                  <div class="col-md-8 col-lg-8 col-sm-4">
                    <div class="pwdMask">
                      <input
                        [type]="isTextFieldType ? 'text' : 'password'"
                        class="form-control password"
                        name="password"
                        id="password"
                        autocomplete="off"
                        formControlName="password"
                      />
                      <span class="focus-input"></span>
                      <span
                        class="fa fa-eye-slash pwd-toggle hiddenPass"
                        *ngIf="!isTextFieldType"
                        (click)="hidePass()"
                      ></span>
                      <span
                        class="fa fa-eye pwd-toggle hiddenPass"
                        *ngIf="isTextFieldType"
                        (click)="hidePass()"
                      ></span>
                    </div>
                  </div>
                </div>

                <div class="text-center button-group mt-5">
                  <button
                    type="submit"
                    class="btn btn-md btn-primary"
                    appLoadingButton
                    data-i18n="page.reset_password.confirm_btn"
                    [isLoading]="isLoading"
                    loadingText="{{ 'common.processing_text' | translate }}"
                    [disabled]="!form.valid"
                  ></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./row -->
  </div>
  <!-- ./container -->
</div>
