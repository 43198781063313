import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractFormComponent } from '../../../../components/abstract-form.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminAccountService } from '../../../../services/admin/admin-account.service';
import { AdminLicenseService } from '../../../../services/admin/admin-license.service';
import { License } from '../../../../models/license';
import { SettingService } from '../../../../services/setting.service';
import { ModalConfirmComponent } from '../../../../components/modal/modal-confirm/modal-confirm.component';
import { ERROR_CODE } from '../../../../models/error';
import { environment } from '../../../../../environments/environment';
import { AdminCsvService } from 'src/app/services/admin/admin-csv.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-modal-edit-csv',
  templateUrl: './my-modal-edit-csv.component.html',
  styleUrls: ['./my-modal-edit-csv.component.scss']
})
export class MyModalEditCsvComponent extends AbstractFormComponent<any> implements OnInit {
  imageUrl: any = null;
  editFile = true;
  removeUpload = false;
  isUpload = false;
  companyFile: File;
  isUploading = false;

  @ViewChild('buttonCancel') buttonCancel;
  @Input() lId;
  license: License;
  durations: any[];
  isChange = false;
  errorCode: ERROR_CODE | null = null;
  constructor(
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private adminAccountService: AdminAccountService,
    private adminLicenseService: AdminLicenseService,
    private settingService: SettingService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private adminCsvService: AdminCsvService,
    private translate: TranslateService

  ) {
    super();
  }


  ngOnInit() {
    this.form = new FormGroup({
    });

    // this.adminLicenseService.getByIdLicense(this.lId)
    //   .subscribe(t => {
    //     this.license = t;
    //     this.form = new FormGroup({
    //       'code': new FormControl({ value: this.license.code, disabled: true }, [Validators.maxLength(20)]),
    //       'username': new FormControl({ value: this.license.username !== null ? this.license.username : '', disabled: true },
    //         [Validators.required, Validators.maxLength(255)]),
    //       'email': new FormControl({ value: this.license.email !== null ? this.license.email : '', disabled: true },
    //         [Validators.email, Validators.maxLength(255)]),
    //       'regist_dt': new FormControl(null, [Validators.required]),
    //       'duration': new FormControl(this.license.duration, [Validators.required]),
    //       'update_dt': new FormControl(null),
    //       'regist_sts': new FormControl(this.license.regist_sts, [Validators.required]),
    //     });

    //     if (this.license.regist_dt !== null) {
    //       const date = this.license.regist_dt.split('-');
    //       const year = Number(date[0]);
    //       const month = Number(date[1]);
    //       const day = Number(date[2]);
    //       this.form.get('regist_dt').setValue({
    //         year: year,
    //         month: month,
    //         day: day
    //       });
    //     }
    //     if (this.license.update_dt !== null) {
    //       const date = this.license.update_dt.split('-');
    //       const year = Number(date[0]);
    //       const month = Number(date[1]);
    //       const day = Number(date[2]);
    //       this.form.get('update_dt').setValue({
    //         year: year,
    //         month: month,
    //         day: day
    //       });
    //     }

    //     this.form.valueChanges.subscribe(value => {
    //       this.isChange = true;
    //     });
    //   });
    // this.settingService.getDurations().subscribe(t => {
    //   this.durations = t.license_durations;
    // });
  }

  _onSubmit(formData) {
    // this.isLoading = true;
    // let regist_dt;
    // if (formData.regist_dt !== null) {
    //   regist_dt = `${formData.regist_dt.year}-${formData.regist_dt.month}-${formData.regist_dt.day}`;
    // } else {
    //   regist_dt = null;
    // }
    // let update_dt;
    // if (formData.update_dt !== null) {
    //   update_dt = `${formData.update_dt.year}-${formData.update_dt.month}-${formData.update_dt.day}`;
    // } else {
    //   update_dt = null;
    // }
    // return this.adminLicenseService.updatedLicense(this.lId, regist_dt, formData.duration, update_dt,
    //   formData.regist_sts, formData.username, formData.email)
    //   .subscribe(() => {
    //     this.isLoading = false;
    //     this.toast.success('Updated Success!');
    //     this.modal.close('close');
    //   }, (err) => {
    //     this.isLoading = false;
    //     this.errorCode = err.error[0].code;
    //     if (this.errorCode === ERROR_CODE.regist_dt_date_invalid) {
    //       this.toast.error('初回登録日 not format!');
    //     } else if (this.errorCode === ERROR_CODE.update_dt_date_invalid) {
    //       this.toast.error('更新日 not format!');
    //     }
    //   });
  }

  onSuccess() {
  }

  upFile($event) {
    const reader = new FileReader();
    const file = $event.target.files[0];
    const fileSize = $event.target.files[0].size / 1024 / 1024;
    if (fileSize > environment.SIZE_CSV_MB) {
      this.toast.error('File size exceeds 1 MB');
    } else {
      if ($event.target.files && $event.target.files[0]) {
        this.companyFile = $event.target.files && $event.target.files[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageUrl = file.name;
          this.editFile = false;
          this.removeUpload = true;
          this.isUpload = true;
        };
        this.cd.markForCheck();
      }
    }
  }

  updateCsv() {
    this.isUploading = true;
    const data = new FormData();
    data.append('id', this.lId);
    data.append('csv', this.companyFile);
    this.adminCsvService.updateCsv(data)
      .subscribe(resp => {
        this.toast.success(this.translate.instant('common.toast_msg.success.upload'));
        this.isUpload = false;
        this.modal.close('close');
        window.scrollTo(0, 0);
        setTimeout(() => {
        }, 5000);
        this.isUploading = false;
      }, (e) => {
        this.toast.success(this.translate.instant('common.toast_msg.failed.upload'));
        this.isUploading = false;
      });
  }

  cancelUpload() {
    this.isUpload = false;
    this.editFile = true;
    this.removeUpload = false;
    this.imageUrl = null;

  }

  goBack() {
    if (this.isChange) {
      const modal = this.modalService.open(ModalConfirmComponent);
      modal.componentInstance.message = 'Are you sure come back license list?';
      modal.componentInstance.title = 'Data lost alert';
      modal.result
        .then(() => {
          this.modal.close('close');
        });
    } else {
      this.modal.close('close');
    }
  }
}
