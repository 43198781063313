<div class="wrapper-home" style="width: 100vw">
  <header class="header fixed-top">
    <div class="container-fluid d-flex justify-content-between">
      <a [routerLink]="['/']" class="logo-header" style="text-decoration:none;">
        <img src="./assets/images/Logo_4.png" alt="" />
      </a>
      <nav>
        <ul class="list-unstyled">
          <li>
            <a (click)="login()" class="btn btn-outline-light" style="color: white">{{ 'page.home.login_link' | translate }}</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <section class="section-hero">
    <div class="section-hero-inner">
      <div class="container">
        <div class="hero-text">
          <div class="row">
            <div class="_left col-12 col-md-12 col-lg-5 wow slideInLeft">
              <img class=" d-sm-block d-md-block d-none" src="/assets/images/home/sbiz_logo.png" />
              <img class="d-block d-sm-none" src="/assets/images/home/Logo_1-01.png
                " />
            </div>
            <div class="_right col-12 col-md-12 col-lg-7 wow slideInRight">
              <h2>{{ 'page.home.text_1' | translate }}</h2>
              <p>
                <a href=""><b class="font-lg">{{ 'page.home.text_2' | translate }}</b></a>{{ 'page.home.text_3' | translate }}
              </p>
              <ul class="font-md list-unstyled mb-5">
                <li>
                  ・
                  {{ 'page.home.text_4' | translate }}
                </li>
                <li>
                  ・
                  {{ 'page.home.text_5' | translate }}
                </li>
                <li>
                  ・
                  {{ 'page.home.text_6' | translate }}
                </li>
                <li>
                  ・
                  {{ 'page.home.text_7' | translate }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-service">
    <div class="container">
      <h2 class="section-title">{{ 'page.home.text_8' | translate }}</h2>
      <div class="service-container">
        <div class="service-inner">
          <div class="service-box">
            <div class="service-item s-item-1">
              <img src="/assets/images/home/service-1.png" alt="" class="wow fadeInUp" />
              <div class="mb service-text wow fadeInUp" data-wow-delay="0.2s">
                <h4 class="c-blue">{{ 'page.home.text_9' | translate }}</h4>
                <p>
                  {{ 'page.home.text_10' | translate }}<br />{{ 'page.home.text_11' | translate }}
                </p>
              </div>
            </div>
            <div class="service-item s-item-2">
              <img src="/assets/images/home/service-2.png" alt="" class="wow fadeInUp" data-wow-delay="0.4s" />
              <div class="mb service-text wow fadeInUp" data-wow-delay="0.4s">
                <h4 class="c-blue">{{ 'page.home.text_12' | translate }}</h4>
                <p>
                  {{ 'page.home.text_13' | translate }}<br />{{ 'page.home.text_14' | translate }}
                </p>
              </div>
            </div>
            <div class="service-item s-item-3">
              <img src="/assets/images/home/service-3.png" alt="" class="wow fadeInUp" data-wow-delay="0.7s" />
              <div class="mb service-text wow fadeInUp" data-wow-delay="0.6s">
                <h4 class="c-blue">{{ 'page.home.text_15' | translate }}</h4>
                <p>
                  {{ 'page.home.text_16' | translate }}<br />{{ 'page.home.text_17' | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-platform">
    <div>
      <div class="platform-container">
        <h2 class="section-title text-center">
          {{ 'page.home.text_18' | translate }}
        </h2>
        <div class="platform-list">
          <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
              <div class="platform-item">
                <div class="number">01</div>
                <div class="content">
                  <h4 class="c-blue">{{ 'page.home.text_19' | translate }}</h4>
                  <p>
                    ・{{ 'page.home.text_20' | translate }}<br />
                    ・{{ 'page.home.text_21' | translate }}
                  </p>
                  <!-- <a href="#" class="platform-btn">配信</a> -->
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.2s">
              <div class="platform-item">
                <div class="number">02</div>
                <div class="content">
                  <h4 class="c-blue">{{ 'page.home.text_22' | translate }}<br />{{ 'page.home.text_23' | translate }}</h4>
                  <p style="margin-bottom: 67px;">
                    {{ 'page.home.text_24' | translate }}
                  </p>
                  <!-- <a href="#" class="platform-btn">制作</a> -->
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
              <div class="platform-item">
                <div class="number">03</div>
                <div class="content">
                  <h4 class="c-blue">{{ 'page.home.text_25' | translate }}<br />{{ 'page.home.text_26' | translate }}</h4>
                  <p style="margin-bottom: 67px;">
                    {{ 'page.home.text_27' | translate }}
                  </p>
                  <!-- <a href="#" class="platform-btn">外部サービス連携</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-case-study">
    <div class="container">
      <h2 class="section-title">{{ 'page.home.text_28' | translate }}</h2>
      <div class="case-study-content">
        <div class="case-study-item wow">
          <div class="case-study-thumb">
            <img src="/assets/images/home/case-study-1.jpg" alt="" />
          </div>
          <div class="case-study-content">
            <h4 class="font-weight-bold case-study-title">
              <a href="#">{{ 'page.home.text_29' | translate }}</a>
            </h4>
            <div class="_text">
              <div class="_left">
                {{ 'page.home.text_30' | translate }}
              </div>
              <div class="_right">
                {{ 'page.home.text_31' | translate }}<br />
                {{ 'page.home.text_32' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="case-study-item wow" data-wow-delay="0.2s">
          <div class="case-study-thumb">
            <img src="/assets/images/home/case-study-2.jpg" alt="" />
          </div>
          <div class="case-study-content">
            <h4 class="font-weight-bold case-study-title">
              <a href="#">{{ 'page.home.text_33' | translate }}</a>
            </h4>
            <div class="_text">
              <div class="_left">
                {{ 'page.home.text_34' | translate }}
              </div>
              <div class="_right">
                {{ 'page.home.text_35' | translate }}<br />
                {{ 'page.home.text_36' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="case-study-item wow" data-wow-delay="0.4s">
          <div class="case-study-thumb">
            <img src="/assets/images/home/case-study-3.jpg" alt="" />
          </div>
          <div class="case-study-content">
            <h4 class="font-weight-bold case-study-title">
              <a href="#">{{ 'page.home.text_37' | translate }}</a>
            </h4>
            <div class="_text">
              <div class="_left">
                {{ 'page.home.text_38' | translate }}
              </div>
              <div class="_right">
                {{ 'page.home.text_39' | translate }}<br />
                {{ 'page.home.text_40' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-contact">
    <div class="contact-title">{{ 'page.home.text_41' | translate }}</div>
    <div class="contact-subtitle">{{ 'page.home.text_42' | translate }}</div>
    <div class="contact-content">{{ 'page.home.text_43' | translate }}<br class="sp">{{ 'page.home.text_44' | translate }}</div>
    <div class="contact-button-container">
      <a class="btn" href="https://www.njk.co.jp/contact/styly" target="targe_contract">
        <i class="fas fa-envelope"></i>
        <span>{{ 'page.home.text_45' | translate }}</span>
        <i class="fas fa-chevron-right"></i>
      </a>
    </div>
  </section>
  <footer class="footer">
    <div class="footer-content">
      <div class="container">
        <div class="footer-info text-center">
          <div class="logo-footer">
            <a [routerLink]="['/']">
              <img src="/assets/images/home/sbiz_logo_w.png" alt="" />
            </a>
          </div>
          <!-- <div class="info-footer">
            <h5>
              Contact:
              <div class="d-inline-block">
                <i class="fa fa-envelope"></i> <a href="https://www.njk.co.jp/contact/styly" target="contact">
                  https://www.njk.co.jp/contact/styly</a>
              </div>
            </h5>
          </div> -->
        </div>
        <div class="text-center copyright">
          {{ 'page.home.copyright' | translate }}
        </div>
        <div class="text-center copyright-img">
          <a href="#"><img src="/assets/images/home/njklogo7_white.png" alt="" /></a>
        </div>
      </div>
    </div>
  </footer>
</div>