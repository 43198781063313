import { Component, OnInit } from '@angular/core';
import { AbstractSearchPage } from '../../abstract-search-page';
import { License } from '../../../models/license';
import { SearchRequest } from '../../../models/search-request';
import { Observable } from 'rxjs';
import { PageResponse } from '../../../models/page-response';
import { AdminLicenseService } from '../../../services/admin/admin-license.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ModalCreateLicenseComponent } from './modal-create-license/modal-create-license.component';
import { ModalEditLicenseComponent } from './modal-edit-license/modal-edit-license.component';
import * as _ from 'lodash';
import { SettingService } from '../../../services/setting.service';
import { ModalConfirmComponent } from '../../../components/modal/modal-confirm/modal-confirm.component';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

export interface AdminLicenseSearchForm {
  code: string;
  regist_from: any;
  regist_to: any;
  duration: number;
  regist_sts: number;
  name: string;
  email: string;
  license_term?: string;
}

@Component({
  selector: 'app-license-management',
  templateUrl: './license-management.component.html',
  styleUrls: ['./license-management.component.scss'],
})
export class LicenseManagementComponent
  extends AbstractSearchPage<AdminLicenseSearchForm, License>
  implements OnInit {
  durations: any[];
  isSearch = true;
  isFirstSearch: boolean;
  isCompareDate = false;
  constructor(
    private adminLicenseService: AdminLicenseService,
    private settingService: SettingService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.isFirstSearch = true;
    this.search();
    this.settingService.getDurations().subscribe((t) => {
      this.durations = t.license_durations;
    });
  }

  _initSearchForm(): SearchRequest<AdminLicenseSearchForm> {
    return <SearchRequest<AdminLicenseSearchForm>>{
      size: 20,
      page: 1,
      search: {
        code: '',
        regist_from: '',
        regist_to: '',
        duration: null,
        regist_sts: null,
        name: '',
        email: '',
      },
      sort: [],
    };
  }

  _search(
    searchForm: SearchRequest<AdminLicenseSearchForm>
  ): Observable<PageResponse<License>> {
    const _searchForm = _.cloneDeep(searchForm);
    if (
      searchForm.search.regist_from !== '' &&
      searchForm.search.regist_from !== null
    ) {
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      if (typeof searchForm.search.regist_from === 'string') {
        _searchForm.search.regist_from = searchForm.search.regist_from.replace(
          /\//gi,
          '-'
        );
      } else {
        const registFrom = searchForm.search.regist_from;
        _searchForm.search.regist_from = `${registFrom.year}-${registFrom.month}-${registFrom.day}`;
      }
    }
    if (
      searchForm.search.regist_to !== '' &&
      searchForm.search.regist_to !== null
    ) {
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      if (typeof searchForm.search.regist_to === 'string') {
        _searchForm.search.regist_from = searchForm.search.regist_from.replace(
          /\//gi,
          '-'
        );
      } else {
        const registTo = searchForm.search.regist_to;
        _searchForm.search.regist_to = `${registTo.year}-${registTo.month}-${registTo.day}`;
      }
    }

    return this.adminLicenseService.search(_searchForm);
  }

  createLicense() {
    const modalRef = this.modalService.open(ModalCreateLicenseComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modalRef.result.then(
      () => {
        this.search();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  editLicense(lId) {
    const modalRef = this.modalService.open(ModalEditLicenseComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modalRef.componentInstance.lId = lId;
    modalRef.result.then(
      () => {
        this.search();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteLicense(lId) {
    const modal = this.modalService.open(ModalConfirmComponent);
    modal.componentInstance.message = 'common.modal.delete.message';
    modal.componentInstance.title = 'common.modal.delete.heading';
    modal.componentInstance.revertButton = true;

    modal.result.then(() => {
      this._loading$.next(true);
      this.adminLicenseService.deleteLicense(lId).subscribe(
        () => {
          this.toast.success(
            this.translate.instant('common.toast_msg.success.delete')
          );
          this.search();
        },
        () => this._loading$.next(false)
      );
    });
  }

  getLicenseTerm(license) {
    const start_date = license.update_dt
      ? moment(license.update_dt)
      : moment(license.regist_dt);
    const license_term: any = moment(start_date)
      .add(license.duration, 'M')
      .add(-1, 'days');

    return license_term.format('YYYY/MM/DD').toString();
  }

  changeValueSearch() {
    const search = this.searchForm.search;
    if (
      search.regist_from &&
      search.regist_to &&
      moment(search.regist_from, 'YYYY/MM/DD').isValid &&
      moment(search.regist_to, 'YYYY/MM/DD').isValid
    ) {
      const registFrom = moment(
        `${search.regist_from.year}/${search.regist_from.month}/${search.regist_from.day}`
      );
      const registTo = moment(
        `${search.regist_to.year}/${search.regist_to.month}/${search.regist_to.day}`
      );
      this.isCompareDate = registFrom > registTo ? true : false;
    }

    const valueSearch = Object.values(search);

    this.isSearch = !valueSearch.some((item) => !!item);
    this.isFirstSearch = false;
  }
}
