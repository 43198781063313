export function generatePassword(l: number = 8) {
    let retVal = '';
    const normalCharset = 'abcdefghijklmnopqrstuvwxyz';
    const upperCharset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numberCharset = '0123456789';
    retVal += normalCharset.charAt(Math.floor(Math.random() * normalCharset.length));
    retVal += upperCharset.charAt(Math.floor(Math.random() * upperCharset.length));
    retVal += numberCharset.charAt(Math.floor(Math.random() * numberCharset.length));
    const charset = normalCharset + upperCharset + numberCharset;
    const length = l - 3;
    for (let i = 0; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return retVal;
}
