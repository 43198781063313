import { Component, OnInit } from '@angular/core';
import { SearchRequest, Sort } from '../../../models/search-request';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { PageResponseFile } from '../../../models/page-response';
import * as _ from 'lodash';
import { AdminFileService } from 'src/app/services/admin/admin-file.service';
import { ModalCreateFileComponent } from './modal-create-file/modal-create-file.component';
import { ModalEditFileComponent } from './modal-edit-file/modal-edit-file.component';
import { ModalConfirmComponent } from 'src/app/components/modal/modal-confirm/modal-confirm.component';
import { FORMAT_FILE_SELECT, FileModel } from '../../../models/file';
import { AbstractSearchPageFile } from '../../abstract-search-page-file';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map } from 'rxjs/operators';
import { ConvertingErrorModalComponent } from './converting-error-modal/converting-error-modal.component';

export interface AdminCsvSearchForm {
  code: string;
  name: string;
  price: number;
  quantity: number;
}

export interface AdminFileSearchFrom {
  file_name: string;
  date: any;
  ext: string;
}

@Component({
  selector: 'app-file-management',
  templateUrl: './file-management.component.html',
  styleUrls: ['./file-management.component.scss'],
})
export class FileManagementComponent
  extends AbstractSearchPageFile<AdminFileSearchFrom, FileModel>
  implements OnInit {
  imageUrl: any = null;
  removeUpload = false;
  isUpload = false;
  isUploading = false;
  isChange = false;
  sortFileName = true;
  sortDate = false;
  formatFile = [];
  isSearch = true;
  isFirstSearch: boolean;
  isActiveSortFileName = false;
  isActiveSortDate = true;

  constructor(
    private adminFileService: AdminFileService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.isFirstSearch = true;
    this.search();
    this.formatFile = Object.values(FORMAT_FILE_SELECT);
  }

  _initSearchForm(): SearchRequest<AdminFileSearchFrom> {
    return <SearchRequest<AdminFileSearchFrom>>{
      size: 20,
      page: 1,
      search: {
        file_name: '',
        date: '',
        ext: '',
      },
      sort: [],
    };
  }

  _search(
    searchForm: SearchRequest<AdminFileSearchFrom>
  ): Observable<PageResponseFile<FileModel>> {
    const _searchForm = _.cloneDeep(searchForm);
    if (searchForm.search.date !== '' && searchForm.search.date !== null) {
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      if (typeof searchForm.search.date === 'string') {
        _searchForm.search.date = searchForm.search.date.replace(/\//gi, '-');
      } else {
        _searchForm.search.date = `${searchForm.search.date.year}-${searchForm.search.date.month}-${searchForm.search.date.day}`;
      }
    }

    return this.adminFileService.search(_searchForm);
  }

  cancelUpload() {
    this.isUpload = false;
    this.removeUpload = false;
    this.imageUrl = null;
  }

  downloadFile(url) {
    window.open(url);
  }

  editFile(file) {
    const modalRef = this.modalService.open(ModalEditFileComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modalRef.componentInstance.file = file;
    modalRef.result.then(
      () => {
        this.search();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteFile(file) {
    const modal = this.modalService.open(ModalConfirmComponent);
    modal.componentInstance.message = 'common.modal.delete.message';
    modal.componentInstance.title = 'common.modal.delete.heading';
    modal.componentInstance.revertButton = true;
    modal.result.then(() => {
      this._loading$.next(true);
      this.adminFileService
        .delete(`${file.guid}.${file.file_type}`, file.file_path)
        .subscribe(
          () => {
            this.toast.success(
              this.translate.instant('common.toast_msg.success.delete')
            );
            this.search();
          },
          () => this._loading$.next(false)
        );
    });
  }

  copySuccess() {
    this.toast.success(this.translate.instant('common.toast_msg.copied'));
  }

  createFile() {
    const modalRef = this.modalService.open(ModalCreateFileComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modalRef.result.then(
      (res) => {
        this.search();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  sort(field) {
    if (field === 'file_name') {
      this.sortFileName = !this.sortFileName;
      this.sortDate = false;
      this.isActiveSortDate = false;
      const option = this.sortFileName ? 'asc' : 'desc';
      const optionSort: Sort = { column: field, dir: option };
      this.searchForm.sort = [optionSort];
    }

    if (field === 'file_datetime') {
      this.sortDate = !this.sortDate;
      this.sortFileName = true;
      this.isActiveSortFileName = false;
      const option = this.sortDate ? 'asc' : 'desc';
      const optionSort: Sort = { column: field, dir: option };
      this.searchForm.sort = [optionSort];
    }

    this.search();
  }

  sortDisable(field) {
    if (field === 'file_name') {
      this.sortFileName = true;
      this.sortDate = false;
      this.isActiveSortDate = false;
      this.isActiveSortFileName = true;
      const optionSort: Sort = { column: field, dir: 'asc' };
      this.searchForm.sort = [optionSort];
    }

    if (field === 'file_datetime') {
      this.sortDate = false;
      this.sortFileName = true;
      this.isActiveSortFileName = false;
      this.isActiveSortDate = true;
      const optionSort: Sort = { column: field, dir: 'desc' };
      this.searchForm.sort = [optionSort];
    }

    this.search();
  }

  changeValueSearch() {
    const search = this.searchForm.search;
    const valueSearch = Object.values(search);

    this.isSearch = !valueSearch.some((item) => !!item);
    this.isFirstSearch = false;
  }

  errorImage(event) {
    if (event.target.src.length < 200) {
      setTimeout(()  => {
        event.target.src += '?' + +Date.now();
        event.retryCount += 1;
      }, 1000);
    } else {
      event.target.src = 'assets/images/Thumbnail-empty.png';
    }
  }

  openConvertErrorModal(file: FileModel) {
    const modal = this.modalService.open(ConvertingErrorModalComponent);
    modal.componentInstance.file = file;
  }
}
