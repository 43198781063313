import {Injectable} from '@angular/core';
import * as moduleLog from 'loglevel';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() {
    moduleLog.setLevel(moduleLog.levels.DEBUG);
  }

  debug(...msg: any[]) {
    moduleLog.debug(msg);
  }

  info(...msg: any[]) {
    moduleLog.info(msg);
  }

  error(...msg: any[]) {
    moduleLog.error(msg);
  }

  warn(...msg: any[]) {
    moduleLog.warn(msg);
  }
}
