<div class="m-grid__item m-grid__item--fluid m-wrapper">
  <div class="m-content">
    <div class="m-portlet m-portlet--tab">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <span class="m-portlet__head-icon m--hide">
              <i class="la la-gear"></i>
            </span>
            <h3 class="tworld-form-header">
              {{ 'page.my_license.heading' | translate }}
            </h3>
          </div>
        </div>
      </div>
      <div class="m-portlet__body">
        <div class="row">
          <div class="col-12">
          </div>
        </div>
        <div class="row">
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-hover table-tworld align-middle">
            <thead>
              <tr>
                <th class="minimum text-center">#</th>
                <th class="text-center">{{ 'page.my_license.code_col' | translate }}</th>
                <th class="text-center">{{ 'page.my_license.email_col' | translate }}</th>
                <th class="text-center">{{ 'page.my_license.license_period_col' | translate }}</th>
                <th class="text-center">{{ 'page.my_license.license_exp_col' | translate }}</th>
                <th class="text-center">{{ 'page.my_license.registration_status_col' | translate }}</th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let license of pageResponse.rows, let i = index">
                <td class="text-center">{{size*(page-1) + i + 1}}</td>
                <td class="text-center">{{license.code}}</td>
                <td class="text-center">{{license.email}}</td>
                <td class="text-center" style="min-width: 140px;">{{getLicenseTerm(license)}}</td>
                <td class="text-center" style="min-width: 140px;">{{ 'common.select.license_expire_opt' | translate: { value: license.duration } }}</td>
                <td class="text-center" style="min-width: 85px;">
                  <span *ngIf="license.regist_sts === 0" class="label-danger">{{ 'common.select.registration_status.inactive_opt' | translate }}</span>
                  <span *ngIf="license.regist_sts === 1" class="label-success">{{ 'common.select.registration_status.active_opt' | translate }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!pageResponse.rows.length" class="no-content">
          {{ 'common.no_content_txt' | translate }}
        </div>
        <div class="row" *ngIf="pageResponse.count">
          <div class="col-md-8 col-sm-12">
            <ngb-pagination [rotate]="true" [collectionSize]="pageResponse.count" [(page)]="page" [maxSize]="3"
              [pageSize]="searchForm.size" [boundaryLinks]="true"></ngb-pagination>
          </div>
          <div class="col-md-4 col-sm-12 d-flex justify-content-center justify-content-md-end">
            <select title="Select Page Size" aria-label="Select Page change" class="form-control form-control-sm"
              style="width: auto" name="pageSize" [(ngModel)]="size">
              <option [ngValue]="10">{{ 'common.pagination.per_page_drd' | translate: { num: 10 } }}</option>
              <option [ngValue]="20">{{ 'common.pagination.per_page_drd' | translate: { num: 20 } }}</option>
              <option [ngValue]="50">{{ 'common.pagination.per_page_drd' | translate: { num: 50 } }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>