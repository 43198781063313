import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractFormComponent} from '../../components/abstract-form.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import { ModalSuccessSendEmailComponent } from './modal-success-send-email/modal-success-send-email.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalLoginComponent } from '../login/modal-login.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-forget-password',
  templateUrl: './modal-forgot-password.component.html',
  styleUrls: ['./modal-forgot-password.component.scss']
})
export class ModalForgotPasswordComponent extends AbstractFormComponent<any> implements OnInit {
  @ViewChild('buttonCancel') buttonCancel;
  constructor(
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private authService: AuthService,
    private modalService: NgbModal,
    private translate: TranslateService,
  ) {
    super();
  }


  ngOnInit() {
    this.form = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.email, Validators.maxLength(255)]),
    });
  }
  _onSubmit(formData) {
    this.isLoading = true;
    return this.authService.forgotPassword(formData.email)
      .subscribe(() => {
        this.isLoading = false;
        this.toast.success(this.translate.instant('page.account.toast_msg.restore_password_email_sent'));
        this.modal.close('close');
        this.modalService.open(ModalSuccessSendEmailComponent, {backdrop: 'static', keyboard: false, size: 'lg', centered: true});
      }, () => {
        this.isLoading = false;
        this.toast.error('メール送信失敗。');
      });
  }

  onSuccess() {
  }

  closeModal() {
    this.modal.dismiss('close');
    const modalRef = this.modalService.open(ModalLoginComponent, { backdrop: 'static', keyboard: false, centered: true });
    modalRef.result.then(() => {
    }, err => {
      console.log(err);
    });
  }
}
