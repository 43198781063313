<div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-content">
        <div class="m-portlet m-portlet--tab">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title">
                        <span class="m-portlet__head-icon m--hide">
                            <i class="la la-gear"></i>
                        </span>
                        <h3 class="tworld-form-header">
                            {{ 'page.csv.heading' | translate }}
                        </h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body">
                <div class="row">
                    <div class="col-8">

                    </div>
                    <div class="col-xl-4 order-1 order-xl-2 m--align-right">
                        <a (click)="createCsv()" class="btn btn-success" style="width: 140px">
                            <span style="color: white;">
                            <i class="fa fa-plus"></i>
                            <span>
                                {{ 'page.csv.create_btn' | translate }}
                            </span>
                            </span>
                        </a>
                    </div>
                </div>
                <br/>
                <div class="table-responsive">
                    <table class="table table-sm table-hover table-tworld align-middle">
                        <thead>
                            <tr>
                                <th class="text-center">{{ 'page.csv.filename_col' | translate }}</th>
                                <th class="text-center">{{ 'page.csv.datetime_col' | translate }}</th>
                                <th class="text-center">{{ 'page.csv.guid_col' | translate }}</th>
                                <th class="text-center">{{ 'page.csv.filepath_col' | translate }}</th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let csv of pageResponse.rows, let i = index">
                                <td class="text-left txt-file-name">
                                    <span id="file_name">{{csv.file_name}}</span>
                                    <button class="btn btn-copy-file-name" ngxClipboard [cbContent]="csv.file_name" (click)="copySuccess()"><i class="fas fa-copy"></i></button>
                                </td>
                                <td class="text-center">
                                    {{csv.file_datetime | dateFormat}}
                                </td>
                                <td class="text-center">
                                    <div class="guid-txt">
                                        <p class="guid">{{csv.guid}}</p>
                                        <button class="btn" ngxClipboard [cbContent]="csv.guid" (click)="copySuccess()"><i class="fas fa-copy"></i></button>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <button class="btn" ngxClipboard [cbContent]="csv.file_path" (click)="copySuccess()"><i class="fas fa-copy"></i></button>
                                </td>
                                <td class="minimum text-left align-middle">
                                    <div class="btn-group btn-group-sm">
                                        <button class="btn btn-danger" ngbTooltip="{{ 'common.tooltip.delete' | translate}}" (click)="deleteCsv(csv.guid)">
                                            <i class="fa fa-fw fa-trash-alt"></i>
                                          </button>
                                        <button class="btn btn-warning" (click)="editCsv(csv.guid)" ngbTooltip="{{ 'common.tooltip.edit' | translate}}">
                                            <i class="fa fa-fw fa-edit"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!pageResponse.rows.length" class="no-content">
                    {{ 'common.no_content_txt' | translate }}
                </div>
                <div class="row" *ngIf="pageResponse.count">
                    <div class="col-md-8 col-sm-12">
                        <ngb-pagination [collectionSize]="pageResponse.count" [rotate]="true" [(page)]="page" [maxSize]="3"
                            [pageSize]="searchForm.size" [boundaryLinks]="true"></ngb-pagination>
                    </div>
                    <div class="col-md-4 col-sm-12 d-flex justify-content-center justify-content-md-end">
                        <select title="Select Page Size" aria-label="Select Page change"
                            class="form-control form-control-sm" style="width: auto" name="pageSize" [(ngModel)]="size">
                            <option [ngValue]="10">{{ 'common.pagination.per_page_drd' | translate: { num: 10 } }}</option>
                            <option [ngValue]="20">{{ 'common.pagination.per_page_drd' | translate: { num: 20 } }}</option>
                            <option [ngValue]="50">{{ 'common.pagination.per_page_drd' | translate: { num: 50 } }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>