import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AdminFileService } from 'src/app/services/admin/admin-file.service';
import { checkSizeFile } from '../../../../util/file-util';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  IS_NEW,
  FORMAT_FILE_SELECT,
  TYPE_AND_SHORT_TYPE,
} from '../../../../models/file';
import { TranslateService } from '@ngx-translate/core';
import { ModalConfirmComponent } from 'src/app/components/modal/modal-confirm/modal-confirm.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-create-file',
  templateUrl: './modal-create-file.component.html',
  styleUrls: ['./modal-create-file.component.scss'],
})
export class ModalCreateFileComponent implements OnInit {
  isDragOver = false;
  fileURL: any = null;
  removeUpload = false;
  isUpload = false;
  isUploading = false;
  errorMessage: string = null;
  thumbnailUrl: string = null;
  progress = 0;
  dataFile: File;
  apiUpload: Subscription;
  dataPreSigned: { path: string; presigned_link: string };
  cancelUploadModel: NgbModalRef;

  @ViewChild('buttonCancel') buttonCancel;
  isChange = false;
  constructor(
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private adminFileService: AdminFileService,
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  handleLeaveOnUpload() {
    window.removeEventListener('beforeunload', () => {});
    window.addEventListener('beforeunload', (event) => {
      if (this.isUploading) {
        event.preventDefault();
        event.returnValue = '';
      }
    });
  }

  upFile($event, isDropped: boolean) {
    this.resetUploadModal();

    const reader = new FileReader();
    const file = isDropped ? $event[0] : $event.target.files[0];
    const fileSize =
      (isDropped ? $event[0].size : $event.target.files[0].size) / 1024 / 1024;
    let fileType = isDropped ? $event[0].type : $event.target.files[0].type;

    if (!fileType) {
      const name = file.name;
      const indexDotTail = name.lastIndexOf('.');
      const type = name.slice(indexDotTail + 1, name.length);
      if (type === FORMAT_FILE_SELECT.GLB) {
        fileType = '3dmodal/glb';
      }
    }

    const isFileUpload = checkSizeFile(fileType, fileSize);
    if (!isFileUpload.isAllow) {
      this.errorMessage = isFileUpload.message;
    } else {
      this.errorMessage = null;
      if (
        ($event.target && $event.target.files && $event.target.files[0]) ||
        ($event && $event[0])
      ) {
        this.dataFile = isDropped
          ? $event && $event[0]
          : $event.target.files && $event.target.files[0];

        // get pre signed
        const data = new FormData();
        data.append('ext', TYPE_AND_SHORT_TYPE[fileType]);
        data.append('is_new', IS_NEW.NEW.toString());
        this.adminFileService.getLinkUpload(data).subscribe((res: any) => {
          if (res.status === 200) {
            this.dataPreSigned = res.result;
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.fileURL = file.name;
              this.removeUpload = true;
              this.isUpload = true;
            };
            this.cd.markForCheck();
          }
        });
      }
    }
  }

  upload() {
    if (!this.dataPreSigned) {
      this.modal.close('close');
      this.toast.error(
        this.translate.instant(
          'page.file.error.file_does_not_exist_or_has_been_deleted'
        )
      );
      return;
    }

    this.progress = 0;
    this.isUploading = true;
    this.handleLeaveOnUpload();

    this.apiUpload = this.adminFileService
      .uploadFileToAws(
        this.dataPreSigned.presigned_link,
        this.dataFile.type,
        this.dataFile
      )
      .subscribe(
        async (res: any) => {
          if (res.type === HttpEventType.UploadProgress) {
            this.progress = Math.round((100 * res.loaded) / res.total);
            if (this.progress === 100 && this.cancelUploadModel) {
              this.cancelUploadModel.dismiss();
            }
          } else if (res instanceof HttpResponse) {
            if (this.isUpload) {
              // Call api BE verify file
              const data = new FormData();
              data.append('file_path', this.dataPreSigned.path);
              data.append('is_new', IS_NEW.NEW.toString());
              data.append('filename', this.dataFile.name);
              await this.adminFileService
                .uploadFile(data)
                .toPromise()
                .then(() => {
                  this.toast.success(
                    this.translate.instant(
                      'page.file.modal.upload.upload_success'
                    )
                  );
                  this.resetUploadModal();
                })
                .catch((e) => {
                  this.toast.error(
                    this.translate.instant(
                      'page.file.modal.upload.upload_fail_txt'
                    )
                  );
                  this.resetUploadModal();
                });
            }
          }
        },
        (e) => {
          this.toast.error(
            this.translate.instant('page.file.modal.upload.upload_fail_txt')
          );
          this.resetUploadModal();
        }
      );
  }

  cancelUpload() {
    if (this.isUploading) {
      this.modalConfirmQuit().then((res) => {
        if (res) {
          this.resetUploadModal();
          this.apiUpload.unsubscribe();
        }
      });
    } else {
      this.resetUploadModal();
    }
  }

  closeModal() {
    if (this.isUploading) {
      this.modalConfirmQuit().then((res) => {
        if (res) {
          this.apiUpload.unsubscribe();
          this.resetUploadModal();
          this.modal.close();
        }
      });
    } else {
      this.resetUploadModal();
      this.modal.close();
    }
  }

  modalConfirmQuit() {
    this.cancelUploadModel = this.modalService.open(ModalConfirmComponent);
    this.cancelUploadModel.componentInstance.title = this.translate.instant(
      'page.file.modal.confirm_quit.title'
    );
    this.cancelUploadModel.componentInstance.message = this.translate.instant(
      'page.file.modal.confirm_quit.message'
    );

    return this.cancelUploadModel.result;
  }

  resetUploadModal() {
    this.isDragOver = false;
    this.progress = 0;
    this.isUploading = false;
    this.isUpload = false;
    this.removeUpload = false;
    this.fileURL = null;
    this.thumbnailUrl = null;
    this.handleLeaveOnUpload();
  }
}
