<a href="javascript:void(0)" class="m-nav__link m-dropdown__toggle">
	<span class="m-topbar__userpic">
		<img width="20" *ngIf="currentLanguage === 'en'" src="./assets/images/flags/en.png" class="m--img-rounded m--marginless m--img-centered" alt=""/>
    <img width="20" *ngIf="currentLanguage === 'ja'" src="./assets/images/flags/ja.png" class="m--img-rounded m--marginless m--img-centered" alt=""/>
	</span>
</a>
<div class="m-dropdown__wrapper lang" style="top : 50px">
  <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
  <div class="m-dropdown__inner">
    <div
      class="m-dropdown__body"
      #mProfileDropdown
      style="background-size: cover"
    >
      <ul class="list-group m-lang">
        <li class="list-group-item">
          <a href="javascript:void(0)" (click)="changeLanguage('en')" class="m-nav__link m-dropdown__toggle">
            <span class="m-topbar__flag mr-2">
              <img src="./assets/images/flags/en.png" class="m--img-rounded m--marginless m--img-centered" alt=""/>
            </span>
            <span class="m-topbar__lang">English</span>
          </a>
        </li>
        <li class="list-group-item">
          <a href="javascript:void(0)" (click)="changeLanguage('ja')" class="m-nav__link m-dropdown__toggle">
            <span class="m-topbar__flag mr-2">
              <img src="./assets/images/flags/ja.png" class="m--img-rounded m--marginless m--img-centered" alt=""/>
            </span>
            <span class="m-topbar__lang">日本</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
