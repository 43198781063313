import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractFormComponent } from '../../components/abstract-form.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MustMatch, NotMatch } from '../../validators/mail.validator';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotContainUserName, PasswordStrengthValidator } from 'src/app/validators/password.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss'],
})
export class ModalChangePasswordComponent
  extends AbstractFormComponent<any>
  implements OnInit {
  @ViewChild('buttonCancel') buttonCancel;
  isTextFieldType: boolean;
  isTextFieldType1: boolean;
  isTextFieldType2: boolean;

  inputError: {
    password: boolean;
    new_password: boolean;
    password_confirmation: boolean;
  };

  confirmPasswordNotMatch: boolean;

  constructor(
    public modal: NgbActiveModal,
    private toast: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    const currentUserName = this.authService.currentUserValue.name;

    this.form = this.formBuilder.group(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.maxLength(32),
          Validators.minLength(8),
          PasswordStrengthValidator,
        ]),
        new_password: new FormControl('', [
          Validators.required,
          Validators.maxLength(32),
          Validators.minLength(8),
          PasswordStrengthValidator,
        ]),
        password_confirmation: new FormControl('', [
          Validators.required,
        ]),
      },
      {
        validator: [
          NotMatch('new_password', 'password'),
          MustMatch('new_password', 'password_confirmation'),
          NotContainUserName(currentUserName, 'new_password'),
        ],
      }
    );
    this.resetInputError();
  }

  resetInputError() {
    this.inputError = {
      password: false,
      new_password: false,
      password_confirmation: false,
    };
  }

  validateInput(key: string) {
    this.resetInputError();
    if (!this.form.controls[key].errors) {
      return;
    }
    console.log(this.form.get('password_confirmation'));
    let isErr = false;
    Object.keys(this.form.controls).every((objectKey) => {
      if (this.form.controls[objectKey].errors) {
        this.inputError[objectKey] = true;
        isErr = true;
        return false;
      }
      return isErr ? false : true;
    });
    if (isErr) {
      return;
    }
  }

  hidePass1() {
    this.isTextFieldType1 = !this.isTextFieldType1;
  }
  hidePass2() {
    this.isTextFieldType2 = !this.isTextFieldType2;
  }
  hidePass() {
    this.isTextFieldType = !this.isTextFieldType;
  }
  _onSubmit(formData) {
    this.isLoading = true;
    return this.authService
      .changePassword(
        formData.password.trim(),
        formData.new_password.trim(),
        formData.password_confirmation.trim()
      )
      .subscribe(
        () => {
          this.isLoading = false;
          this.toast.success(
            this.translate.instant(
              'page.modal_change_password.change_password_success'
            )
          );
          this.modal.close('close');
          this.authService.logout().subscribe((value) => {
            this.router.navigateByUrl('/');
          });
        },
        () => {
          this.isLoading = false;
          this.toast.error(
            this.translate.instant(
              'page.modal_change_password.change_password_failed'
            )
          );
        }
      );
  }

  onSuccess() {}
}
