import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appLoadingButton]'
})
export class LoadingButtonDirective implements OnChanges {

  @Input() isLoading: boolean;
  @Input() loadingText = 'Loading';
  loadingClass = ['m-loader', 'm-loader--light', 'm-loader--left'];
  oldText;

  constructor(private el: ElementRef, private render2: Renderer2, private translate: TranslateService) {
    const buttonText = this.el.nativeElement.getAttribute('data-i18n');
    if (buttonText) {
      this.translate.stream(buttonText).subscribe((data: any) => {
        this.oldText = data;
        this.render2.setProperty(this.el.nativeElement, 'innerHTML', data);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.oldText) {
      this.oldText = this.el.nativeElement.innerHTML;
    }
    if (changes.isLoading && changes.isLoading.currentValue) {
      this.loadingClass.forEach((i) => {
        this.render2.addClass(this.el.nativeElement, i);
      });
      this.render2.setProperty(this.el.nativeElement, 'innerHTML', this.loadingText);
    } else {
      this.loadingClass.forEach((i) => {
        this.render2.removeClass(this.el.nativeElement, i);
      });
      this.render2.setProperty(this.el.nativeElement, 'innerHTML', this.oldText);
    }
  }
}
