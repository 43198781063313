import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractFormComponent } from '../../../../components/abstract-form.component';
import { ERROR_CODE } from '../../../../models/error';
import { Information } from '../../../../models/information';
import { AdminInformationService } from '../../../../services/admin/admin-information.service';
import { SettingService } from '../../../../services/setting.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-my-modal-detail-information',
  templateUrl: './my-modal-detail-information.component.html',
  styleUrls: ['./my-modal-detail-information.component.scss']
})
export class MyModalDetailInformationComponent extends AbstractFormComponent<any> implements OnInit {

  @ViewChild('buttonCancel') buttonCancel;
  @Input() iId;
  information: Information;
  durations: any[];
  isChange = false;
  errorCode: ERROR_CODE | null = null;
  contentHtml: SafeHtml;
  constructor(
    public adminInformationService: AdminInformationService,
    public modal: NgbActiveModal,
    private settingService: SettingService,
    private sanitized: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    this.adminInformationService.getByIdInformation(this.iId)
      .subscribe(t => {
        this.information = t;
        this.contentHtml = this.sanitized.bypassSecurityTrustHtml(this.information.content);
        this.form = new FormGroup({
          'title': new FormControl({ value: this.information.title, disabled: true }),
          'content': new FormControl({ value: this.information.content, disabled: true }),
          'publish_date': new FormControl({ value: this.information.publish_date.toString().slice(0, -3), disabled: true }),
          'publish_flg': new FormControl({ value: this.information.publish_flg, disabled: true }),
        });

        this.form.valueChanges.subscribe(value => {
          this.isChange = true;
        });
      });
    this.settingService.getDurations().subscribe(t => {
      this.durations = t.information_durations;
    });
  }

  _onSubmit(formData) {
  }

  onSuccess() {
  }

  goBack() {
    this.modal.close('close');
  }
}
