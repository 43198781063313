import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractSearchPage } from '../../abstract-search-page';
import { Csv } from '../../../models/csv';
import { SearchRequest } from '../../../models/search-request';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PageResponse } from '../../../models/page-response';
import * as _ from 'lodash';
import { SettingService } from '../../../services/setting.service';
import { AdminCsvService } from 'src/app/services/admin/admin-csv.service';
import { environment } from '../../../../environments/environment';
import { ModalEditCsvComponent } from './modal-edit-csv/modal-edit-csv.component';
import { ModalConfirmComponent } from 'src/app/components/modal/modal-confirm/modal-confirm.component';
import { ModalCreateCsvComponent } from './modal-create-csv/modal-create-csv.component';
import { TranslateService } from '@ngx-translate/core';

export interface AdminCsvSearchForm {
  code: string;
  name: string;
  price: number;
  quantity: number;
}

@Component({
  selector: 'app-csv-management',
  templateUrl: './csv-management.component.html',
  styleUrls: ['./csv-management.component.scss'],
})
export class CsvManagementComponent
  extends AbstractSearchPage<AdminCsvSearchForm, Csv>
  implements OnInit {
  imageUrl: any = null;
  editFile = true;
  removeUpload = false;
  isUpload = false;
  companyFile: File;
  isUploading = false;
  isChange = false;
  constructor(
    private adminCsvService: AdminCsvService,
    private settingService: SettingService,
    private router: Router,
    private toast: ToastrService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.search();
  }
  _initSearchForm(): SearchRequest<AdminCsvSearchForm> {
    return <SearchRequest<AdminCsvSearchForm>>{
      size: 20,
      page: 1,
      search: {},
      sort: [],
    };
  }
  _search(
    searchForm: SearchRequest<AdminCsvSearchForm>
  ): Observable<PageResponse<Csv>> {
    return this.adminCsvService.search(searchForm);
  }

  upFile($event) {
    const reader = new FileReader();
    const file = $event.target.files[0];
    const fileSize = $event.target.files[0].size / 1024 / 1024;
    if (fileSize > environment.SIZE_CSV_MB) {
      this.toast.error('File size exceeds 1 MB');
    } else {
      if ($event.target.files && $event.target.files[0]) {
        this.companyFile = $event.target.files && $event.target.files[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageUrl = file.name;
          this.editFile = false;
          this.removeUpload = true;
          this.isUpload = true;
        };
        this.cd.markForCheck();
      }
    }
  }

  upload() {
    this.isUploading = true;
    const data = new FormData();
    data.append('csv', this.companyFile);
    this.adminCsvService.uploadCsv(data).subscribe(
      (resp) => {
        this.toast.success(
          this.translate.instant('page.file.modal.upload.upload_success')
        );
        this.isUpload = false;
        this.search();
        window.scrollTo(0, 0);
        setTimeout(() => {}, 5000);
        this.isUploading = false;
      },
      (e) => {
        this.toast.error(
          this.translate.instant('page.file.modal.upload.upload_fail_txt')
        );
        this.isUploading = false;
      }
    );
  }

  cancelUpload() {
    this.isUpload = false;
    this.editFile = true;
    this.removeUpload = false;
    this.imageUrl = null;
  }

  downloadFile(url) {
    window.open(url);
  }

  editCsv(guid) {
    const modalRef = this.modalService.open(ModalEditCsvComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.guid = guid;
    modalRef.result.then(
      () => {
        this.search();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteCsv(uId) {
    const modal = this.modalService.open(ModalConfirmComponent);
    modal.componentInstance.message = 'common.modal.delete.message';
    modal.componentInstance.title = 'common.modal.delete.heading';
    modal.componentInstance.revertButton = true;

    modal.result.then(() => {
      this._loading$.next(true);
      this.adminCsvService.delete(uId).subscribe(
        () => {
          this.toast.success(
            this.translate.instant('common.toast_msg.success.delete')
          );
          this.search();
        },
        () => this._loading$.next(false)
      );
    });
  }

  copySuccess() {
    this.toast.success(this.translate.instant('common.toast_msg.copied'));
  }

  createCsv() {
    const modalRef = this.modalService.open(ModalCreateCsvComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true,
    });
    modalRef.result.then(
      () => {
        this.search();
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
