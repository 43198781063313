<div class="m-grid__item m-grid__item--fluid m-wrapper">
  <div class="m-content">
    <div class="m-portlet m-portlet--tab">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <span class="m-portlet__head-icon m--hide">
              <i class="la la-gear"></i>
            </span>
            <h3 class="tworld-form-header">
              {{ "page.file.heading" | translate }}
            </h3>
          </div>
        </div>
      </div>
      <div class="m-portlet__body">
        <div class="row">
          <div class="col-12">
            <form class="form-inline row" (ngSubmit)="search()">
              <div class="col-lg-4 col-md-4 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.file.file_name_lbl" | translate }}
                  </label>
                  <div
                    class="m-input-icon m-input-icon--left mb-3"
                    style="width: auto"
                  >
                    <input
                      type="text"
                      class="form-control m-input"
                      placeholder="{{ 'page.file.file_name_lbl' | translate }}"
                      aria-label="Search by file name"
                      id="fileNameSearch"
                      [(ngModel)]="searchForm.search.file_name"
                      name="fileNameSearch"
                      style="width: 100%"
                      (keyup)="changeValueSearch()"
                    />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6"></div>
              <div class="col-lg-3 col-md-3 col-sm-6"></div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.file.date_time_lbl" | translate }}
                  </label>
                  <div class="m-input-icon mb-3" style="width: 100%">
                    <div class="input-group">
                      <input
                        class="form-control"
                        placeholder="{{
                          'page.file.date_time_placeholder' | translate
                        }}"
                        id="date"
                        name="dp"
                        ngbDatepicker
                        #date="ngbDatepicker"
                        [(ngModel)]="searchForm.search.date"
                        (keyup)="changeValueSearch()"
                        (dateSelect)="changeValueSearch()"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="date.toggle()"
                          type="button"
                        >
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.file.file_format_lbl" | translate }}
                  </label>
                  <div class="m-input-icon mb-3" style="width: auto">
                    <select
                      class="form-control lg-input"
                      aria-label="Select format file"
                      name="ext"
                      style="width: 100%; padding: 0 1rem !important"
                      [(ngModel)]="searchForm.search.ext"
                      (change)="changeValueSearch()"
                    >
                      <option [ngValue]="null" [selected]="true">
                        {{ "common.select.all_opt" | translate }}
                      </option>
                      <option *ngFor="let f of formatFile" [value]="f">
                        {{ f }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-6"></div>
              <div class="col-lg-2 col-md-2 col-sm-6"></div>

              <div style="margin: 10px auto">
                <button
                  type="submit"
                  class="btn btn-tworld-important btn-sm mb-3"
                  appLoadingButton
                  data-i18n="common.search_btn"
                  loadingText="{{ 'common.search_btn' | translate }}"
                  [isLoading]="_loading$ | async"
                  style="padding: 7px 40px; font-size: 16px"
                  [disabled]="isSearch && isFirstSearch"
                ></button>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-8"></div>
          <div class="col-xl-4 order-1 order-xl-2 m--align-right">
            <a
              (click)="createFile()"
              class="btn btn-success"
              style="width: 140px"
            >
              <span style="color: white">
                <i class="fa fa-plus"></i>
                <span>
                  {{ "page.file.upload_btn" | translate }}
                </span>
              </span>
            </a>
          </div>
        </div>
        <br />
        <div class="table-responsive">
          <table class="table table-sm table-hover table-tworld align-middle">
            <thead>
              <tr>
                <th class="text-center"></th>
                <th class="text-center">
                  {{ "page.file.file_name_lbl" | translate }}
                  <i
                    class="fas fa-caret-square-up"
                    *ngIf="sortFileName && isActiveSortFileName"
                    (click)="sort('file_name')"
                  ></i>
                  <i
                    class="fas fa-caret-square-up unactive"
                    *ngIf="sortFileName && !isActiveSortFileName"
                    (click)="sortDisable('file_name')"
                  ></i>
                  <i
                    class="fas fa-caret-square-down"
                    *ngIf="!sortFileName"
                    (click)="sort('file_name')"
                  ></i>
                </th>
                <th class="text-center" style="min-width: 12rem"></th>
                <th class="text-center date-time-col">
                  {{ "page.file.date_time_lbl" | translate }}
                  <i
                    class="fas fa-caret-square-up"
                    *ngIf="sortDate"
                    (click)="sort('file_datetime')"
                  ></i>
                  <i
                    class="fas fa-caret-square-down"
                    *ngIf="!sortDate && isActiveSortDate"
                    (click)="sort('file_datetime')"
                  ></i>
                  <i
                    class="fas fa-caret-square-down unactive"
                    *ngIf="!sortDate && !isActiveSortDate"
                    (click)="sortDisable('file_datetime')"
                  ></i>
                </th>
                <th class="text-center">
                  {{ "page.file.guid_lbl" | translate }}
                </th>
                <th class="text-center">
                  {{ "page.file.path_lbl" | translate }}
                </th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let file of pageResponse.result.files; let i = index">
                <td class="minimum text-left align-middle">
                  <span id="thumbnail">
                    <img
                      [src]="
                        file?.convert_status?.status === 'ERROR' || file?.convert_status?.status === 'WAITING'
                          ? 'assets/images/Thumbnail-empty.png'
                          : file.file_thumbnail
                      "
                      id="file-{{ file.id }}"
                      class="thumbnail"
                      *ngIf="file.file_thumbnail"
                      (error)="
                        file?.convert_status?.status === 'ERROR'
                          ? ''
                          : errorImage($event)
                      "
                    />
                    <div
                      class="thumbnail-empty"
                      *ngIf="!file.file_thumbnail"
                    ></div>
                  </span>
                </td>
                <td class="text-left file-name-col">
                  <span class="pl-0 file_name">{{ file.file_name }}</span>
                </td>
                <td>
                  <div *ngIf="file.convert_status">
                    <span [ngSwitch]="file.convert_status.status">
                      <span
                        *ngSwitchCase="'ERROR'"
                        class="bg-secondary p-1 text-center"
                      >
                        <a
                          class="btn-link text text-danger p-0"
                          style="cursor: pointer"
                          (click)="openConvertErrorModal(file)"
                          >{{
                            "page.file.converting_status.error" | translate
                          }}</a
                        >
                      </span>
                      <span
                        *ngSwitchCase="'PROGRESSING'"
                        class="bg-secondary p-1 text-center text-sm"
                        >{{
                          "page.file.converting_status.processing" | translate
                        }}</span
                      >
                      <span
                        *ngSwitchCase="'WAITING'"
                        class="bg-secondary p-1 text-center text-sm"
                        >{{
                          "page.file.converting_status.processing" | translate
                        }}</span
                      >
                    </span>
                  </div>
                </td>
                <td class="text-center">
                  <div class="file-datetime">
                    {{
                      file.file_datetime
                        | dateFormat: { format: "YYYY/MM/DD HH:mm:ss" }
                    }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="guid-txt">
                    <p class="guid">{{ file.guid }}.{{ file.file_type }}</p>
                    <button
                      class="btn"
                      ngxClipboard
                      [cbContent]="file.guid + '.' + file.file_type"
                      (click)="copySuccess()"
                    >
                      <i class="fas fa-copy"></i>
                    </button>
                  </div>
                </td>
                <td class="text-center">
                  <button
                    class="btn"
                    ngxClipboard
                    [cbContent]="file.file_path"
                    (click)="copySuccess()"
                  >
                    <i class="fas fa-copy"></i>
                  </button>
                </td>
                <td class="minimum text-left align-middle">
                  <div class="btn-group btn-group-sm">
                    <button
                      [disabled]="
                        file.convert_status &&
                        file.convert_status.action === '0'
                      "
                      class="btn btn-danger"
                      ngbTooltip="{{ 'common.tooltip.delete' | translate }}"
                      (click)="deleteFile(file)"
                    >
                      <i class="fa fa-fw fa-trash-alt"></i>
                    </button>
                    <button
                      [disabled]="
                        file.convert_status &&
                        file.convert_status.action === '0'
                      "
                      class="btn btn-warning"
                      (click)="editFile(file)"
                      ngbTooltip="{{ 'common.tooltip.edit' | translate }}"
                    >
                      <i class="fa fa-fw fa-edit"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!pageResponse.result.files.length" class="no-content">
          {{ "common.no_content_txt" | translate }}
        </div>
        <div class="row" *ngIf="pageResponse.result.total">
          <div class="col-md-8 col-sm-12">
            <ngb-pagination
              [collectionSize]="pageResponse.result.total"
              [rotate]="true"
              [(page)]="page"
              [maxSize]="3"
              [pageSize]="searchForm.size"
              [boundaryLinks]="true"
            ></ngb-pagination>
          </div>
          <div
            class="col-md-4 col-sm-12 d-flex justify-content-center justify-content-md-end"
          >
            <select
              title="Select Page Size"
              aria-label="Select Page change"
              class="form-control form-control-sm"
              style="width: auto"
              name="pageSize"
              [(ngModel)]="size"
            >
              <option [ngValue]="10">
                {{ "common.pagination.per_page_drd" | translate: { num: 10 } }}
              </option>
              <option [ngValue]="20">
                {{ "common.pagination.per_page_drd" | translate: { num: 20 } }}
              </option>
              <option [ngValue]="50">
                {{ "common.pagination.per_page_drd" | translate: { num: 50 } }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
