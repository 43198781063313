<div class="m-grid__item m-grid__item--fluid m-wrapper">
  <div class="m-content">
    <div class="m-portlet m-portlet--tab">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <span class="m-portlet__head-icon m--hide">
              <i class="la la-gear"></i>
            </span>
            <h3 class="tworld-form-header">
              {{ "page.scene.heading" | translate }}
            </h3>
          </div>
        </div>
      </div>
      <div class="m-portlet__body">
        <div class="row">
          <div class="col-12">
            <form class="form-inline pb-4 row" (ngSubmit)="search()">
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.scene.scene_name_lbl" | translate }}
                  </label>
                  <div class="mb-3" style="width: auto">
                    <div
                      class="m-input-icon m-input-icon--left mb-3"
                      style="width: auto"
                    >
                      <input
                        type="text"
                        class="form-control m-input"
                        aria-label="scene_name"
                        id="scene_name"
                        [(ngModel)]="searchForm.search.name"
                        placeholder="{{
                          'page.scene.scene_name_lbl' | translate
                        }}"
                        name="scene_name"
                        style="width: 100%"
                        (keydown)="changeFirstSearchState()"
                      />
                      <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.scene.user_name_lbl" | translate }}
                  </label>
                  <div class="mb-3" style="width: auto">
                    <div
                      class="m-input-icon m-input-icon--left mb-3"
                      style="width: auto"
                    >
                      <input
                        type="text"
                        class="form-control m-input"
                        aria-label="user_name"
                        id="user_name"
                        placeholder="{{
                          'page.scene.user_name_lbl' | translate
                        }}"
                        [(ngModel)]="searchForm.search.username"
                        name="user_name"
                        style="width: 100%"
                        (keydown)="changeFirstSearchState()"
                      />
                      <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div style="line-height: 40px">
                  <label
                    style="font-size: 14px; justify-content: start !important"
                  >
                    {{ "page.scene.scene_guid_lbl" | translate }}
                  </label>
                  <div class="mb-3" style="width: auto">
                    <div
                      class="m-input-icon m-input-icon--left mb-3"
                      style="width: auto"
                    >
                      <input
                        type="text"
                        class="form-control m-input"
                        aria-label="scene_guid"
                        id="scene_guid"
                        [(ngModel)]="searchForm.search.id"
                        placeholder="{{
                          'page.scene.scene_guid_lbl' | translate
                        }}"
                        name="scene_guid"
                        style="width: 100%"
                        (keydown)="changeFirstSearchState()"
                      />
                      <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 text-center">
                <button
                  type="submit"
                  class="btn btn-tworld-important btn-sm mb-3"
                  appLoadingButton
                  data-i18n="common.search_btn"
                  loadingText="{{ 'common.search_btn' | translate }}"
                  [isLoading]="_loading$ | async"
                  [disabled]="isFirstSearch"
                  style="padding: 7px 40px; font-size: 16px"
                ></button>
              </div>
            </form>
          </div>
        </div>

        <div class="row">
          <div class="col-8"></div>
          <div class="col-xl-4 order-1 order-xl-2 m--align-right mb-2 pr-4">
            <a (click)="createScene()" class="btn btn-success">
              <div style="color: white" class="d-flex align-items-center">
                <i class="fa fa-plus"></i>
                <span class="text-nowrap ml-2">
                  {{ "page.scene.new_scene_btn" | translate }}
                </span>
              </div>
            </a>
          </div>
        </div>

        <br />
        <div class="table-responsive">
          <table class="table table-sm table-hover table-tworld align-middle">
            <thead>
              <tr>
                <th class="text-center">
                  {{ "page.scene.scene_name_col" | translate }}
                </th>
                <th class="text-center">
                  {{ "page.scene.user_name_col" | translate }}
                </th>
                <th class="text-center">
                  {{ "page.scene.scene_guid_col" | translate }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let scene of pageResponse.rows; let i = index">
                <td class="text-center">
                  <a
                    class="scene-hyperlink"
                    href="https://gallery.styly.cc/scene/{{ scene.id }}"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ scene.name }}</a
                  >
                </td>
                <td class="text-center">{{ scene.username }}</td>
                <td class="text-center">{{ scene.id }}</td>
                <td class="text-right">
                  <div class="btn-group btn-group-sm">
                    <button
                      class="btn btn-danger"
                      ngbTooltip="{{ 'common.tooltip.delete' | translate }}"
                      (click)="deleteScene(scene)"
                    >
                      <i class="fa fa-fw fa-trash-alt"></i>
                    </button>
                    <button
                      class="btn btn-warning"
                      ngbTooltip="{{ 'common.tooltip.edit' | translate }}"
                      (click)="editScene(scene)"
                    >
                      <i class="fa fa-fw fa-edit"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="!pageResponse.rows.length" class="no-content">
          {{ "common.no_content_txt" | translate }}
        </div>
        <div class="row" *ngIf="pageResponse.count">
          <div
            class="col-12 d-flex justify-content-center justify-content-md-end"
          >
            <ngb-pagination
              [collectionSize]="pageResponse.count"
              [(page)]="page"
              [rotate]="true"
              [maxSize]="3"
              [pageSize]="searchForm.size"
              [boundaryLinks]="true"
            ></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
