import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  retry,
  switchMap,
  take,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LogService } from '../services/log.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor(private log: LogService, private authService: AuthService) {}
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      const access_token = user.access_token;
      if (
        !request.headers.get('Authorization') &&
        request.url.includes(environment.API_SERVER_URL)
      ) {
        request = request.clone({
          url: request.url,
          setHeaders: {
            Authorization: `Bearer ${access_token}`,
            'strict-transport-security': 'max-age:31536000; includeSubDomains'
          },
        });
      }
    }

    return next.handle(request).pipe(
      catchError((err) => {
        this.log.debug(err);
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          if (err.error[0].code === 'token_invalid') {
            return this.handle401Error(request, next);
          } else {
            this.logoutUser();
          }
        }
        return throwError(err);
      })
    );
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser.access_token) {
        return this.authService.refreshToken(currentUser.access_token).pipe(
          switchMap((newToken: any) => {
            if (newToken.access_token) {
              const newAccessToken = newToken.access_token;
              currentUser.access_token = newAccessToken;
              localStorage.setItem('currentUser', JSON.stringify(currentUser));

              this.tokenSubject.next(newAccessToken);
              return next.handle(this.addToken(req, newAccessToken));
            }
            // If we don't get a new token, we are in trouble so logout.
            return this.logoutUser();
          }),
          catchError((error) => {
            // If there is an exception calling 'refreshToken', bad news so logout.
            this.log.debug(error);
            return this.logoutUser();
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
        );
      }
      this.logoutUser();
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addToken(req, token));
        })
      );
    }
  }

  logoutUser() {
    this.authService.logout();
    location.reload();
    return throwError('');
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }
}
