import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-my-modal-confirm-quit',
  templateUrl: './my-modal-confirm-quit.component.html',
  styleUrls: ['./my-modal-confirm-quit.component.scss']
})
export class MyModalConfirmQuitComponent implements OnInit {
  @Input() title;
  @Input() confirmMessage;
  @Input() message;
  @Input() type = 'warning';
  @Input() buttonConfirmTitle = 'common.modal.delete.confirm_btn';
  @Input() buttonCancelTitle = 'common.modal.delete.cancel_btn';
  @Output() emitSerivce = new EventEmitter;

  @ViewChild('buttonCancel') buttonCancel;

  constructor(public modal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  closeModal() {
    this.emitSerivce.next(true);
    this.modal.dismiss('dismiss');
  }
}
