import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Information } from '../../../models/information';
import { PageResponse } from '../../../models/page-response';
import { SearchRequest } from '../../../models/search-request';
import { AdminInformationService } from '../../../services/admin/admin-information.service';
import { SettingService } from '../../../services/setting.service';
import { AbstractSearchPage } from '../../abstract-search-page';
import { MyModalDetailInformationComponent } from './my-modal-detail-information/my-modal-detail-information.component';

export interface AdminInformationSearchForm {
  title: string;
  content: string;
  publish_date: string;
  publish_flg: number;
}

@Component({
  selector: 'app-my-information',
  templateUrl: './my-information.component.html',
  styleUrls: ['./my-information.component.scss']
})
export class MyInformationComponent extends AbstractSearchPage<AdminInformationSearchForm, Information> implements OnInit {

  durations: any[];
  current_user = JSON.parse(localStorage.getItem('currentUser'));
  constructor(private adminInformationService: AdminInformationService,
              public authService: AuthService,
              private settingService: SettingService,
              private router: Router,
              private toast: ToastrService,
              private modalService: NgbModal) {
    super();
  }

  ngOnInit() {
    this.search();
    this.settingService.getDurations().subscribe(t => {
      this.durations = t.license_durations;
    });
  }

  _initSearchForm(): SearchRequest<AdminInformationSearchForm> {
    return <SearchRequest<AdminInformationSearchForm>>{
      size: 10,
      page: 1,
      search: {
        title: '',
        content: '',
        publish_date: '',
        publish_flg: 1,
      },
      sort: {},
    };
  }

  _search(searchForm: SearchRequest<AdminInformationSearchForm>): Observable<PageResponse<Information>> {
    const _searchForm = _.cloneDeep(searchForm);
    return this.adminInformationService.search(_searchForm);
  }

  detailInformation(iId) {
    const modalRef = this.modalService.open(MyModalDetailInformationComponent, {backdrop: 'static', keyboard: false, size: 'lg'});
    modalRef.componentInstance.iId = iId;
    modalRef.result.then(() => {
      this.search();
    }, err => {
      console.log(err);
    });
  }


  getStatusName(statusValue) {
    switch (statusValue) {
      case 0:
        return '非公開';
      case 1:
        return '公開';
      default:
        return '下書き';
    }
  }

}
