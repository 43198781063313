import { TYPE_AND_SIZE, TYPE_AND_SHORT_TYPE } from '../models/file';

export function checkSizeFile(type, size, typeFileCurrent = null, isEdit = false) {
  const nameType = Object.keys(TYPE_AND_SIZE);
  if (!nameType.includes(type)) {
    return {
      isAllow: false,
      message: 'page.file.error.file_format'
    };
  }

  if (size > TYPE_AND_SIZE[type]) {
    return {
      isAllow: false,
      message: 'page.file.error.file_size'
    };
  }

  const keysFile = Object.keys(TYPE_AND_SHORT_TYPE);
  const fileJPGAndJPEG: Array<String> = [keysFile[0], keysFile[1]];
  if (isEdit && typeFileCurrent) {
    if (fileJPGAndJPEG.includes(type)) {
      if (![`.${TYPE_AND_SHORT_TYPE['image/jpeg']}`, `.${TYPE_AND_SHORT_TYPE['image/jpg']}`].includes(typeFileCurrent)) {
        return {
          isAllow: false,
          message: 'page.file.error.file_format_edit'
        };
      }
    } else if (typeFileCurrent !== `.${TYPE_AND_SHORT_TYPE[type]}`) {
      return {
        isAllow: false,
        message: 'page.file.error.file_format_edit'
      };
    }
  }

  return { isAllow: true };
}
