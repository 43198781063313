<div class="modal-header">
  <h2 class="modal-title" id="modal-title" style="font-weight: 600">
    {{ "page.file.modal.convert_error.heading" | translate }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body form-label">
  <div class="mb-2">
    <p class="text-danger p-0" style="max-width: 100%;">
      {{ "page.file.modal.convert_error.file_name" | translate }}:
      <span class="ml-1">{{file.file_name}}</span>
    </p>
    <p class="text-danger p-0">
      {{ "page.file.modal.convert_error.error_code" | translate }}:
      <span class="ml-1">{{file.convert_status.error_code}}</span>
    </p>
    <p class="text-danger p-0">
      {{ "page.file.modal.convert_error.error_msg" | translate }}:
      <span class="ml-1">{{"page.file.modal.mediaconvert_error."+file.convert_status.error_code | translate}}</span>
    </p>
  </div>

  <div class="w-100 text-center px-2">
    <p class="text-danger mb-1 p-0">
      {{ "page.file.modal.convert_error.modal_msg_1" | translate }}
    </p>
    <p class="text-danger p-0">
      {{ "page.file.modal.convert_error.modal_msg_2" | translate }}
    </p>
  </div>

  <br />
  <div class="text-center form-item-row">
    <button
      type="button"
      class="btn btn-md btn-secondary with-button"
      (click)="modal.close()"
    >
      {{ "page.file.modal.convert_error.close_btn" | translate }}</button
    >&nbsp;
  </div>
  &nbsp;
</div>
