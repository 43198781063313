import { Injectable } from '@angular/core';
import { AbstractService } from '../abstract.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Log } from '../../models/log';
import { SearchRequest } from '../../models/search-request';
import { PageResponse } from '../../models/page-response';
import { AdminLogSearchForm } from 'src/app/pages/admin/log-management/log-management.component';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminLogService extends AbstractService {
  _URL = `${environment.API_SERVER_URL}/api`;

  constructor(private http: HttpClient, private authService: AuthService) {
    super();
  }

  search(searchForm: SearchRequest<AdminLogSearchForm>) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${access_token}`,
    });
    const params = AbstractService.requestToParams(searchForm);
    return this.http.get<PageResponse<Log>>(`${this._URL}/logs`, {
      params,
      headers: httpHeaders,
    });
  }

  downLoadLog(logs) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const data = {
      file_data: {
        user_id: user.name,
        logs: logs,
      },
    };

    return this.http.post(`${this._URL}/v1/logs/download`, data, {
      responseType: 'arraybuffer',
    });
  }

  deleteLogById(ids) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${access_token}`,
    });
    const data = { ids };
    return this.http.post<PageResponse<Log>>(`${this._URL}/logs/delete`, data, {
      headers: httpHeaders,
    });
  }

  deleteAllLog() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const access_token = user.access_token;
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${access_token}`,
    });

    return this.http.delete<PageResponse<Log>>(`${this._URL}/logs/all`, {
      headers: httpHeaders,
    });
  }
}
