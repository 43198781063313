import { Injectable } from '@angular/core';
import { AbstractService } from '../abstract.service';
import { HttpClient } from '@angular/common/http';
import { SearchRequest } from '../../models/search-request';
import { PageResponse } from '../../models/page-response';
import { environment } from '../../../environments/environment';
import { AdminSceneSearchForm } from 'src/app/pages/admin/scene-management/scene-management.component';
import { SceneModel } from 'src/app/models/scene';

@Injectable({
  providedIn: 'root',
})
export class AdminSceneService extends AbstractService {
  _URL = `${environment.API_SERVER_URL}/api`;

  constructor(private http: HttpClient) {
    super();
  }

  search(searchForm: SearchRequest<AdminSceneSearchForm>) {
    const params = AbstractService.requestToParams(searchForm);
    const apiURL = `${this._URL}/scenes`;
    return this.http.get<PageResponse<SceneModel>>(apiURL, { params });
  }

  createScene(scene: SceneModel) {
    const apiURL = `${this._URL}/scenes`;
    return this.http.post(apiURL, { ...scene });
  }

  editScene(scene: SceneModel, id: string) {
    const apiURL = `${this._URL}/scenes/${id}`;
    const body = {
      name: scene.name,
      id: scene.id,
      user_id: scene.user_id,
    };
    return this.http.post(apiURL, body);
  }

  deleteScene(id: string) {
    const apiURL = `${this._URL}/scenes/${id}`;
    return this.http.delete(apiURL);
  }
}
