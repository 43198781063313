import { Component, OnInit } from '@angular/core';
import { AdminAccountService } from 'src/app/services/admin/admin-account.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User} from 'src/app/models/user';
import { AbstractSearchPage } from '../../abstract-search-page';
import { SearchRequest } from '../../../models/search-request';
import { PageResponse } from '../../../models/page-response';
import {AuthService} from '../../../services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalCreateAccountComponent} from './modal-create-account/modal-create-account.component';
import {ModalEditAccountComponent} from './modal-edit-account/modal-edit-account.component';
import {ModalConfirmComponent} from '../../../components/modal/modal-confirm/modal-confirm.component';
import {ToastrService} from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

export interface AdminAccountSearchForm {
  name: string;
  email: string;
  phone: string;
  gender: number;
}

@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.scss']
})
export class AccountManagementComponent extends AbstractSearchPage<AdminAccountSearchForm, User> implements OnInit {
  isSearch = true;
  isFirstSearch: boolean;
  currentUser: User;
  constructor(
    private adminAccountService: AdminAccountService,
    private router: Router,
    private modalService: NgbModal,
    private toast: ToastrService,
    private authService: AuthService,
    private translate: TranslateService,
  ) {
    super();
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit() {
    this.isFirstSearch = true;
    this.search();
  }

  _initSearchForm(): SearchRequest<AdminAccountSearchForm> {
    return <SearchRequest<AdminAccountSearchForm>>{
      size: 20,
      page: 1,
      search: {
        name: '',
        email: '',
        phone: '',
        gender: null,
      },
      sort: []
    };
  }

  _search(searchForm: SearchRequest<AdminAccountSearchForm>): Observable<PageResponse<User>> {
    return this.adminAccountService.search(searchForm);
  }

  createAccount() {
    const modalRef = this.modalService.open(ModalCreateAccountComponent, {backdrop: 'static', keyboard: false, size: 'lg'});
    modalRef.result.then(() => {
      this.search({ loading: false });
    }, err => {
      console.log(err);
    });
  }

  edit(aId) {
    const modalRef = this.modalService.open(ModalEditAccountComponent, {backdrop: 'static', keyboard: false, size: 'lg'});
    modalRef.componentInstance.aId = aId;
    modalRef.result.then(() => {
      this.search({ loading: false });
    }, err => {
      console.log(err);
    });
  }

  async delete(uId) {
    const licences: any = await this.adminAccountService.getListUserLicense(uId).toPromise();
    const modal = this.modalService.open(ModalConfirmComponent);
    if (licences.length > 0) {
      modal.componentInstance.message = `
      <p>
      ${this.translate.instant('page.account.modal.delete.message')} <br/>
      ${this.translate.instant('page.account.modal.delete.license_list')}<br/>
      ${licences.map((item: any) => `${item.code}<br/>`).join('')} <br/>
      ${this.translate.instant('page.account.modal.delete.confirm')}
      </p>
      `;
    } else {
      modal.componentInstance.message = `
      <p>
      ${this.translate.instant('page.account.modal.delete.message')} <br/>
      ${this.translate.instant('page.account.modal.delete.confirm')}
      </p>
      `;
    }
    modal.componentInstance.title = 'common.modal.delete.heading';
    modal.componentInstance.revertButton = true;
    modal.result
      .then(() => {
        this._loading$.next(true);
        this.adminAccountService.delete(uId)
          .subscribe(() => {
            this.toast.success(this.translate.instant('common.toast_msg.success.delete'));
            this.search();
          }, () => this._loading$.next(false));
      });
  }

  changeValueSearch() {
    const search = this.searchForm.search;
    const valueSearch = Object.values(search);

    this.isSearch = !valueSearch.some(item => !!item);
    this.isFirstSearch = false;
  }
}
