// tslint:disable:max-line-length
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDateCustomParserFormatter } from './components/share/widget/format-date-picker';

import { ModalLoginComponent } from './pages/login/modal-login.component';
import { HeaderComponent } from './components/share/header/header.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { FooterComponent } from './components/share/footer/footer.component';
import { UserMainComponent } from './pages/admin/user-main/user-main.component';
import { SidebarComponent } from './components/share/sidebar/sidebar.component';
import { HomeComponent } from './pages/home/home.component';
import { WINDOW_PROVIDERS } from './providers/window.provider';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { MyHttpInterceptor } from './providers/http.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileUploadModule } from 'ng2-file-upload';
import { LoadingButtonDirective } from './components/share/widget/loading-button.directive';
import { RequiredLabelDirective } from './components/share/widget/required-label.directive';
import { FormValidDirective } from './components/share/widget/form-valid.directive';
import { TextSearchPipe } from './pipes/text-search.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UserProfileDropdownComponent } from './components/share/user-profile-dropdown/user-profile-dropdown.component';
import { UserLanguageDropdownComponent } from './components/share/user-language-dropdown/user-language-dropdown.component';
import { InputTelComponent } from './components/share/widget/form-controls/input-tel.component';
import { InputDateComponent } from './components/share/widget/form-controls/input-date.component';
import { SelectCountryComponent } from './components/share/widget/form-controls/select-country';
import { SelectLanguageComponent } from './components/share/widget/form-controls/select-language';
import { ModalConfirmComponent } from './components/modal/modal-confirm/modal-confirm.component';
import { ModalConfirm2FARegisteredComponent } from './components/modal/modal-confirm-2fa-registered/modal-confirm-2fa-registered.component';
import { ModalAlertComponent } from './components/modal/modal-alert/modal-alert.component';
import { InputTextComponent } from './components/share/widget/form-controls/input-text.component';
import { SortableDirective } from './components/table/sortable.directive';
import { DatePipe } from './pipes/date.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { AccountManagementComponent } from './pages/admin/account-management/account-management.component';
import { ClipboardModule } from 'ngx-clipboard';
import { MHeaderDirective } from './components/share/m-header.directive';
import { AdminAccountResolve } from './resolves/admin-account.resolve';
import { HiddenPhonePipe } from './pipes/hidden-phone.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AdsenseModule } from 'ng2-adsense';
import { ToastrModule } from 'ngx-toastr';
import { MemberManagementComponent } from './pages/admin/member-management/member-management.component';
import { LicenseManagementComponent } from './pages/admin/license-management/license-management.component';
import { InformationManagementComponent } from './pages/admin/information-management/information-management.component';
import { ModalCreateInformationComponent } from './pages/admin/information-management/modal-create-information/modal-create-information.component';
import { ModalEditAccountComponent } from './pages/admin/account-management/modal-edit-account/modal-edit-account.component';
import { ModalCreateAccountComponent } from './pages/admin/account-management/modal-create-account/modal-create-account.component';
import { NotFoundPageComponent } from './pages/error/not-found-page/not-found-page.component';
import { MyLicenseComponent } from './pages/user/my-license/my-license.component';
import { ModalCreateLicenseComponent } from './pages/admin/license-management/modal-create-license/modal-create-license.component';
import { ModalEditLicenseComponent } from './pages/admin/license-management/modal-edit-license/modal-edit-license.component';
import { ModalChangePasswordComponent } from './pages/modal-change-password/modal-change-password.component';
import { ModalConfirmCodeSetting2FAComponent } from './pages/modal-confirm-code-setting-2fa/modal-confirm-code-setting-2fa.component';
import { ModalForgotPasswordComponent } from './pages/modal-forgot-password/modal-forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { LogManagementComponent } from './pages/admin/log-management/log-management.component';
import { CsvManagementComponent } from './pages/admin/csv-management/csv-management.component';
import { ModalEditCsvComponent } from './pages/admin/csv-management/modal-edit-csv/modal-edit-csv.component';
import { MyCsvComponent } from './pages/user/my-csv/my-csv.component';
import { MyModalEditCsvComponent } from './pages/user/my-csv/my-modal-edit-csv/my-modal-edit-csv.component';
import { ModalEditInformationComponent } from './pages/admin/information-management/modal-edit-information/modal-edit-information.component';
import { ModalDetailInformationComponent } from './pages/admin/information-management/modal-detail-information/modal-detail-information.component';
import { ModalCreateCsvComponent } from './pages/admin/csv-management/modal-create-csv/modal-create-csv.component';
import { ModalCodeLoginComponent } from './pages/login/modal-code-login/modal-code-login/modal-code-login.component';
import { MyInformationComponent } from './pages/user/my-information/my-information.component';
import { MyModalDetailInformationComponent } from './pages/user/my-information/my-modal-detail-information/my-modal-detail-information.component';
import { ModalSearchUserLicenseComponent } from './pages/admin/license-management/modal-search-user-license/modal-search-user-license.component';
import { ModalDownloadLogComponent } from './pages/admin/log-management/modal-download-log/modal-download-log.component';
import { FileManagementComponent } from './pages/admin/file-management/file-management.component';
import { ModalCreateFileComponent } from './pages/admin/file-management/modal-create-file/modal-create-file.component';
import { ModalEditFileComponent } from './pages/admin/file-management/modal-edit-file/modal-edit-file.component';
import { MyFileComponent } from './pages/user/my-file/my-file.component';
import { MyModalCreateFileComponent } from './pages/user/my-file/my-modal-create-file/my-modal-create-file.component';
import { MyModalEditFileComponent } from './pages/user/my-file/my-modal-edit-file/my-modal-edit-file.component';
import { MyModalConfirmQuitComponent } from './pages/user/my-file/my-modal-confirm-quit/my-modal-confirm-quit.component';
import { ModalSuccessSendEmailComponent } from './pages/modal-forgot-password/modal-success-send-email/modal-success-send-email.component';
import { MyDashboardComponent } from './pages/user/my-dashboard/my-dashboard.component';
import { AnalysisManagementComponent } from './pages/admin/analysis-management/analysis-management.component';
import { ModalStatisticAnalysisComponent } from './pages/admin/analysis-management/modal-statistic-analysis/modal-statistic-analysis.component';
import { ModalDetailedLogComponent } from './pages/admin/analysis-management/modal-detailed-log/modal-detailed-log.component';
import { SceneManagementComponent } from './pages/admin/scene-management/scene-management.component';
import { ModalEditSceneComponent } from './pages/admin/scene-management/modal-edit-scene/modal-edit-scene.component';
import { ModalCreateSceneComponent } from './pages/admin/scene-management/modal-create-scene/modal-create-scene.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { ConvertingErrorModalComponent } from './pages/admin/file-management/converting-error-modal/converting-error-modal.component';
import { DropZoneDirective } from './pages/admin/file-management/drop-file.directive';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  ['clean'], // remove formatting button
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    `.json?v=${Date.now()}`
  );
}

@NgModule({
  declarations: [
    AppComponent,
    ModalLoginComponent,
    HeaderComponent,
    DashboardComponent,
    FooterComponent,
    UserMainComponent,
    SidebarComponent,
    HomeComponent,
    LoadingButtonDirective,
    RequiredLabelDirective,
    FormValidDirective,
    TextSearchPipe,
    ModalConfirmComponent,
    ModalConfirm2FARegisteredComponent,
    UserProfileDropdownComponent,
    UserLanguageDropdownComponent,
    InputTelComponent,
    InputDateComponent,
    SelectCountryComponent,
    SelectLanguageComponent,
    ModalAlertComponent,
    InputTextComponent,
    SortableDirective,
    DatePipe,
    DateAgoPipe,
    ReplacePipe,
    DateFormatPipe,
    AccountManagementComponent,
    MHeaderDirective,
    HiddenPhonePipe,
    MemberManagementComponent,
    LicenseManagementComponent,
    InformationManagementComponent,
    ModalCreateInformationComponent,
    ModalEditInformationComponent,
    ModalDetailInformationComponent,
    MyInformationComponent,
    MyModalDetailInformationComponent,
    ModalEditAccountComponent,
    ModalCreateAccountComponent,
    NotFoundPageComponent,
    MyLicenseComponent,
    ModalCreateLicenseComponent,
    ModalEditLicenseComponent,
    ModalChangePasswordComponent,
    ModalConfirmCodeSetting2FAComponent,
    ModalForgotPasswordComponent,
    ResetPasswordComponent,
    LogManagementComponent,
    CsvManagementComponent,
    ModalEditCsvComponent,
    MyModalEditCsvComponent,
    MyCsvComponent,
    ModalCreateCsvComponent,
    ModalCodeLoginComponent,
    ModalSearchUserLicenseComponent,
    ModalDownloadLogComponent,
    FileManagementComponent,
    ModalCreateFileComponent,
    ModalEditFileComponent,
    MyFileComponent,
    MyModalCreateFileComponent,
    MyModalEditFileComponent,
    MyModalConfirmQuitComponent,
    ModalSuccessSendEmailComponent,
    MyDashboardComponent,
    AnalysisManagementComponent,
    ModalStatisticAnalysisComponent,
    ModalDetailedLogComponent,
    SceneManagementComponent,
    ModalEditSceneComponent,
    ModalCreateSceneComponent,
    ConvertingErrorModalComponent,
    DropZoneDirective
  ],
  imports: [
    NgbModule,
    BrowserModule,
    QuillModule.forRoot({
      modules: {
        toolbar: toolbarOptions,
      },
    }),
    AppRoutingModule,
    PerfectScrollbarModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    NgSelectModule,
    FileUploadModule,
    ClipboardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AdsenseModule.forRoot({
      adClient: 'ca-pub-1729968955957024',
      adSlot: 7259870550,
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
    }),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    WINDOW_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true },
    AdminAccountResolve,
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
  entryComponents: [
    ModalConfirmComponent,
    ModalConfirm2FARegisteredComponent,
    ModalAlertComponent,
    ModalLoginComponent,
    ModalCreateAccountComponent,
    ModalEditAccountComponent,
    ModalCreateLicenseComponent,
    ModalEditLicenseComponent,
    ModalEditCsvComponent,
    ModalChangePasswordComponent,
    ModalForgotPasswordComponent,
    MyModalEditCsvComponent,
    ModalCreateInformationComponent,
    ModalEditInformationComponent,
    ModalDetailInformationComponent,
    ModalCreateCsvComponent,
    ModalCodeLoginComponent,
    MyModalDetailInformationComponent,
    ModalSearchUserLicenseComponent,
    ModalDownloadLogComponent,
    ModalCreateFileComponent,
    ModalEditFileComponent,
    FileManagementComponent,
    MyFileComponent,
    MyModalCreateFileComponent,
    MyModalEditFileComponent,
    MyModalConfirmQuitComponent,
    ModalConfirmCodeSetting2FAComponent,
    ModalSuccessSendEmailComponent,
    ModalStatisticAnalysisComponent,
    ModalDetailedLogComponent,
    ModalCreateSceneComponent,
    ModalEditSceneComponent,
    ConvertingErrorModalComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
