<div class="m-grid__item m-grid__item--fluid m-wrapper">
  <div class="m-content">
    <div class="m-portlet m-portlet--tab">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <span class="m-portlet__head-icon m--hide">
              <i class="la la-gear"></i>
            </span>
            <h3 class="tworld-form-header">
              {{ 'page.account.heading' | translate}}
            </h3>
          </div>
        </div>
      </div>
      <div class="m-portlet__body">
        <div class="row">
          <div class="col-12">
            <form class="form-inline row" (ngSubmit)="search()">
              <div class="col-lg-4 col-md-4 col-sm-6">
                <div style="line-height: 40px">
                  <label style="font-size: 14px; justify-content: start !important;">
                    {{ 'page.account.login_name_lbl' | translate}}
                  </label>
                  <div class="m-input-icon m-input-icon--left mb-3" style="width: auto;">
                    <input type="text" class="form-control m-input" placeholder="{{ 'page.account.login_name_lbl' | translate}}"
                           aria-label="ユーザ 名"
                           id="name" [(ngModel)]="searchForm.search.name" name="name"
                           style="width: 100%"
                           (keyup)="changeValueSearch()"
                    >
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                  <span>
                    <i class="la la-search"></i>
                  </span>
                </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <div style="line-height: 40px">
                  <label style="font-size: 14px; justify-content: start !important;">
                    {{ 'page.account.email_lbl' | translate}}
                  </label>
                  <div class="m-input-icon m-input-icon--left mb-3" style="width: auto;">
                    <input type="text" class="form-control  m-input" placeholder="{{ 'page.account.email_lbl' | translate}}"
                           aria-label="メールアドレス"
                           id="email" [(ngModel)]="searchForm.search.email" name="email"
                           style="width: 100%"
                           (keyup)="changeValueSearch()"
                    >
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                  <span>
                    <i class="fa fa-envelope"></i>
                  </span>
                </span>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6">
              </div>
              <div style="margin: 10px auto">
                <button type="submit" class="btn btn-tworld-important btn-sm mb-3" appLoadingButton data-i18n="common.search_btn" loadingText="{{ 'common.loading_text' | translate }}"
                        [isLoading]="_loading$ | async"
                        style="padding: 7px 40px;font-size: 16px"
                        [disabled]="isSearch && isFirstSearch"
                ></button>
              </div>
            </form>

          </div>
        </div>
        <div class="row">
          <div class="col-8">

          </div>
          <div class="col-xl-4 order-1 order-xl-2 m--align-right">
            <a (click)="createAccount()" class="btn btn-success"
               style="width: 100px"
            >
              <span style="color: white;">
                 <i class="fa fa-plus"></i>
                <span>
                  {{ 'page.account.create_btn' | translate}}
                </span>
              </span>
            </a>
          </div>
        </div>
        <br/>
        <div class="table-responsive">
          <table class="table table-sm table-hover table-tworld align-middle">
            <thead>
            <tr>
              <th class="minimum text-center">#</th>
              <th class="text-center">{{ 'page.account.login_name_col' | translate}}</th>
              <th class="text-center">{{ 'page.account.email_col' | translate}}</th>
              <th class="text-center tfa-status">{{ 'page.account.tfa_col' | translate}}</th>
              <th class="minimum text-left"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of pageResponse.rows, let i = index">
              <td class="text-center">{{size*(page-1) + i + 1}}</td>
              <td class="text-center">{{user.name}}</td>
              <td class="text-center">{{user.email}}</td>
              <td class="text-center" *ngIf="user.two_factor_authen_flg"><i class="fa fa-check-circle text-success"></i></td>
              <td class="text-center" *ngIf="!user.two_factor_authen_flg"><i class="fa fa-ban text-danger"></i></td>
              <td class="minimum text-left align-middle">
                <div class="btn-group btn-group-sm">
                  <button
                    *ngIf="currentUser.id !== user.id; else isMe"
                    class="btn btn-danger" ngbTooltip="{{ 'common.tooltip.delete' | translate}}" (click)="delete(user.id)">
                    <i class="fa fa-fw fa-trash-alt"></i>
                  </button>
                  <ng-template #isMe>
                    <button class="btn btn-danger" style="visibility: hidden;">
                      <i class="fa fa-fw fa-trash-alt"></i>
                    </button>
                  </ng-template>
                  <button
                    class="btn btn-warning" (click)="edit(user.id)" ngbTooltip="{{ 'common.tooltip.edit' | translate}}">
                    <i class="fa fa-fw fa-edit"></i>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!pageResponse.rows.length" class="no-content">
          {{ 'common.no_content_txt' | translate }}
        </div>
        <div class="row" *ngIf="pageResponse.count">
          <div class="col-md-8 col-sm-12">
            <ngb-pagination [collectionSize]="pageResponse.count" [rotate]="true" [(page)]="page"
                            [maxSize]="3" [pageSize]="searchForm.size"
                            [boundaryLinks]="true"></ngb-pagination>
          </div>
          <div class="col-md-4 col-sm-12 d-flex justify-content-center justify-content-md-end">
            <select title="Select Page Size"
                    aria-label="Select Page change"
                    class="form-control form-control-sm" style="width: auto"
                    name="pageSize" [(ngModel)]="size">
              <option [ngValue]="10">{{ 'common.pagination.per_page_drd' | translate: { num: 10 } }}</option>
              <option [ngValue]="20">{{ 'common.pagination.per_page_drd' | translate: { num: 20 } }}</option>
              <option [ngValue]="50">{{ 'common.pagination.per_page_drd' | translate: { num: 50 } }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
