import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AdminLogService } from 'src/app/services/admin/admin-log.service';
import { Log } from '../../../models/log';
import { PageResponse } from '../../../models/page-response';
import { SearchRequest } from '../../../models/search-request';
import { AbstractSearchPage } from '../../abstract-search-page';
import { ModalConfirmComponent } from '../../../components/modal/modal-confirm/modal-confirm.component';
import { ModalDownloadLogComponent } from './modal-download-log/modal-download-log.component';
import { TranslateService } from '@ngx-translate/core';

export interface AdminLogSearchForm {
  scene_id: string;
  log_level: number;
  user_name: string;
  device_info: string;
}

@Component({
  selector: 'app-log-management',
  templateUrl: './log-management.component.html',
  styleUrls: ['./log-management.component.scss'],
})
export class LogManagementComponent
  extends AbstractSearchPage<AdminLogSearchForm, Log>
  implements OnInit {
  isChange = false;
  checkedItems: any = [];
  isSearch = true;
  isFirstSearch: boolean;

  constructor(
    private adminLogService: AdminLogService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.isFirstSearch = true;
    this.search();
  }
  _initSearchForm(): SearchRequest<AdminLogSearchForm> {
    return <SearchRequest<AdminLogSearchForm>>{
      size: 20,
      page: 1,
      search: {},
      sort: [],
    };
  }
  _search(
    searchForm: SearchRequest<AdminLogSearchForm>
  ): Observable<PageResponse<Log>> {
    return this.adminLogService.search(searchForm);
  }

  // Delete all log.
  deleteAllLog() {
    const modal = this.modalService.open(ModalConfirmComponent);
    modal.componentInstance.message = 'common.modal.delete.message';
    modal.componentInstance.title = 'common.modal.delete.heading';
    modal.componentInstance.revertButton = true;
    modal.result.then(() => {
      this._loading$.next(true);
      this.adminLogService.deleteAllLog().subscribe(
        () => {
          this.toast.success(
            this.translate.instant('common.toast_msg.success.delete')
          );
          this.search();
        },
        () => this._loading$.next(false)
      );
    });
  }

  replaceLogDate(log: any) {
    return log.date.replaceAll('-', '/');
  }

  deleteBySelected() {
    const modal = this.modalService.open(ModalConfirmComponent);
    modal.componentInstance.message = 'common.modal.delete.message';
    modal.componentInstance.title = 'common.modal.delete.heading';
    modal.componentInstance.revertButton = true;
    modal.result.then(() => {
      this._loading$.next(true);
      this.adminLogService.deleteLogById(this.checkedItems).subscribe(
        () => {
          this.toast.success(
            this.translate.instant('common.toast_msg.success.delete')
          );
          this.checkedItems = [];
          this.search();
        },
        () => this._loading$.next(false)
      );
    });
  }

  downloadLog() {
    const modalRef = this.modalService.open(ModalDownloadLogComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
    modalRef.componentInstance.rows = this.pageResponse.rows;
    modalRef.result.then(
      () => {
        // this.search();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onChecked(item: any, event: any) {
    const { checked, value } = event.target;
    if (checked) {
      this.checkedItems.push(+value);
    } else {
      const index = this.checkedItems.indexOf(+value);
      if (index !== -1) {
        this.checkedItems.splice(index, 1);
      }
    }

    console.log(' this.checkedItems', this.checkedItems);
  }

  changeValueSearch() {
    const search = this.searchForm.search;
    const valueSearch = Object.values(search);

    this.isSearch = !valueSearch.some((item) => !!item);
    this.isFirstSearch = false;
  }
}
