<div *ngIf="form">
  <div class="modal-header">
    <h2 class="modal-title" id="modal-title" style="font-weight: 600">
      {{ "page.account.modal.edit.heading" | translate }}
    </h2>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('close')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body form-label">
    <form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form">
      <legend class="title">
        {{ "page.account.modal.edit.member_info.heading" | translate }}
      </legend>

      <div class="form-group row form-item-row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label
            for="name"
            class="col-form-label isRequired"
            data-required="必須"
            appRequiredLabel
            >{{
              "page.account.modal.edit.member_info.login_name_lbl" | translate
            }}</label
          >
          <input
            class="form-control m-input"
            name="name"
            id="name"
            type="text"
            formControlName="name"
          />
          <div
            *ngIf="form.controls.name.touched"
            class="text text-danger mt-1 text-left"
          >
            <span
              *ngIf="form.controls.name.errors?.required"
              class="text text-danger mt-1 label-error"
            >
            {{ "validation.field_required" | translate }}
            </span>
            <span
              *ngIf="form.controls.name.errors?.maxlength"
              class="text text-danger mt-1 label-error"
            >
            {{
              "validation.field_max_length" | translate | replace: "{max}":"255"
            }}
            </span>
          </div>
        </div>
      </div>
      <div class="form-group row form-item-row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label
            for="email"
            class="col-form-label isRequired"
            data-required="必須"
            appRequiredLabel
            >{{
              "page.account.modal.edit.member_info.email_lbl" | translate
            }}</label
          >
          <input
            class="form-control m-input"
            name="email"
            id="email"
            type="email"
            formControlName="email"
          />
          <div
            *ngIf="form.controls.email.touched"
            class="text text-danger mt-1 text-left"
          >
            <span
              *ngIf="form.controls.email.errors?.required"
              class="text text-danger mt-1 label-error"
            >
            {{ "validation.field_required" | translate }}
            </span>
            <span
              *ngIf="
                form.controls.email.errors?.email ||
                form.controls.email.errors?.pattern
              "
              class="text text-danger mt-1 label-error"
            >
            {{ "validation.field_format" | translate }}
            </span>
            <span
              *ngIf="form.controls.email.errors?.maxlength"
              class="text text-danger mt-1 label-error"
            >
            {{
              "validation.field_max_length" | translate | replace: "{max}":"255"
            }}
            </span>
          </div>
        </div>
      </div>

      <div class="form-group row form-item-row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label
            for="type"
            class="col-form-label isRequired"
            data-required="必須"
            appRequiredLabel
            >{{
              "page.account.modal.edit.member_info.user_type_lbl" | translate
            }}</label
          >
          <select
            class="form-control m-input"
            name="type"
            id="type"
            style="width: 100%"
            formControlName="type"
          >
            <option [ngValue]="0">
              {{ "common.select.user_type.user" | translate }}
            </option>
            <option [ngValue]="2">
              {{ "common.select.user_type.admin" | translate }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group row form-item-row">
        <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
          <div>
            <label for="type" class="col-form-label mr-2"
              >{{ "page.account.modal.edit.member_info.tfa_lbl" | translate }}:
            </label>
            <span *ngIf="user.two_factor_authen_flg">{{
              "page.account.modal.edit.member_info.tfa_lbl_yes" | translate
            }}</span>
            <span *ngIf="!user.two_factor_authen_flg">{{
              "page.account.modal.edit.member_info.tfa_lbl_no" | translate
            }}</span>
          </div>
          <button
            *ngIf="user.two_factor_authen_flg"
            type="button"
            class="btn btn-primary ml-4"
            (click)="toggle2fa()"
          >
            {{
              "page.account.modal.edit.member_info.tfa_lbl_cancel" | translate
            }}
          </button>
        </div>
      </div>

      <div class="form-group row form-item-row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <button
            type="button"
            class="btn btn-primary"
            (click)="restorePassword()"
          >
            {{
              "page.account.modal.edit.member_info.reset_password_btn"
                | translate
            }}
          </button>
        </div>
      </div>

      <div class="text-center form-item-row padding-button-top">
        <button
          type="button"
          class="btn btn-md btn-secondary with-button"
          (click)="goBack()"
        >
          {{ "page.account.modal.edit.cancel_btn" | translate }}</button
        >&nbsp;
        <button
          type="submit"
          class="btn btn-md btn-primary with-button"
          appLoadingButton
          data-i18n="page.account.modal.edit.confirm_btn"
          [isLoading]="isLoading"
          loadingText="{{ 'common.processing_text' | translate }}"
          [disabled]="!form.valid"
        ></button
        >&nbsp;
      </div>
      &nbsp;
    </form>
  </div>
</div>
