<header id="m_header" class="m-grid__item m-header">
  <div class="m-container m-container--fluid m-container--full-height">
    <div class="m-stack m-stack--ver m-stack--desktop">

      <!-- BEGIN: Brand -->
      <div class="m-stack__item m-brand  m-brand--skin-dark">
        <div class="m-stack m-stack--ver m-stack--general">
          <div class="m-stack__item m-stack__item--middle m-brand__logo text-center">
            <a class="m-brand__logo-wrapper" [routerLink]="['/manager/dashboard']">
              <img alt="" src="assets/images/sbiz_logo.png" width="70" height="auto" class="m-0 p-0">
            </a>
          </div>
          <div class="m-stack__item m-stack__item--middle m-brand__tools">
            <!-- BEGIN: Left Aside Minimize Toggle -->
            <a href="javascript:void(0)" (click)="toggleSidebar()" id="m_aside_left_minimize_toggle"
               class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block">
              <span></span>
            </a>

            <!-- END -->

            <!-- BEGIN: Responsive Aside Left Menu Toggler -->
            <a href="javascript:void(0)" (click)="toggleSidebarCanvas()" id="m_aside_left_offcanvas_toggle"
               class="m-brand__icon m-brand__toggler m-brand__toggler--right m--visible-tablet-and-mobile-inline-block">
              <span></span>
            </a>
            <!-- <a id="m_aside_header_topbar_mobile_toggle" href="javascript:void(0)"
               class="m-brand__icon m--visible-tablet-and-mobile-inline-block"
               (click)="toggleTopBar()">
              <i class="flaticon-more"></i>
            </a> -->
            <!-- BEGIN: Topbar Toggler -->
          </div>
        </div>
      </div>

      <!-- END: Brand -->
      <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">

        <!-- BEGIN: Horizontal Menu -->
        <button class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-dark "
                id="m_aside_header_menu_mobile_close_btn"><i class="la la-close"></i></button>
        <div id="m_header_menu"
             class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark ">

        </div>

        <!-- END: Horizontal Menu -->

        <!-- BEGIN: Topbar -->
        <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general m-stack--fluid">
          <div class="m-stack__item m-topbar__nav-wrapper">
            <ul class="m-topbar__nav m-nav m-nav--inline">
              <app-user-language-dropdown></app-user-language-dropdown>
              <app-user-profile-dropdown></app-user-profile-dropdown>
            </ul>
          </div>
        </div>

        <!-- END: Topbar -->
      </div>
    </div>
  </div>
</header>
