<div *ngIf="form">
  <div class="modal-header">
    <h2 class="modal-title" id="modal-title" style="font-weight: 600;">{{ 'page.information.modal.detail.heading' | translate }}</h2>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
      (click)="modal.dismiss('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body form-label">
    <form (ngSubmit)="form.valid && onSubmit()" [formGroup]="form">
      <div class="form-group row form-item-row">
        <div class="col-lg-9 col-md-9 col-sm-12">
          <input class="form-control m-input" name="title" id="title" type="text" formControlName="title"
            placeholder="{{ 'page.information.modal.detail.title_plh' | translate }}">
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <input class="form-control m-input" name="publish_date" id="publish_date" type="text"
            formControlName="publish_date" disabled="disabled">
        </div>
      </div>

      <div class="form-group row form-item-row">
        <div class="col-12">
          <div id="quill" >
            <quill-editor id="content" placeholder="{{ 'page.information.modal.detail.content_plh' | translate }}"
              formControlName="content" required>
            </quill-editor>
          </div>
        </div>
      </div>
      <div class="text-center form-item-row padding-button-top">
        <button type="button" class="btn btn-md btn-primary with-button" (click)="editInformation()">
          {{ 'page.information.modal.detail.edit_btn' | translate }}
        </button>&nbsp;
        <button type="button" class="btn btn-md btn-secondary with-button" (click)="goBack()">
          {{ 'page.information.modal.detail.cancel_btn' | translate }}
        </button>&nbsp;
      </div>&nbsp;
    </form>
  </div>
</div>